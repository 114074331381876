// eslint-disable-next-line import/no-extraneous-dependencies
import { PropertyValue as PV, PropertyValueSet as PVS } from "@promaster-sdk/property";
import { Amount } from "uom";
import { Units } from "uom-units";
// import * as AF from "../air-formulas";
import {
  AirDirection,
  CableInlet,
  Connection,
  Currency,
  Method,
  Material,
  Model,
  IP_Class,
  Insulation,
  Type,
  PaymentTerms,
  CalculateRequest,
  CalculateResult,
  Voltage,
  MaxOutTemp,
  ErrorResult,
  CustomItems,
} from "./types";
import { EhbUnits } from "../index";
import * as GQLOps from "../generated/generated-operations";
import {
  getFilteredElementTable,
  getFilteredPriceTables,
  getSupplierCode,
  PriceTables,
  ElementTableRows,
  getFilteredOrderingCodeTable,
  getFilteredRowsFromWeightTable,
  WeightTableRow,
  // getFilteredExtraDepthTable,
  // ExtraDepthTableRows,
  getFilteredGlandEntrySizeTable,
  GlandEntrySizeTableRows,
} from "./get-tables";
import { calculateAirVelocity, calculatePressureDropPa } from "./air-functions";
import { getDepth, getHeight, getWidth } from "./get-dimensions";
import { getWeight } from "./calc-weight";
import * as FP from "../fp";
import { TranslateFn } from "../lang-texts/translate";
import { getPrice, getPriceAccessories } from "./calc-price";
import { texts } from "../lang-texts/texts";
import { getCurrent } from "./calc-current";
import { calculateRows } from "./calc-rows";
import { calculateStepPowers, deltatToPower } from "./calc-power";
import { calculateTemperatureIncrese } from "./calc-temperature";
import { createErrorResult } from "./util";
import { calculateHeatingRodSurfaceTemp } from "./calc-heating-rod-surface-temperature";

export function fromVariantToCalculateRequest(variant: PVS.PropertyValueSet): FP.Result<string, CalculateRequest> {
  const model: Model = PV.getInteger(PVS.getValue("model", variant)) || 1;
  const type: Type = PV.getInteger(PVS.getValue("type", variant)) || 1;
  const voltage: Voltage = PV.getInteger(PVS.getValue("voltage", variant)) || 3400;
  const connection: Connection = PV.getInteger(PVS.getValue("connection", variant)) || 1;
  const method: Method = PV.getInteger(PVS.getValue("method", variant)) || 0;
  const currency: Currency = PV.getInteger(PVS.getValue("currency", variant)) || 1;
  const powerAmount = method === Method.Power ? PVS.getAmount<"Power">("power", variant) : undefined;
  const powerKw = powerAmount ? Amount.valueAs(Units.KiloWatt, powerAmount) : 0;
  const powerSteps = type === Type.M || type === Type.ML ? PV.getInteger(PVS.getValue("powersteps", variant)) || 1 : 1;
  const powerStepDistribution =
    type === Type.M || type === Type.ML ? PV.getInteger(PVS.getValue("powerstepsdistribution", variant)) || 1 : 1;
  const heightAmount = connection === Connection.Rectangular ? PVS.getAmount<"Length">("height", variant) : undefined;
  const heightMm = heightAmount ? Amount.valueAs(Units.Millimeter, heightAmount) : 0;
  const widthAmount = connection === Connection.Rectangular ? PVS.getAmount<"Length">("width", variant) : undefined;
  const widthMm = widthAmount ? Amount.valueAs(Units.Millimeter, widthAmount) : 0;
  const diameter = connection === Connection.Circular ? PV.getInteger(PVS.getValue("diameter", variant)) || 0 : 0;

  const inletTempAmount =
    method === Method.Temperature ? PVS.getAmount<"Temperature">("inlettemp", variant) : undefined;
  const inletTemp = inletTempAmount ? Amount.valueAs(Units.Celsius, inletTempAmount) : 0;
  const outletTempAmount =
    method === Method.Temperature ? PVS.getAmount<"Temperature">("outlettemp", variant) : undefined;
  const outletTemp = outletTempAmount ? Amount.valueAs(Units.Celsius, outletTempAmount) : 0;

  const deltaTemp = method === Method.DeltaT ? PVS.getAmount<"Temperature">("deltatemp", variant) || 0 : 0;

  const airHumidityAmount =
    method === Method.Temperature ? PVS.getAmount<"RelativeHumidity">("inletairhumidity", variant) : undefined;
  const airHumidityPct = airHumidityAmount ? Amount.valueAs(Units.PercentHumidity, airHumidityAmount) : 0;
  const maxOutputTemp: MaxOutTemp = PV.getInteger(PVS.getValue("maxoutputtemp", variant)) || 0;
  const material: Material = PV.getInteger(PVS.getValue("material", variant)) || 0;
  const insulation: Insulation = PV.getInteger(PVS.getValue("insulation", variant)) || 1;
  const ipClass: IP_Class = PV.getInteger(PVS.getValue("protection", variant)) || 0;
  const airDirection: AirDirection = PV.getInteger(PVS.getValue("airdirection", variant)) || 1;
  const cableInlet: CableInlet = PV.getInteger(PVS.getValue("cableinlet", variant)) || 1;
  const airvolume = PVS.getAmount<"VolumeFlow">("airvolume", variant);

  const discountAmount = PVS.getAmount<"Dimensionless">("discount", variant);
  const discount = discountAmount ? Amount.valueAs(Units.Percent, discountAmount) : 0;
  const deliveryTerms = PV.getInteger(PVS.getValue("deliveryterms", variant)) || 0;
  const paymentTerms: PaymentTerms = PV.getInteger(PVS.getValue("paymentterms", variant)) || 0;
  // Accessories
  const ductsensorTGK330 = PV.getInteger(PVS.getValue("ductsensorTGK330", variant)) || 0;
  const ductsensorTGK360 = PV.getInteger(PVS.getValue("ductsensorTGK360", variant)) || 0;
  const pressureswitchDTV300 = PV.getInteger(PVS.getValue("pressureswitchDTV300", variant)) || 0;
  const roomsensorTGR430 = PV.getInteger(PVS.getValue("roomsensorTGR430", variant)) || 0;
  const meshInlet = PV.getInteger(PVS.getValue("meshInlet", variant)) || 0;
  const meshOutlet = PV.getInteger(PVS.getValue("meshOutlet", variant)) || 0;

  const productionTimeAmount = PVS.getAmount<"Duration">("productiontime", variant);
  const productionTime = productionTimeAmount ? Amount.valueAs(Units.Day, productionTimeAmount) : 0;

  const project = PVS.getText("project", variant) || "";
  const reference = PVS.getText("reference", variant) || "";

  const quotationNumber = PVS.getText("quotationnumber", variant) || undefined;

  const notes = PVS.getText("notes", variant) || undefined;
  const notesDrawing = PVS.getText("notesdrawing", variant) || undefined;

  const checkManualDepth = PVS.getValue("manualdepth", variant);

  const manualDepth = (checkManualDepth && PV.getInteger(PVS.getValue("manualdepth", variant))) || 0;

  const includePrice = PV.getInteger(PVS.getValue("include_price", variant)) || 0;
  const includeDiscount = PV.getInteger(PVS.getValue("include_discount", variant)) || 0;

  return FP.Ok({
    model,
    type,
    voltage,
    powerSteps,
    powerStepDistribution,
    cableInlet,
    airVolumeM3h: airvolume ? Amount.valueAs(EhbUnits.CubicMeterPerHour, airvolume) : 0,
    currency,
    heightMm,
    widthMm,
    material,
    powerKw,
    ipClass,
    airDirection,
    connection,
    deltaTempC: deltaTemp ? Amount.valueAs(EhbUnits.Celsius, deltaTemp) : 0,
    inletTempC: inletTemp,
    outletTempC: outletTemp,
    airHumidityPct,
    maxOutputTempC: maxOutputTemp,
    method,
    diameter: diameter ? diameter : 0,
    diameterMm: diameter ? Amount.create(diameter, EhbUnits.Millimeter).value : 0,
    insulation,
    ductsensorTGK330,
    ductsensorTGK360,
    pressureswitchDTV300,
    roomsensorTGR430,
    deliveryTerms,
    productionTime,
    paymentTerms,
    quotationNumber: quotationNumber ?? "",
    discount: discount,
    notes: notes ?? "",
    notesDrawing: notesDrawing ?? "",
    manualDepth,
    includePrice,
    includeDiscount,
    meshInlet,
    meshOutlet,
    project,
    reference,
  });
}

export function fromCalculateRequstToVariant(request: CalculateRequest): PVS.PropertyValueSet {
  const result = {
    model: PV.create("integer", request.model),
    type: PV.create("integer", request.type),
    voltage: PV.create("integer", request.voltage),
    powerSteps: PV.create("integer", request.powerSteps),
    powerStepDistribution: PV.create("integer", request.powerStepDistribution),
    cableInlet: PV.create("integer", request.cableInlet),
    airvolumeLs: PV.create("amount", Amount.create(request.airVolumeM3h, EhbUnits.LiterPerSecond)),
    currency: PV.create("integer", request.currency),
    heightMm: PV.create("amount", Amount.create(request.heightMm, EhbUnits.Millimeter)),
    widthMm: PV.create("amount", Amount.create(request.widthMm, EhbUnits.Millimeter)),
    material: PV.create("integer", request.material),
    powerKw: PV.create("amount", Amount.create(request.powerKw, EhbUnits.KiloWatt)),
    inlettemp: PV.create("amount", Amount.create(request.inletTempC, EhbUnits.Celsius)),
    outlettemp: PV.create("amount", Amount.create(request.outletTempC, EhbUnits.Celsius)),
    inletairhumidity: PV.create("amount", Amount.create(request.airHumidityPct, EhbUnits.PercentHumidity)),
    maxoutputtemp: PV.create("integer", request.maxOutputTempC),
    protection: PV.create("integer", request.ipClass),
    insulation: PV.create("integer", request.insulation),
    cableinlet: PV.create("integer", request.cableInlet),
    airdirection: PV.create("integer", request.airDirection),
    connection: PV.create("integer", request.connection),
    deltaTemp: PV.create("integer", request.deltaTempC),
    method: PV.create("integer", request.method),
    diameter: PV.create("integer", request.diameter),
    ductsensorTGK330: PV.create("integer", request.ductsensorTGK330),
    ductsensorTGK360: PV.create("integer", request.ductsensorTGK360),
    pressureswitchDTV300: PV.create("integer", request.pressureswitchDTV300),
    roomsensorTGR430: PV.create("integer", request.roomsensorTGR430),
    discount: PV.create("integer", request.discount),
  };
  return result;
}

export function calculate(
  request: CalculateRequest,
  product: GQLOps.ProductQuery | undefined,
  translate: TranslateFn,
  customItems: CustomItems
): FP.Result<ErrorResult, CalculateResult> {
  // Validate Request

  if (!product?.product) {
    return createErrorResult("Error when fetching product", "ERROR");
  }

  const calcRequest = validateRequest(request, translate, product.product.key);
  if (calcRequest.type === "Err") {
    return calcRequest;
  }

  const variant = fromCalculateRequstToVariant(request);
  const orderingCodesTable = getFilteredOrderingCodeTable(product, variant);
  if (orderingCodesTable.type === "Err") {
    return orderingCodesTable;
  }
  const supplierCode = getSupplierCode(orderingCodesTable.value, variant);

  const priceTables = getFilteredPriceTables(product, variant);
  if (priceTables.type === "Err") {
    return createErrorResult("Something went wrong when fetching price.", "ERROR");
  }

  const elementTable = getFilteredElementTable(product, variant);
  if (elementTable.type === "Err") {
    return createErrorResult("Something went wrong when element data.", "ERROR");
  }

  const weightTable = getFilteredRowsFromWeightTable(product, variant);
  if (weightTable.type === "Err") {
    return createErrorResult("Something went wrong when element data.", "ERROR");
  }

  const glandEntrySizeTable = getFilteredGlandEntrySizeTable(product);
  if (glandEntrySizeTable.type === "Err") {
    return createErrorResult("Something went wrong when element data.", "ERROR");
  }

  if (product?.product?.key === undefined) {
    return createErrorResult("Something went wrong when getting product.", "ERROR");
  }

  const result = calculateHeater(
    calcRequest.value,
    supplierCode,
    priceTables.value,
    elementTable.value,
    weightTable.value,
    // extraDepthTable.value,
    translate,
    customItems,
    glandEntrySizeTable.value,
    product.product.key
  );
  if (result.type === "Err") {
    return result;
  }

  const validatedResult = validateResult(request, result.value, translate, product.product.key);
  if (validatedResult.type === "Err") {
    return validatedResult;
  }

  return validatedResult;
}

export function calculateHeater(
  request: CalculateRequest,
  supplierCode: string,
  priceTables: PriceTables,
  elementTable: ReadonlyArray<ElementTableRows>,
  weightTable: ReadonlyArray<WeightTableRow>,
  // extraDepthTable: ReadonlyArray<ExtraDepthTableRows>,
  translate: TranslateFn | undefined,
  customItems: CustomItems,
  glandEntrySizeTable: ReadonlyArray<GlandEntrySizeTableRows>,
  productKey: string
): FP.Result<ErrorResult, CalculateResult> {
  const voltageV = Number(request.voltage.toString().slice(1));
  const phases = Number(request.voltage.toString().charAt(0));
  const widthMm = request.connection === Connection.Circular ? request.diameterMm : request.widthMm;
  const heightMm = request.connection === Connection.Circular ? request.diameterMm : request.heightMm;
  const project = request.project;
  const reference = request.reference;
  const model = request.model;
  const type = request.type;
  const powerStepDistribution = request.powerStepDistribution;
  const powersteps = type === Type.M || type === Type.ML ? request.powerSteps : 1;
  const protection = request.ipClass;
  const airVolumeM3h = request.airVolumeM3h;
  const material = request.material;
  const connection = request.connection;
  const currency = request.currency;
  const insulation = request.insulation;
  const airDirection = request.airDirection;
  const cableInlet = request.cableInlet;
  const maxOutputTemp = request.maxOutputTempC;
  const inletTempC = request.inletTempC;
  let outletTempC =
    request.method === Method.Temperature
      ? request.outletTempC
      : request.method === Method.DeltaT
      ? request.inletTempC + request.deltaTempC
      : 0;

  const airHumidityPct = request.method === Method.Temperature ? request.airHumidityPct : 0;
  const deltaTemp = request.method === Method.Temperature ? outletTempC - inletTempC : request.deltaTempC;
  const discount = request.discount;

  const manualDepthMm = request.manualDepth !== 0 ? request.manualDepth : undefined;
  const faceWidthMm = getWidth(model, type, widthMm) || widthMm;
  const faceHeightMm = getHeight(model, heightMm) || heightMm;
  // const humidityGpk =
  //   AF.AmountConversions.relativeHumidityToHumidityRatio(
  //     AF.AshraeHb2009.calculateAtmosphericPressurePa(0),
  //     inletTempC,
  //     airHumidityPct
  //   ) * 1000;

  // const densityKGM3 =
  //   request.method === Method.Temperature
  //     ? AF.AshraeHb2009.calculateDensityOfMoistAirMixture(
  //         inletTempC,
  //         AF.AshraeHb2009.calculateAtmosphericPressurePa(0),
  //         humidityGpk
  //       )
  //     : 0;

  // const massFlowKgs = request.method === Method.Temperature ? densityKGM3 * (airVolumeM3h / 1000) : 0;

  const airFlowLps = airVolumeM3h ? airVolumeM3h * 0.277778 : 0;
  const standardAirPressure = 101325;

  const airMass = moistAirDensity(inletTempC, standardAirPressure, airHumidityPct / 100.0) * (airFlowLps / 1000.0);

  const powerKw =
    request.method === Method.DeltaT
      ? Math.round(deltatToPower(deltaTemp, airVolumeM3h) * 10) / 10
      : request.method === Method.Temperature
      ? Math.round((((outletTempC - inletTempC) * (airMass * 1005)) / 1000) * 10) / 10
      : request.powerKw;

  const rows = calculateRows(powerKw, heightMm, widthMm);

  const powerStepsKw =
    type === Type.M || type === Type.ML ? calculateStepPowers(powerKw, powersteps, powerStepDistribution) : [];
  const tempIncrease =
    request.method === Method.DeltaT
      ? request.deltaTempC
      : request.method === Method.Temperature
      ? request.outletTempC - request.inletTempC
      : calculateTemperatureIncrese(powerKw, airVolumeM3h);
  const current = getCurrent(phases, voltageV, powerKw);

  const currentRoundedValue = Math.round(current * 10) / 10;

  const glandEntrySizeValue = glandEntrySizeTable.find(
    (r) => r.from_ampere < currentRoundedValue && r.to_ampere > currentRoundedValue
  )?.diameter;

  const fullGlandEntryString = `1 x Ø${glandEntrySizeValue}, 4 x Ø20`;

  outletTempC = request.inletTempC ? inletTempC + tempIncrease : outletTempC;
  const calculatedDepthResult = getDepth({
    type: type,
    widthMm: faceWidthMm,
    heightMm: faceHeightMm,
    powerKw: powerKw,
    voltageV: voltageV,
    phases: phases,
    powerStepsKw: powerStepsKw,
    model: model,
    elementTable: elementTable,
    connection: connection,
    current: current,
  });

  if (calculatedDepthResult.type === "Err") {
    return createErrorResult(texts.ERROR_DEPTH_NOT_POSSIBLE(productKey).key, "ERROR", translate, productKey);
  }
  const element = elementTable.find((r) => r.artNr === calculatedDepthResult.value.articleNumber);

  /*
  Från: Alexander Sonesson <als@veab.com> 
  Skickat: den 1 mars 2023 09:50
  Till: Håkan Ekelund <HaE@veab.com>; Patrik Karlsson <PaK@veab.com>
  Ämne: Sv: Felsökning bredd. 
  Hej Patrik/Håkan,
  Vi räknar alltid lufthastighet på elbatterier med cirkulära anslutningar på det rektangulära måttet,
  Om vi hade räknat för 1,5m/s på det cirkulära måttet så skulle lufthastigheten genom den rektangulära värmaren bli strax under 1,5m/s.
  Så det är rätt att hastigheten räknas på rektangulära måttet.
  /Alex
  */
  const areaM2 = Math.round((widthMm / 1000) * (heightMm / 1000) * 1000) / 1000;
  const faceAreaM2 = Math.round((faceWidthMm / 1000) * (faceHeightMm / 1000) * 1000) / 1000;
  const faceVelocityMs = calculateAirVelocity(faceAreaM2, airVolumeM3h);

  const heatingRodSurfaceTemperatureC =
    element && calculateHeatingRodSurfaceTemp(element.power, element.length, faceVelocityMs, voltageV);

  const depthMm = manualDepthMm ? request.manualDepth : calculatedDepthResult.value.depthMm;

  const heaterWeightKg = getWeight({
    widthMm: widthMm,
    heightMm: heightMm,
    depthMm,
    power: powerKw,
    amperage: current,
    model: model,
    weightTable: weightTable,
  });
  const weightKg = heaterWeightKg?.weightKg || 0;

  let pressureDrop = calculatePressureDropPa(faceVelocityMs, faceAreaM2, powerKw, rows);

  //
  // Below - extended calculation of pressureDrop depending on meshInlet, meshOutlet

  if (request.meshInlet === 1) {
    pressureDrop += calculatePressureDropPa(faceVelocityMs, faceAreaM2, powerKw, 6);
  }
  if (request.meshOutlet === 1) {
    pressureDrop += calculatePressureDropPa(faceVelocityMs, faceAreaM2, powerKw, 6);
  }

  //--------------------------------------------------------------------------------------------------//

  const stepsArrayText = powerStepsText(powerStepsKw, type);

  const generatedSupplierCode = supplierCode
    .replace("{width}", widthMm.toString())
    .replace("{height}", heightMm.toString())
    .replace("{depth}", depthMm.toString())
    .replace("{maxPower}", powerKw.toString())
    .replace("{steps}", stepsArrayText.join("+"))
    .replace("{phases}", phases.toString())
    .replace("{volts}", voltageV.toString())
    .replace("{maxoutputtemp}", maxOutputTemp.toString());

  const calcResult: CalculateResult = {
    model,
    type,
    material,
    ipClass: protection,
    insulation,
    airDirection,
    cableInlet,
    connection,
    depthMm: manualDepthMm ? request.manualDepth : depthMm,
    supplierCode: generatedSupplierCode,
    widthMm,
    heightMm,
    airHeightMm: faceHeightMm,
    airWidthMm: faceWidthMm,
    powerKw,
    weightKg,
    voltage: request.voltage,
    pressureDropPa: pressureDrop,
    rows,
    areaM2,
    airVolumeM3h,
    velocityMs: faceVelocityMs,
    current,
    powerStepsKw,
    powerStepsKwText: stepsArrayText,
    outletTempC: Math.round(outletTempC * 100) / 100,
    temperatureIncreaseC: tempIncrease,
    heaterPrice: 0,
    totalPrice: 0,
    maxOutputTempC: maxOutputTemp,
    currency,
    numberElectricalElements: calculatedDepthResult.value.electricalElements,
    articleNumberUsed: calculatedDepthResult.value.articleNumber,
    resultStatus: "OK",
    fullGlandEntryString,
    heatingRodSurfaceTemperatureC: heatingRodSurfaceTemperatureC!,
    project,
    reference,
  };

  const validatedResult = validateResult(request, calcResult, translate, productKey);
  if (validatedResult.type === "Err") {
    return validatedResult;
  }

  const price = getPrice(
    protection,
    connection,
    depthMm,
    material,
    areaM2,
    powerKw * 1000,
    type,
    currency,
    insulation,
    powerStepsKw,
    voltageV,
    phases,
    maxOutputTemp,
    priceTables,
    discount,
    customItems,
    request
  );
  if (price.type === "Err") {
    return price;
  }

  return FP.Ok({
    ...calcResult,
    heaterPrice: price.value,
    totalPrice: price.value + getPriceAccessories(currency, priceTables.priceAccessories, discount).totalPrice,
  });
}

function powerStepsText(powerStepsKw: ReadonlyArray<number>, type: Type): ReadonlyArray<string> {
  const stepsArrayText: Array<string> = [];
  if (type === Type.M || type === Type.ML) {
    powerStepsKw.forEach((step) => {
      if (step > 43) {
        const stepCalc = step / 43;

        if (stepCalc > 1 && stepCalc < 2) {
          const stepDiv = step / 2;
          stepsArrayText.push(`(${stepDiv}+${stepDiv})`);
        } else if (stepCalc > 2 && stepCalc < 3) {
          const stepDiv = step / 3;
          stepsArrayText.push(`(${stepDiv}+${stepDiv}+${stepDiv})`);
        }
      } else if (step !== 0) {
        stepsArrayText.push(`${step}`);
      }
    });
  }
  return stepsArrayText;
}

export function validateRequest(
  request: CalculateRequest,
  translate?: TranslateFn,
  productKey?: string
): FP.Result<ErrorResult, CalculateRequest> {
  // Method
  if (!(request.method in Method)) {
    return createErrorResult("ERROR_METHOD_NOT_POSSIBLE", "ERROR", translate, productKey);
  }

  if (request.method === Method.Temperature) {
    if (Number.isNaN(request.inletTempC) || Number.isNaN(request.outletTempC) || Number.isNaN(request.airHumidityPct)) {
      return createErrorResult("ERROR_SELECTION_NOT_POSSIBLE", "ERROR", translate, productKey);
    }
  }

  if (!(request.voltage in Voltage)) {
    return createErrorResult("ERROR_SELECTION_NOT_POSSIBLE", "ERROR", translate, productKey);
  }

  // Protection
  if (!(request.ipClass in IP_Class)) {
    return createErrorResult("ERROR_IPCLASS_NOT_POSSIBLE", "ERROR", translate, productKey);
  }

  // Cableinlet

  if (!(request.cableInlet in CableInlet)) {
    return createErrorResult("ERROR_CABLE_INLET", "ERROR", translate, productKey);
  }

  // Dimensions
  if (request.heightMm < 0 || request.widthMm < 0) {
    return createErrorResult("ERROR_NEGATIVE_VALUE", "ERROR", translate, productKey);
  }
  if (request.model !== Model.VRA && request.connection === Connection.Rectangular && request.widthMm < 250) {
    return createErrorResult("ERROR_MIN_WIDTH_DUCT", "ERROR", translate, productKey);
  }
  if (request.model !== Model.VRA && request.connection === Connection.Rectangular && request.heightMm < 200) {
    return createErrorResult("ERROR_MIN_HEIGHT_DUCT", "ERROR", translate, productKey);
  }
  if (request.heightMm > 2520) {
    return createErrorResult("ERROR_MAX_HEIGHT", "CONTACT-VEAB", translate, productKey);
  }
  if (request.widthMm > 2520) {
    return createErrorResult("ERROR_MAX_WIDTH", "CONTACT-VEAB", translate, productKey);
  }

  if (request.model === Model.VRA && request.widthMm < 420) {
    return createErrorResult("ERROR_MIN_WIDTH", "CONTACT-VEAB", translate, productKey);
  }
  if (request.model === Model.VRA && request.heightMm < 350) {
    return createErrorResult("ERROR_MIN_HEIGHT", "CONTACT-VEAB", translate, productKey);
  }

  // MaxTemp
  if (request.model === Model.VRA && request.maxOutputTempC > MaxOutTemp._50C && request.type !== Type.M) {
    return createErrorResult("ERROR_MAX_TEMP", "ERROR", translate, productKey);
  }

  // Connection

  // if (request.model !== Model.VFLPG && request.connection === Connection.Circular) {
  //   return createErrorResult("ERROR_CIRCULAR_NOT_POSSIBLE", "ERROR", translate, productKey);
  // }

  //Voltage & Steps & Power

  if (request.voltage === 1230 && request.powerKw > 5.4) {
    return createErrorResult("ERROR_MAX_POWER_FOR_1X230V", "ERROR", translate, productKey);
  }

  if (
    (request.type === Type.MQXL || request.type === Type.MQUL || request.type === Type.MQEML) &&
    request.powerKw > 27
  ) {
    return createErrorResult("ERROR_MAX_POWER_FOR_MQ", "ERROR", translate, productKey);
  }

  if (
    (request.type === Type.MQXL || request.type === Type.MQUL || request.type === Type.MQEML) &&
    request.voltage === Voltage.Phase3Volt230 &&
    request.powerKw > 15
  ) {
    return createErrorResult("ERROR_MAX_POWER_FOR_MQ_3X230V", "ERROR", translate, productKey);
  }

  if (request.type === Type.MTUL && request.powerKw > 135) {
    return createErrorResult("ERROR_MAX_POWER_FOR_MTUL", "ERROR", translate, productKey);
  }

  if (request.type === Type.MTUL && request.powerKw > 75 && request.voltage === Voltage.Phase3Volt230) {
    return createErrorResult("ERROR_MAX_POWER_MTUL_230V", "ERROR", translate, productKey);
  }

  if (
    (request.type === Type.MQXL || request.type === Type.MQUL || request.type === Type.MQEML) &&
    request.powerKw > 15 &&
    request.voltage === Voltage.Phase3Volt230
  ) {
    return createErrorResult("ERROR_MAX_POWER_FOR_MQ_3X230V", "ERROR", translate, productKey);
  }

  if (
    (request.method === Method.Power && request.powerKw === 0) ||
    (request.method === Method.DeltaT && request.deltaTempC === 0) ||
    (request.method === Method.Temperature &&
      (request.outletTempC - request.inletTempC <= 0 || request.airHumidityPct <= 0))
  ) {
    return createErrorResult(texts.err_fill_in_values.key, "ERROR", translate);
  }

  return FP.Ok(request);
}

export function validateResult(
  request: CalculateRequest,
  calcResult: CalculateResult,
  translate: TranslateFn | undefined,
  productKey: string
): FP.Result<ErrorResult, CalculateResult> {
  // values powerKw, widthMm, heightMm or airVolumeM3h not filled in
  if (!calcResult.powerKw || !calcResult.widthMm || !calcResult.heightMm || !calcResult.airVolumeM3h) {
    return createErrorResult(texts.err_fill_in_values.key, "ERROR", translate);
  }

  // Dimensions
  if (calcResult.areaM2 > 6) {
    return createErrorResult("ERROR_FRAME_TO_BIG", "ERROR", translate, productKey);
  }
  if (calcResult.depthMm >= 2500) {
    return createErrorResult("ERROR_MAX_DEPTH", "ERROR", translate, productKey);
  }

  // Speed - both rectangular and circular
  if (calcResult.velocityMs > 6) {
    return createErrorResult("ERROR_AIRSPEED_TO_HIGH", "ERROR", translate, productKey);
  }

  if (calcResult.velocityMs < 1.5) {
    return createErrorResult("ERROR_AIRSPEED_TO_LOW", "ERROR", translate, productKey);
  }

  if (calcResult.maxOutputTempC === 120 && calcResult.velocityMs < 2) {
    return createErrorResult("ERROR_AIRSPEED_TO_LOW_FOR_120C", "ERROR", translate, productKey);
  }
  //Voltage & Steps & Power

  if (calcResult.voltage === Voltage.Phase1Volt230 && calcResult.powerKw > 5.4) {
    return createErrorResult("ERROR_MAX_POWER_FOR_1X230V", "ERROR", translate, productKey);
  }
  if (
    (calcResult.type === Type.MQXL || calcResult.type === Type.MQUL || calcResult.type === Type.MQEML) &&
    calcResult.powerKw > 27
  ) {
    return createErrorResult("ERROR_MAX_POWER_FOR_MQ", "ERROR", translate, productKey);
  }

  if (calcResult.type === Type.MTUL && calcResult.powerKw > 135) {
    return createErrorResult("ERROR_MAX_POWER_FOR_MTUL", "ERROR", translate, productKey);
  }

  if (calcResult.type === Type.MTUL && calcResult.voltage === 1230 && calcResult.powerKw > 75) {
    return createErrorResult("ERROR_MAX_POWER_MTUL_230V", "ERROR", translate, productKey);
  }

  if (
    (calcResult.type === Type.MQXL || calcResult.type === Type.MQUL || calcResult.type === Type.MQEML) &&
    calcResult.voltage === 3230 &&
    calcResult.powerKw > 15
  ) {
    return createErrorResult("ERROR_MAX_POWER_FOR_MQ_3X230V", "ERROR", translate, productKey);
  }

  if (calcResult.voltage === 230 && calcResult.powerStepsKw.some((pwstp) => pwstp > 5.4)) {
    return createErrorResult("ERROR_MAX_STEPSIZE_FOR_1X230V", "ERROR", translate, productKey);
  }

  if (calcResult.voltage === Voltage.Phase2Volt400 && calcResult.powerStepsKw.some((pwstp) => pwstp > 9)) {
    return createErrorResult("ERROR_MAX_STEPSIZE_FOR_2X400V", "ERROR", translate, productKey);
  }

  if (calcResult.voltage === Voltage.Phase3Volt230 && calcResult.powerKw > 215) {
    return createErrorResult("ERROR_MAX_POWER_3X230V", "ERROR", translate, productKey);
  }

  if (calcResult.voltage === Voltage.Phase3Volt400 && calcResult.powerKw > 215) {
    return createErrorResult("ERROR_MAX_POWER_3X400V", "ERROR", translate, productKey);
  }

  if (
    (calcResult.type === Type.M || calcResult.type === Type.ML) &&
    calcResult.powerStepsKw.length === 1 &&
    calcResult.powerStepsKw[0] > 43
  ) {
    return createErrorResult("ERROR_STEP1_TO_HIGH", "ERROR", translate, productKey);
  }

  if (Number.isNaN(calcResult.pressureDropPa) && Method.Power && request.powerKw) {
    return createErrorResult(texts.err_pressure_drop_power.key, "ERROR", translate);
  }

  if (Number.isNaN(calcResult.pressureDropPa) && Method.DeltaT && request.deltaTempC) {
    return createErrorResult(texts.err_pressure_drop_delta_t.key, "ERROR", translate);
  }

  return FP.Ok(calcResult);
}

export function moistAirDensity(dryBulbTemperature: number, pressure: number, relativeHumidity: number): number {
  if (relativeHumidity > 1.0 || relativeHumidity < 0.0) {
    throw new Error("Value out of range: relativeHumidity");
  }
  const specificDryAirGasConstant = 287.058; // J/(kg*K)
  const kelvinDelta = 273.15;
  const specificWaterVaporGasConstant = 461.495; // J/(kg*K)
  const h20p = h2opsat(dryBulbTemperature) * relativeHumidity;
  return (
    (pressure - h20p) / (specificDryAirGasConstant * (dryBulbTemperature + kelvinDelta)) +
    h20p / (specificWaterVaporGasConstant * (dryBulbTemperature + kelvinDelta))
  );
}

function h2opsat(temp: number): number {
  if (temp < -60.0) {
    throw new Error("Value out of range: temp");
  }
  const pwat =
    6.107799961 +
    temp *
      (4.436518521e-1 +
        temp *
          (1.428945805e-2 +
            temp * (2.650648471e-4 + temp * (3.031240396e-6 + temp * (2.034080948e-8 + temp * 6.136820929e-11)))));
  const pice =
    6.109177956 +
    temp *
      (5.03469897e-1 +
        temp *
          (1.886013408e-2 +
            temp * (4.176223716e-4 + temp * (5.82472028e-6 + temp * (4.838803174e-8 + temp * 1.838826904e-10)))));
  return Math.min(pwat, pice) * 100.0;
}
