import { Amount } from "uom";
import { Units } from "uom-units";
import { CalculatorFrtCoil, Search } from "..";
import * as GQLOps from "../generated/generated-operations";
import { isDefined } from "../utils";

export type Accessory = {
  readonly articleNumber: string;
  readonly name: string;
  readonly price_sek: string;
  readonly price_eur: string;
  readonly text_key: string;
};

export function getAvailableAccessories(
  searchProduct: GQLOps.ProductQuery,
  calculationResults: CalculatorFrtCoil.CalculationResult | undefined,
  match: Search.Match
): ReadonlyArray<Accessory> {
  const accessoriesTable = searchProduct.product?.modules.custom_tables.accessories_water_dx;
  const productAccessoryLinkTable = searchProduct.product?.modules.custom_tables.product_accessory_link;

  if (!accessoriesTable || !productAccessoryLinkTable) {
    return [];
  }

  const accessoryNumbers = productAccessoryLinkTable.filter((r) => r.product_art_nr === match.productVariantRow.art_nr);

  const accessories = accessoryNumbers
    .map((r) => accessoriesTable.find((a) => a.art_nr === r.accessory_art_nr))
    .filter(isDefined);

  const mapAccessory = (a: GQLOps.AccessoriesWaterDxTableFragment["accessories_water_dx"][number]): Accessory => ({
    articleNumber: a.art_nr || "",
    name: a.name || "",
    price_sek: a.price_sek?.toString() || "",
    price_eur: a.price_eur?.toString() || "",
    text_key: a.text_key || "",
  });

  if (calculationResults && calculationResults.type === "Ok") {
    // Filter on KV value using formula kv = Q * sqrt(1/dp)
    // where Q = fluid flow in m3/h
    // dp = fluid pressure drop in bar
    // Only allow accessories with kv > calculated kv
    const Q = Amount.valueAs(Units.CubicMeterPerHour, calculationResults.value.fluidFlow);
    const dp = Amount.valueAs(Units.Bar, calculationResults.value.fluidPressureDrop);
    const kv = Q * Math.sqrt(1 / dp);

    return accessories
      .filter((a) => {
        if (a.kv === null) {
          return true;
        }

        return a.kv > kv;
      })
      .map(mapAccessory);
  } else {
    return accessories.filter((a) => a.kv === null).map(mapAccessory);
  }
}
