import { PDFDocument, PDFForm, PDFName, PDFRef, TextAlignment, degrees } from "pdf-lib";
import { unitLookup } from "uom-units";
import { PropertyFilter } from "@promaster-sdk/property";
import {
  AirDirection,
  CableInlet,
  CalculateRequest,
  CalculateResult,
  Connection,
  DocumentTableRows,
  Insulation,
  Material,
  Model,
  IP_Class,
  Type,
} from "../../calculator";
import { getUnitLabel } from "../../units/unit-labels";
import { createBlobUrlCreator } from "../../utils";
import { downloadFile } from "../../fetch";
import { ReportParams } from "../types";

const DrawingFormFields = {
  model: "Modell",
  type: "Type",
  power: "Tot loading",
  voltage: "Voltage",
  current: "I",
  DimF22: "Dim F22",
  DimB1: "Dim B1",
  DimH1: "Dim H1",
  DimB: "Dim B",
  DimH: "Dim H",
  DimD: "Dim D",
  DimK: "Dim K",
  depth: "Djup",
  weight: "Vikt",
  material: "Material",
  protection: "IP",
  tempInfo: "Temp",
  connection: "con",
  connection2: "Ø con",
  insulation: "Isolering",
  freeText: "Free text",
  airDirection: "Air flow direction",
  cableInlet: "Gland pos",
  glandSize: "Gland size",
  maxOutTemp: "Air temp",
  step1: "Step1",
  step2: "Step2",
  step3: "Step3",
  step4: "Step4",
  step5: "Step5",
  step6: "Step6",
};

export async function generateDrawing(
  documents: ReadonlyArray<DocumentTableRows>,
  reportParams: ReportParams
): Promise<PDFDocument> {
  // Drawing
  const drawing = documents.find(
    (row) =>
      row.type === "drawing" &&
      PropertyFilter.isValid(
        reportParams.properties,
        PropertyFilter.fromString(row.property_filter, unitLookup) || PropertyFilter.Empty
      )
  );
  if (drawing === undefined) {
    throw new Error("Could not find drawing");
  }
  if (!reportParams.calculateResult || !reportParams.calculateRequest) {
    throw new Error("Missing calculate result");
  }
  const drawingPdfUrl = createBlobUrlCreator(reportParams.user.accessToken)(drawing.document, drawing.file_name);
  const drawingPdf = await downloadFile(drawingPdfUrl);
  const drawingDoc = await PDFDocument.load(drawingPdf.buffer);
  const drawingForm = drawingDoc.getForm();

  fillDrawingFields(drawingForm, reportParams.calculateResult, reportParams.calculateRequest);
  //   const [drawingPage] = await MainPdfDoc.copyPages(drawingDoc, [0]);
  //   MainPdfDoc.addPage(drawingPage);
  //   MainPdfDoc.getForm().acroForm.dict.set(PDFName.of("NeedAppearances"), PDFBool.True);
  //   const pdfBytes = await MainPdfDoc.save();

  return drawingDoc;
}

function fillDrawingFields(pdfForm: PDFForm, calculateResult: CalculateResult, calcRequest: CalculateRequest): void {
  if (calculateResult.model === Model.VRA || calculateResult.model === Model.VTL) {
    pdfForm.getDropdown(DrawingFormFields.type).select(Model[calculateResult.model] + "-" + Type[calculateResult.type]);
    pdfForm.getTextField(DrawingFormFields.DimB1).setText(`${calculateResult.widthMm}`);
    pdfForm.getTextField(DrawingFormFields.DimH1).setText(`${calculateResult.heightMm}`);
    pdfForm.getTextField(DrawingFormFields.DimD).setText(`${calculateResult.depthMm}`);
    pdfForm.getTextField(DrawingFormFields.DimH).setText(`${calculateResult.airHeightMm}`);
    pdfForm.getTextField(DrawingFormFields.DimB).setText(`${calculateResult.airWidthMm}`);
    pdfForm.getTextField(DrawingFormFields.DimD).setText(`${calculateResult.depthMm}`);
    calculateResult.type === Type.M || calculateResult.type === Type.ML
      ? pdfForm.getTextField(DrawingFormFields.DimK).setText(`150`)
      : pdfForm.getTextField(DrawingFormFields.DimK).setText(`200`);
    pdfForm.getTextField(DrawingFormFields.power).setText(`${calculateResult.powerKw}`);
    pdfForm
      .getDropdown(DrawingFormFields.voltage)
      .select(`${calculateResult.voltage.toString().charAt(0)}x${calculateResult.voltage.toString().slice(1)}V`);
    pdfForm.getDropdown(DrawingFormFields.material).select(Material[calculateResult.material]);
    pdfForm.getDropdown(DrawingFormFields.protection).select(IP_Class[calculateResult.ipClass]);
    pdfForm.getDropdown(DrawingFormFields.current).select(Insulation[calculateResult.insulation]);
    pdfForm
      .getDropdown(DrawingFormFields.maxOutTemp)
      .select(`${calculateResult.maxOutputTempC}${getUnitLabel("Celsius")}`);
    pdfForm.getTextField(DrawingFormFields.freeText).setText(calcRequest.notesDrawing);
    pdfForm.getDropdown(DrawingFormFields.airDirection).select(AirDirection[calculateResult.airDirection]);
    pdfForm.getDropdown(DrawingFormFields.cableInlet).select(CableInlet[calculateResult.cableInlet]);
    pdfForm.getDropdown(DrawingFormFields.glandSize).select(calculateResult.fullGlandEntryString);

    if (calculateResult.type === Type.M || calculateResult.type === Type.ML) {
      pdfForm.getTextField(DrawingFormFields.step1).setText(`${calculateResult.powerStepsKwText[0] ?? ""}`);
      pdfForm.getTextField(DrawingFormFields.step2).setText(`${calculateResult.powerStepsKwText[1] ?? ""}`);
      pdfForm.getTextField(DrawingFormFields.step3).setText(`${calculateResult.powerStepsKwText[2] ?? ""}`);
      pdfForm.getTextField(DrawingFormFields.step4).setText(`${calculateResult.powerStepsKwText[3] ?? ""}`);
      pdfForm.getTextField(DrawingFormFields.step5).setText(`${calculateResult.powerStepsKwText[4] ?? ""}`);
      pdfForm.getTextField(DrawingFormFields.step6).setText(`${calculateResult.powerStepsKwText[5] ?? ""}`);
    }
  } else if (
    (calculateResult.model === Model.VFL && calculateResult.type === Type.M) ||
    (calculateResult.model === Model.VFL && calculateResult.type === Type.ML) ||
    (calculateResult.model === Model.VFLPG && calculateResult.type === Type.M) ||
    (calculateResult.model === Model.VFLPG && calculateResult.type === Type.ML)
  ) {
    pdfForm.getDropdown(DrawingFormFields.type).select(Model[calculateResult.model] + "-" + Type[calculateResult.type]);
    pdfForm.getTextField(DrawingFormFields.DimB).setText(`${calculateResult.airWidthMm}`);
    pdfForm.getTextField(DrawingFormFields.DimH).setText(`${calculateResult.airHeightMm}`);
    pdfForm.getTextField(DrawingFormFields.DimD).setText(`${calculateResult.depthMm}`);
    pdfForm.getTextField(DrawingFormFields.power).setText(`${calculateResult.powerKw}`);
    pdfForm.getTextField(DrawingFormFields.step1).setText(`${calculateResult.powerStepsKwText[0] ?? ""}`);
    pdfForm.getTextField(DrawingFormFields.step2).setText(`${calculateResult.powerStepsKwText[1] ?? ""}`);
    pdfForm.getTextField(DrawingFormFields.step3).setText(`${calculateResult.powerStepsKwText[2] ?? ""}`);
    pdfForm.getTextField(DrawingFormFields.step4).setText(`${calculateResult.powerStepsKwText[3] ?? ""}`);
    pdfForm.getTextField(DrawingFormFields.step5).setText(`${calculateResult.powerStepsKwText[4] ?? ""}`);
    pdfForm.getTextField(DrawingFormFields.step6).setText(`${calculateResult.powerStepsKwText[5] ?? ""}`);
    pdfForm
      .getDropdown(DrawingFormFields.voltage)
      .select(`${calculateResult.voltage.toString().charAt(0)}x${calculateResult.voltage.toString().slice(1)}V`);
    pdfForm.getDropdown(DrawingFormFields.material).select(Material[calculateResult.material]);
    pdfForm.getDropdown(DrawingFormFields.protection).select(IP_Class[calculateResult.ipClass]);
    pdfForm.getDropdown(DrawingFormFields.current).select(Insulation[calculateResult.insulation]);
    pdfForm
      .getDropdown(DrawingFormFields.maxOutTemp)
      .select(`${calculateResult.maxOutputTempC}${getUnitLabel("Celsius")}`);
    pdfForm.getDropdown(DrawingFormFields.airDirection).select(AirDirection[calculateResult.airDirection]);
    pdfForm.getDropdown(DrawingFormFields.cableInlet).select(CableInlet[calculateResult.cableInlet]);
    pdfForm.getDropdown(DrawingFormFields.glandSize).select(calculateResult.fullGlandEntryString);
    if (calculateResult.connection === Connection.Circular) {
      if (calcRequest.meshInlet === 1 || calcRequest.meshOutlet === 1) {
        pdfForm
          .getDropdown(DrawingFormFields.connection2)
          .select("Ø" + calculateResult.widthMm + getUnitLabel("Millimeter"));
      } else {
        pdfForm
          .getDropdown(DrawingFormFields.connection)
          .select("Ø" + calculateResult.widthMm + getUnitLabel("Millimeter"));
      }
    }
  } else {
    pdfForm.getDropdown(DrawingFormFields.type).select(Model[calculateResult.model] + "-" + Type[calculateResult.type]);
    pdfForm.getTextField(DrawingFormFields.DimB).setText(`${calculateResult.widthMm}`);
    pdfForm.getTextField(DrawingFormFields.DimH).setText(`${calculateResult.heightMm}`);
    pdfForm.getTextField(DrawingFormFields.DimD).setText(`${calculateResult.depthMm}`);

    // dimK value is implemented to have a dropdown in the existing pdf for VFL/VFLPG, which in turn removes a black border around the value. We make this textfield below in order to make //black border around the dimK value. The purpose is to keep it consistent with the dimK boxes in quote for VRA/VTL (which has black borders around the value)

    const dimK = pdfForm.getFields().find((x) => x.getName() === DrawingFormFields.DimK);

    if (dimK) {
      const widget0 = dimK.acroField.getWidgets()[0];
      const AP = widget0.ensureAP();
      AP.set(PDFName.of("N"), PDFRef.of(0, 0));
      pdfForm.removeField(dimK);
    }
    const dimKTextField = pdfForm.createTextField(DrawingFormFields.DimK);

    dimKTextField.setAlignment(TextAlignment.Left);
    calculateResult.type === Type.M || calculateResult.type === Type.ML
      ? dimKTextField.setText(`150`)
      : dimKTextField.setText(`200`);
    const page = pdfForm.doc.getPage(0);
    dimKTextField.addToPage(page, {
      x: 270,
      y: 203,
      rotate: degrees(270),
      height: 18,
      width: 23,
    });

    // calculateResult.type === Type.M || calculateResult.type === Type.ML
    //   ? pdfForm.getDropdown(DrawingFormFields.DimK).select(`150`)
    //   : pdfForm.getDropdown(DrawingFormFields.DimK).select(`200`);
    pdfForm.getTextField(DrawingFormFields.power).setText(`${calculateResult.powerKw}`);
    pdfForm
      .getDropdown(DrawingFormFields.voltage)
      .select(`${calculateResult.voltage.toString().charAt(0)}x${calculateResult.voltage.toString().slice(1)}V`);
    pdfForm.getDropdown(DrawingFormFields.material).select(Material[calculateResult.material]);
    pdfForm.getDropdown(DrawingFormFields.protection).select(IP_Class[calculateResult.ipClass]);
    pdfForm.getDropdown(DrawingFormFields.current).select(Insulation[calculateResult.insulation]);
    pdfForm
      .getDropdown(DrawingFormFields.maxOutTemp)
      .select(`${calculateResult.maxOutputTempC}${getUnitLabel("Celsius")}`);

    if (calculateResult.connection === Connection.Circular) {
      if (calcRequest.meshInlet === 1 || calcRequest.meshOutlet === 1) {
        pdfForm
          .getDropdown(DrawingFormFields.connection2)
          .select("Ø" + calculateResult.widthMm + getUnitLabel("Millimeter"));
      } else {
        pdfForm
          .getDropdown(DrawingFormFields.connection)
          .select("Ø" + calculateResult.widthMm + getUnitLabel("Millimeter"));
      }
    }

    pdfForm.getDropdown(DrawingFormFields.airDirection).select(AirDirection[calculateResult.airDirection]);
    pdfForm.getDropdown(DrawingFormFields.cableInlet).select(CableInlet[calculateResult.cableInlet]);
    pdfForm.getDropdown(DrawingFormFields.glandSize).select(calculateResult.fullGlandEntryString);
  }
  pdfForm.getTextField(DrawingFormFields.freeText).setText(calcRequest.notesDrawing);

  // dimF22 is rubbish. To remove it, we need a workaround: https://github.com/Hopding/pdf-lib/issues/789#issuecomment-774551592

  const dimF22 = pdfForm.getFields().find((x) => x.getName() === DrawingFormFields.DimF22);

  if (dimF22) {
    const widget0 = dimF22.acroField.getWidgets()[0];
    const AP = widget0.ensureAP();
    AP.set(PDFName.of("N"), PDFRef.of(0, 0));
    pdfForm.removeField(dimF22);
  }
  // pdfForm.flatten(); broke the printout funtionality in Acrobat reader
  pdfForm.getFields().forEach((formField) => formField.enableReadOnly()); // https://github.com/Hopding/pdf-lib/issues/1387
}
