import React from "react";
import { Dispatch } from "@typescript-tea/core";
import { SharedState, Routes, createBlobUrlCreator } from "@ehb/client-infra";
import { Texts } from "@ehb/shared";
import * as State from "./state";
import { Action } from "./state";
import { DropdownInput } from "../elements/dropdown-input";

export function View({
  state,
  dispatch,
  sharedState,
}: {
  readonly state: State.State | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
}): JSX.Element {
  const translate = sharedState.translate;
  const images = state?.metaProduct?.product?.modules.images.image;
  const logoImage = images?.find((i) => i.name === "veab-logo");
  const bannerImage = images?.find((i) => i.name === "banner");
  const logoUrl =
    logoImage && logoImage.image && logoImage.file_name
      ? createBlobUrlCreator(sharedState.activeUser.accessToken)(logoImage.image, logoImage.file_name)
      : undefined;
  const bannerUrl =
    bannerImage && bannerImage.image && bannerImage.file_name
      ? createBlobUrlCreator(sharedState.activeUser.accessToken)(bannerImage.image, bannerImage.file_name)
      : undefined;
  return (
    <div
      className="w-full flex flex-row flex-nowrap justify-between border-b p-6 bg-cover bg-no-repeat bg-center shrink-0 "
      style={{
        backgroundImage: `url('${bannerUrl}')`,
        minWidth: "100%",
      }}
    >
      <div className="pb-4 pt-4 flex">
        <a
          className="w-[12rem] h-[3rem]"
          href={Routes.buildUrl(Routes.RootLocation.MainLocation(Routes.MainLocation.StartPage()))}
        >
          {logoUrl ? <img src={logoUrl} className="" /> : undefined}
        </a>
      </div>

      <h1 className="font-bold font-myriad-pro not-italic text-white hidden md:flex text-center lg:text-4xl items-center">
        Heating and Cooling Solutions For Life
      </h1>

      <div className="flex flex-row justify-end ml-5 md:ml-5 items-start space-x-8 text-white max-h-24 text-xs">
        <LangugeSelect
          className="text-neutral"
          languageTable={state?.metaProduct?.product?.modules?.languages}
          dispatch={dispatch}
          sharedState={sharedState}
        />
        <div className="leading-[30px] text-white whitespace-nowrap">{sharedState.activeUser.userName}</div>
        <button className="btn-primary" onClick={() => dispatch(State.Action.Logout())}>
          {translate(Texts.texts.logout)}
        </button>
      </div>
    </div>
  );
}

function LangugeSelect({
  languageTable,
  dispatch,
  sharedState,
  className,
}: {
  readonly languageTable: Texts.LanguageTable | undefined;
  readonly dispatch: Dispatch<State.Action>;
  readonly sharedState: SharedState.SharedState;
  readonly className: string;
}): JSX.Element {
  if (!languageTable) {
    return <div className={className} />;
  }

  const langugesOptions = Texts.getLanguages(languageTable).map((l) => ({
    key: l,
    label: l,
    value: l,
    language: l,
  }));
  return (
    <div className={className}>
      <DropdownInput
        options={langugesOptions}
        value={langugesOptions.find((o) => o.key === sharedState.selectedLanguage) || langugesOptions[0]}
        onChange={(o) => dispatch(Action.ChangeLanguage(o.value))}
      />
    </div>
  );
}
