import { exhaustiveCheck } from "ts-exhaustive-check";

export type Url = {
  readonly protocol: Protocol;
  readonly host: string;
  readonly port: number | undefined;
  readonly path: string;
  readonly query: string | undefined;
  readonly fragment: string | undefined;
};

export type Protocol = "http:" | "https:";

export function fromString(url: string): Url {
  const myUrl = new URL(url);
  if (myUrl.protocol !== "http:" && myUrl.protocol !== "https:") {
    throw new Error(`Invalid protocol for url ${myUrl.protocol}`);
  }
  const port = myUrl.port !== undefined && myUrl.port !== "" ? parseInt(myUrl.port, 10) : undefined;
  return {
    protocol: myUrl.protocol,
    host: myUrl.host,
    port: port,
    path: myUrl.pathname,
    query: myUrl.search === "" ? undefined : myUrl.search,
    fragment: myUrl.hash === "" ? undefined : myUrl.hash,
  };
}

export function toString(url: Url): string {
  const http = (() => {
    switch (url.protocol) {
      case "http:":
        return "http://";
      case "https:":
        return "https://";
      default:
        return exhaustiveCheck(url.protocol);
    }
  })();
  const withPort = addPort(url.port, http + url.host + url.path);
  const withQuery = addPrefixed("?", url.query, withPort);
  const withFragment = addPrefixed("#", url.fragment, withQuery);
  return withFragment;
}

function addPort(maybePort: number | undefined, starter: string): string {
  if (maybePort === undefined) {
    return starter;
  }
  return starter + ":" + maybePort;
}

function addPrefixed(prefix: string, maybeSegment: string | undefined, starter: string): string {
  if (maybeSegment === undefined) {
    return starter;
  }
  return starter + prefix + maybeSegment;
}
