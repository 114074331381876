import * as React from "react";
import { AbstractDocJsx as ADX, AbstractDoc as AD } from "abstract-document";
import { TranslateFn } from "../../lang-texts";

const { Table, TableRow, TableCell, Paragraph, Group, TextRun, Image, render } = ADX;

export function createHeader(
  logo: AD.ImageResource.ImageResource | undefined,
  _translate: TranslateFn,
  _title: string | undefined | null
  // eslint-disable-next-line functional/prefer-readonly-type
): AD.SectionElement.SectionElement[] {
  return [
    render(
      <Group>
        <Table
          columnWidths={[Infinity]}
          style={AD.TableStyle.create({ margins: AD.LayoutFoundation.create({ top: 30, bottom: 20 }) })}
        >
          <TableRow>
            <TableCell>
              <Paragraph
                style={AD.ParagraphStyle.create({
                  position: "relative",
                  alignment: "Start",
                  margins: AD.LayoutFoundation.create({ top: 10, bottom: 10 }),
                })}
              >
                {logo && <Image imageResource={logo} width={150} height={40} />}
              </Paragraph>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={AD.TableCellStyle.create({
                borders: AD.LayoutFoundation.create({ bottom: 1 }),
              })}
            >
              <Paragraph>
                <TextRun text={""} />
              </Paragraph>
            </TableCell>
          </TableRow>
        </Table>
      </Group>
    ),
  ];
}
