export interface User {
  readonly id_token: string;
  readonly session_state?: unknown;
  readonly access_token: string;
  readonly token_type: string;
  readonly scope: string;
  readonly profile: unknown;
  readonly expires_at: number;
  readonly state: unknown;
  readonly expires_in: number | undefined;
  readonly expired: boolean | undefined;
  readonly scopes: readonly string[];
}
