import { Quantity } from "uom-units";

export type AnyQuantity =
  | Quantity.Acceleration
  | Quantity.Alkalinity
  | Quantity.AmountOfSubstance
  | Quantity.Angle
  | Quantity.Area
  | Quantity.CatalyticActivity
  | Quantity.DataAmount
  | Quantity.DeltaDewPointTemperature
  | Quantity.DeltaTemperature
  | Quantity.Density
  | Quantity.DewPointTemperature
  | Quantity.Dimensionless
  | Quantity.DimensionlessPerEnergy
  | Quantity.DimensionlessPerMass
  | Quantity.DimensionlessPerVolume
  | Quantity.DimensionlessPerDuration
  | Quantity.Discrete
  | Quantity.Duration
  | Quantity.ElectricCapacitance
  | Quantity.ElectricCharge
  | Quantity.ElectricConductance
  | Quantity.ElectricInductance
  | Quantity.ElectricCurrent
  | Quantity.ElectricPotential
  | Quantity.ElectricResistance
  | Quantity.Emission
  | Quantity.Energy
  | Quantity.Force
  | Quantity.Frequency
  | Quantity.HeatCapacityRate
  | Quantity.HeatingValue
  | Quantity.HumidityRatio
  | Quantity.Illuminance
  | Quantity.Intensity
  | Quantity.Length
  | Quantity.LuminousFlux
  | Quantity.LuminousIntensity
  | Quantity.MagneticFlux
  | Quantity.MagneticFluxDensity
  | Quantity.Mass
  | Quantity.MassFlow
  | Quantity.MassFlowPerArea
  | Quantity.MomentOfInertia
  | Quantity.Power
  | Quantity.Pressure
  | Quantity.RelativeHumidity
  | Quantity.SolidAngle
  | Quantity.SoundPowerLevel
  | Quantity.SoundPressureLevel
  | Quantity.SpecificEnthalpy
  | Quantity.SpecificFanPower
  | Quantity.SpecificHeatCapacity
  | Quantity.SquareRootPressure
  | Quantity.Temperature
  | Quantity.Text
  | Quantity.Velocity
  | Quantity.Viscosity
  | Quantity.Volume
  | Quantity.VolumeFlow
  | Quantity.VolumeFlowPerArea
  | Quantity.VolumeFlowPerPower
  | Quantity.VolumeFlowPerSquareRootPressure
  | Quantity.WaterHardness
  | Quantity.WaterUseEfficiency
  | Quantity.WetTemperature
  | Quantity.ThermalTransmittance
  | Quantity.ThermalConductivity;
