import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { CalculatorFrtCoil, SelectableFormat, FP } from "@ehb/shared";
import { PropertyValueSet } from "@promaster-sdk/property";
import { PromiseEffectManager, SharedState } from "@ehb/client-infra";
import { clientConfig } from "@ehb/client-infra/src/client-config";
import { decodePropertyValueSet } from "@ehb/shared/src/product-utils";
import { specialCalculationProductQuery } from "@ehb/shared/src/graphql-schema";
import { SharedStateAction } from "@ehb/client-infra/src/shared-state";
import * as GQLOps from "../../generated/generated-operations";
import {
  generateDefaultProperties,
  resetCalcParamsToDefault,
} from "../../elements/properties-selector/property-selector-def";

type LocationParams = { readonly query: { readonly [query: string]: string } };

export type State = {
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly productQuery: GQLOps.SpecialCalculationProductQuery | undefined;
  readonly calculating: boolean;
  readonly calculationResults: CalculatorFrtCoil.CalculationResultRaw | undefined;
  readonly locationParams: LocationParams;
};

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState,
  locationParams: LocationParams
): readonly [State, Cmd<Action>?] {
  if (prevState) {
    const newVariant = decodePropertyValueSet(locationParams.query, sharedState.activeUser);
    const merged = PropertyValueSet.merge(newVariant, prevState.variant);

    return [{ ...prevState, variant: merged }];
  }

  const gqlCmdProduct = sharedState.graphQLProductQuery<
    GQLOps.SpecialCalculationProductQuery,
    GQLOps.SpecialCalculationProductQueryVariables,
    Action
  >(
    specialCalculationProductQuery,
    { productId: clientConfig.promaster_special_calculation_product_id, language: sharedState.selectedLanguage },
    (data) => {
      return Action.ProductReceived(data);
    }
  );

  const variant = decodePropertyValueSet(locationParams.query, sharedState.activeUser);

  const initialState: State = {
    locationParams,
    productQuery: undefined,
    variant: PropertyValueSet.setInteger("mode", 2, variant), // mode=2 for product page
    calculating: false,
    calculationResults: undefined,
  };

  return [initialState, Cmd.batch([gqlCmdProduct])];
}

export const Action = ctorsUnion({
  ProductReceived: (data: GQLOps.SpecialCalculationProductQuery) => ({ data }),
  SetVariant: (variant: PropertyValueSet.PropertyValueSet) => ({ variant }),
  UpdateCalculationResult: (result: CalculatorFrtCoil.CalculationResultRaw) => ({ result }),
  SetSelectedFormat: (fieldName: string, selectedFormat: SelectableFormat) => ({ fieldName, selectedFormat }),
  SetFieldUnit: (fieldName: string, unit: string, decimalCount: number) => ({
    fieldName,
    unit,
    decimalCount,
  }),
  ClearFieldUnit: (fieldName: string) => ({ fieldName }),
  StartCalculate: () => ({}),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "ProductReceived": {
      const variant = action.data.product
        ? generateDefaultProperties(action.data.product.modules.properties.property, state.variant)
        : PropertyValueSet.Empty;
      const newState = { ...state, productQuery: action.data, variant };

      return [newState];
    }

    case "SetVariant": {
      const productProperties = state.productQuery?.product?.modules.properties.property;
      if (!productProperties) {
        return [state];
      }
      const newVariant = resetCalcParamsToDefault(productProperties, state.variant, action.variant);
      const newState = { ...state, variant: newVariant };
      return [newState];
    }

    case "UpdateCalculationResult": {
      return [{ ...state, calculating: false, calculationResults: action.result }];
    }

    case "SetSelectedFormat": {
      return [state, undefined, SharedStateAction.SetSelectedFormat(action.fieldName, action.selectedFormat)];
    }
    case "ClearFieldUnit": {
      return [state, undefined, SharedStateAction.ClearFieldUnit(action.fieldName)];
    }
    case "SetFieldUnit": {
      return [state, undefined, SharedStateAction.SetFieldUnit(action.fieldName, action.unit, action.decimalCount)];
    }

    case "StartCalculate": {
      return [state, calculateCmd(state.variant)];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function calculateCmd(
  properties: PropertyValueSet.PropertyValueSet
): PromiseEffectManager.PromiseEffect<Action, never, CalculatorFrtCoil.CalculationResultRaw> {
  return PromiseEffectManager.perform<Action, CalculatorFrtCoil.CalculationResultRaw>(
    (data) => Action.UpdateCalculationResult(data),
    (async (): Promise<FP.Result<never, CalculatorFrtCoil.CalculationResultRaw>> => {
      return {
        type: "Ok",
        value: await calculate(properties),
      };
    })()
  );
}

export async function calculate(
  properties: PropertyValueSet.PropertyValueSet
): Promise<CalculatorFrtCoil.CalculationResultRaw> {
  const input = CalculatorFrtCoil.mapSpecialCalculationInput(properties);
  if (input.type === "Err") {
    return input;
  }
  return CalculatorFrtCoil.calculateRaw(input.value);
}
