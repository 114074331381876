import { Units } from "uom-units";
import {
  PropertyFilter as PF,
  PropertyValueSet,
  PropertyValue as PV,
  PropertyValueSet as PVS,
} from "@promaster-sdk/property";
import { SelectableFormat } from "@promaster-sdk/react-property-selectors";
import { Amount } from "uom";
import { EhbUnits, EhbUnitsLookup, FP, log, Texts } from "@ehb/shared";
import { VeabConstants } from "@ehb/shared/src/calculator/constants";
import { Connection, Model, Type } from "@ehb/shared/src/calculator";
import { calculateAirVelocity } from "@ehb/shared/src/calculator/air-functions";
import { mapProperties } from "@ehb/shared/src/product-properties";
import { isNonNull, mapPropertyFilter } from "@ehb/shared/src/utils";
import { ActiveUser, getUserClaimPropertyNames, isInternalUser } from "@ehb/shared/src/user";
import { TranslateFn } from "@ehb/shared/src/lang-texts";
import * as GQLOps from "../../generated/generated-operations";

// TODO: combine with code in @shared/product-properties?

export type PropertySelectorDef = AmountPropertyDef | DiscretePropertyDef | TextPropertyDef;

export type PropertyImage = {
  readonly position: string;
  readonly image: string | undefined;
};

export type PropertyInfo = {
  readonly exists: boolean;
  readonly textKey: string;
};

export type PropertyDefBase = {
  readonly sortNo: number;
  readonly name: string;
  readonly group: string;
  readonly quantity: string;
  readonly translation: string;
  readonly validationFilter: PF.PropertyFilter;
  readonly visibilityFilter: PF.PropertyFilter;
  readonly defaultValue: string;
  readonly propertyImage: PropertyImage | undefined;
  readonly overridable: boolean;
  readonly propInfo: PropertyInfo;
  readonly readOnly: boolean;
};

export type AmountPropertyDef = PropertyDefBase & {
  readonly type: "Amount";
  readonly selectableFormats: ReadonlyArray<SelectableFormat>;
  readonly fieldName: string;
};

export type DiscretePropertyDef = PropertyDefBase & {
  readonly type: "Discrete";
  readonly selectorType: string;
  readonly items: ReadonlyArray<PropertyValueDef>;
};

export type TextPropertyDef = PropertyDefBase & {
  readonly type: "Text";
  readonly selectorType: string | undefined;
};

export type DiscretePropertyInfo<TPropertyValueDef> = {
  readonly type: "Discrete";
  readonly name: string;
  readonly group: string;
  readonly validationFilter: PF.PropertyFilter;
  readonly visibilityFilter: PF.PropertyFilter;
  readonly items: ReadonlyArray<TPropertyValueDef>;
};

export type PropertyValueDef = {
  readonly sortNo: number;
  readonly image?: string;
  readonly value: PV.PropertyValue | undefined | null;
  readonly validationFilter: PF.PropertyFilter;
  readonly text: string;
};

export type PropertySelectorTranslations = {
  readonly value_is_required: string;
  readonly value_must_be_numeric: string;
  readonly pfp_equals: string;
  readonly pfp_notEquals: string;
  readonly pfp_lessOrEqual: string;
  readonly pfp_greaterOrEqual: string;
  readonly pfp_less: string;
  readonly pfp_greater: string;
  readonly pfp_between: string;
  readonly pfp_and: string;
  readonly pfp_or: string;
};

type CustomValidationFilter = { readonly propertyName: string; readonly validationFilter: string };
export function mapPropertiesToSelector(
  user: ActiveUser,
  product: GQLOps.ProductQuery | GQLOps.SpecialCalculationProductQuery | undefined,
  pvs: PVS.PropertyValueSet,
  translate: TranslateFn,
  validationFilter?: CustomValidationFilter | undefined
): ReadonlyArray<PropertySelectorDef> {
  // Works but it's probably not the best way to disabled these properties
  const readonlyProperties = !isInternalUser(user.claims) ? getUserClaimPropertyNames() : [];

  const selectorTypes = FP.createMapFilter(
    product?.product?.modules.custom_tables?.property_selector_type ?? [],
    (r) => PF.isValid(pvs, PF.fromStringOrEmpty(r.property_filter ?? "", EhbUnitsLookup)),
    (r) => r.property ?? "",
    (r) => r.selector ?? ""
  );

  const overridableProperties = FP.createMap(
    product?.product?.modules.custom_tables?.property_overridable ?? [],
    (r) => r.property ?? "",
    (r) => (r.overridable === "yes" ? true : false)
  );
  const infoProperties = FP.createMap(
    product?.product?.modules.custom_tables?.property_info ?? [],
    (r) => r.property ?? "",
    (r) => (r.property ? { exists: true, textKey: r.text_key ?? "" } : { exists: false, textKey: "" })
  );
  const propertyImages = FP.createMapFilter(
    product?.product?.modules.custom_tables?.property_image ?? [],
    (r) => PF.isValid(pvs, PF.fromStringOrEmpty(r.property_filter ?? "", EhbUnitsLookup)),
    (r) => r.property ?? "",
    (r) => ({ position: r.position ?? "above", image: r.image ?? "" })
  );
  const propertyFieldName = FP.createMap(
    product?.product?.modules.custom_tables?.property_field_name ?? [],
    (r) => r.property || "",
    (r) => r.field_name || ""
  );

  const validationFilters = getCombinedValidationFilters(product, pvs);

  return (product?.product?.modules.properties.property ?? []).map((propertyRow) => {
    const propertyQuantity = propertyRow.quantity;
    const propertyName = propertyRow.name ?? "";

    const propImage = propertyImages[propertyName] ?? undefined;
    const propInfo = infoProperties[propertyName] ?? undefined;
    const overridable = overridableProperties[propertyName] ?? true;
    const customValidationFilter =
      validationFilter?.propertyName === propertyName ? validationFilter.validationFilter : undefined;
    const base: PropertyDefBase = {
      sortNo: propertyRow.sort_no ?? 0,
      name: propertyName,
      group: propertyRow.group ?? "",
      quantity: propertyQuantity ?? "",
      validationFilter: customValidationFilter
        ? PF.fromString(customValidationFilter ?? "", EhbUnitsLookup) || PF.Empty
        : validationFilters.get(propertyName) || PF.Empty,
      visibilityFilter: PF.fromString(propertyRow.visibility_filter ?? "", EhbUnitsLookup) || PF.Empty,
      defaultValue: propertyRow.defaults[0]?.value ?? "",
      translation: translate(Texts.propertyNameKey(propertyName, product!.product!.key)),
      // translation: propertyRow.translations.find((t) => t.type === "standard")?.translation ?? propertyName,
      propertyImage: propImage,
      overridable: overridable,
      propInfo: propInfo,
      readOnly: readonlyProperties.includes(propertyName),
    };

    switch (base.quantity) {
      case "Discrete": {
        return {
          type: "Discrete",
          ...base,
          selectorType: selectorTypes[propertyName] ?? "dropdown",
          items: propertyRow.values.map((v) => {
            const value = v.value ?? "";
            return {
              text: translate(
                Texts.propertyValueKey(
                  propertyName,
                  PropertyValueSet.setInteger(propertyName, Number(v.value!), PropertyValueSet.Empty),
                  product!.product!.key
                ),
                v.description || ""
              ),
              sortNo: v.sort_no ?? 0,
              value: PV.fromString(value, EhbUnitsLookup)!,
              image: v.image ?? "",
              validationFilter: PF.fromString(v.property_filter ?? "", EhbUnitsLookup)!,
            };
          }),
        };
      }
      case "Text": {
        return {
          type: "Text",
          selectorType: selectorTypes[propertyName] ?? undefined,
          ...base,
        };
      }
      default: {
        return {
          type: "Amount",
          selectableFormats: [],
          fieldName: propertyFieldName[propertyName] || propertyName,
          ...base,
        };
      }
    }
  });
}

export function getCombinedValidationFilters(
  product: GQLOps.ProductQuery | GQLOps.SpecialCalculationProductQuery | undefined,
  pvs: PVS.PropertyValueSet
): ReadonlyMap<string, PF.PropertyFilter> {
  const extraValidations = mapPropertyFilter(product?.product?.modules.custom_tables.property_validation || [])
    .filter((r) => PF.isValid(pvs, r.property_filter))
    .map((r) => ({ property: r.property, validation_filter: r.validation_filter }))
    .filter(isNonNull);
  const propertyValidations = (product?.product?.modules.properties.property || [])
    .map((r) => ({ property: r.name, validation_filter: r.validation_filter }))
    .filter(isNonNull);

  const filtersByProperty = new Map<string, Array<string>>();
  for (const { property, validation_filter } of [...extraValidations, ...propertyValidations]) {
    if (validation_filter.trim() === "") {
      continue;
    }
    const cur = filtersByProperty.get(property) || [];
    cur.push(validation_filter);
    filtersByProperty.set(property, cur);
  }

  const filterByProperty = new Map<string, PF.PropertyFilter>();
  for (const p of product?.product?.modules.properties.property || []) {
    if (!p.name) {
      continue;
    }
    const filters = filtersByProperty.get(p.name || "");
    if (filters) {
      filterByProperty.set(p.name, PF.fromStringOrEmpty(filters.map((f) => `(${f})`).join("&"), EhbUnitsLookup));
    } else {
      filterByProperty.set(p.name, PF.Empty);
    }
  }

  return filterByProperty;
}

export function generateDefaultProperties(
  productPropertiesGql: ReadonlyArray<GQLOps.PropertyModuleFragment>,
  variant: PVS.PropertyValueSet = PVS.Empty
): PVS.PropertyValueSet {
  const productProperties = mapProperties(productPropertiesGql);
  let variantSoFar = variant;
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < productProperties.length; i++) {
    let variantIsChanged = false;
    for (const p of productProperties) {
      if (PVS.hasProperty(p.name, variant)) {
        PVS.set(p.name, PVS.getValue(p.name, variant), variantSoFar);
        continue;
      }
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      const defValue = p.defValues.find((v) => PF.isValid(variantSoFar, v.propertyFilter));
      if (!defValue) {
        continue;
      }
      if (p.quantity === "Discrete" && defValue.value.type === "integer") {
        variantSoFar = PVS.set(p.name, defValue.value, variantSoFar);
      } else if (p.quantity === "Text" && defValue.value.type === "text") {
        variantSoFar = PVS.set(p.name, defValue.value, variantSoFar);
      } else if (defValue.value.type === "amount") {
        variantSoFar = PVS.set(p.name, defValue.value, variantSoFar);
      }
      variantIsChanged = true;
    }
    if (!variantIsChanged) {
      break;
    }
  }

  return variantSoFar;
}

export function resetCalcParamsToDefault(
  productProperties: ReadonlyArray<GQLOps.PropertyModuleFragment>,
  prevPvs: PVS.PropertyValueSet,
  nextPvs: PVS.PropertyValueSet
): PVS.PropertyValueSet {
  const calcParams = productProperties
    .filter((p) => p.group?.includes("calculation_parameters"))
    .map((p) => p.name || "");

  const prevVariant = PVS.removeProperties(calcParams, prevPvs);
  const nextVariant = PVS.removeProperties(calcParams, nextPvs);

  if (PVS.equals(prevVariant, nextVariant)) {
    return nextPvs;
  }

  const prevDefaults = generateDefaultProperties(productProperties, prevVariant);
  const nextDefaults = generateDefaultProperties(productProperties, nextVariant);

  const changedDefaults: Array<string> = [];
  for (const calcParam of calcParams) {
    const prev = PVS.get(calcParam, prevDefaults);
    const next = PVS.get(calcParam, nextDefaults);
    if (!prev && !next) {
      continue;
    } else if (!prev || !next) {
      changedDefaults.push(calcParam);
    } else if (!PV.equals(prev, next)) {
      changedDefaults.push(calcParam);
    }
  }

  const nextDefaultsToSet = PVS.keepProperties(changedDefaults, nextDefaults);
  const newNextPvs = PVS.merge(nextDefaultsToSet, nextPvs);

  return newNextPvs;
}

export function generateDefaultValueForProperty(
  productProperties: ReadonlyArray<GQLOps.PropertyModuleFragment>,
  selectedProperties: PVS.PropertyValueSet,
  propertyName: string
): PVS.PropertyValueSet {
  let properties = PVS.Empty;
  productProperties.forEach((p) => {
    if (p.name === propertyName) {
      if (p.defaults.length === 1 && p.name) {
        const defaultValue = p.defaults[0]?.value;
        if (defaultValue) {
          const defaultPropertyValue = PV.fromString(defaultValue, EhbUnitsLookup);
          if (defaultPropertyValue) {
            properties = PVS.set(p.name, defaultPropertyValue, properties);
          }
        }
      } else if (p.defaults.length > 1 && p.name) {
        const filterDefaultValue = p.defaults.filter((r) =>
          PF.isValid(selectedProperties, PF.fromStringOrEmpty(r.property_filter ?? "", EhbUnitsLookup))
        );
        const defaultPropertyValue = PV.fromString(filterDefaultValue[0]?.value ?? "", EhbUnitsLookup);
        if (defaultPropertyValue) {
          properties = PVS.set(p.name, defaultPropertyValue ?? "", properties);
        }
      }
    }
  });
  return properties;
}

export function updateAmountFormat(
  productProperties: ReadonlyArray<GQLOps.PropertyModuleFragment>,
  selectedProperties: PVS.PropertyValueSet,
  propertyName: string,
  selectedFormat: SelectableFormat
): PVS.PropertyValueSet {
  let properties = selectedProperties;
  productProperties.forEach((p) => {
    if (p.name === propertyName) {
      const propertyValue = PVS.getValue(propertyName, selectedProperties);
      if (propertyValue && propertyValue.type === "amount") {
        const newAmount = Amount.create(
          Amount.valueAs(selectedFormat.unit ?? EhbUnits.CubicMeterPerHour, propertyValue.value),
          selectedFormat.unit,
          selectedFormat.decimalCount
        );
        const amount = PV.getAmount({ ...propertyValue, value: newAmount });
        properties = PVS.setAmount(propertyName, { ...amount } as Amount.Amount<unknown>, properties);
      }
    }
  });
  return properties;
}

export function createValidationFilterForAirVolume(variant: PVS.PropertyValueSet): CustomValidationFilter {
  const heightAmount = PVS.getAmount<"Length">("height", variant);
  const widthAmount = PVS.getAmount<"Length">("width", variant);
  let heightMm = heightAmount && Amount.valueAs(Units.Millimeter, heightAmount);
  let widthMm = widthAmount && Amount.valueAs(Units.Millimeter, widthAmount);
  const connection: Connection = PVS.getInteger("connection", variant) || 1;
  const diameterValue = connection === Connection.Circular ? PV.getInteger(PVS.getValue("diameter", variant)) || 0 : 0;

  const model: Model = PVS.getInteger("model", variant) || 1;
  const type: Type = PVS.getInteger("type", variant) || 1;
  if (connection === Connection.Circular) {
    const diameter = Amount.create(diameterValue, EhbUnits.Millimeter).value;
    heightMm = diameter;
    widthMm = diameter;
  }
  const airvolume = PVS.getAmount("airvolume", variant);
  if (!heightMm || !widthMm || heightMm < 0 || widthMm < 0 || heightMm > 2520 || widthMm > 2520) {
    return { propertyName: "airvolume", validationFilter: "" };
  }

  if (model === Model.VRA) {
    widthMm -=
      type === Type.MTXL || type === Type.MQXL
        ? VeabConstants.VRA_Type_Width_With_Regulator
        : VeabConstants.VRA_Type_Width_Without_Regulator;
    if (widthMm < 0) {
      widthMm = 0;
    }
    heightMm -= VeabConstants.VRA_Type_Height;

    if (heightMm < 0) {
      heightMm = 0;
    }
  }
  const areaM2 = Math.round((widthMm / 1000) * (heightMm / 1000) * 1000) / 1000;
  const minVelocity = 1.5;
  const maxVelocity = 6;
  // Calculate the minimum and maximum air volumes based on velocity limits
  let minAirVolumeM3h = areaM2 * minVelocity * 3600;
  let maxAirVolumeM3h = areaM2 * maxVelocity * 3600;

  // Find the min air volume that results in a calculated air velocity >= 1.5 m/s
  while (calculateAirVelocity(areaM2, minAirVolumeM3h) >= minVelocity) {
    minAirVolumeM3h -= 0.01;
  }

  // Find the max air volume that results in a calculated air velocity <= 6 m/s
  while (calculateAirVelocity(areaM2, maxAirVolumeM3h) <= maxVelocity) {
    maxAirVolumeM3h += 0.01;
  }

  const amountMinAirVolume = Amount.create(minAirVolumeM3h, EhbUnits.CubicMeterPerHour);
  const amountMaxAirVolumeM3h = Amount.create(maxAirVolumeM3h, EhbUnits.CubicMeterPerHour);
  const unit = EhbUnitsLookup(airvolume ? airvolume.unit.name : "CubicMeterPerHour");
  const maxAirVolume = Amount.valueAs(unit ?? EhbUnits.CubicMeterPerHour, amountMaxAirVolumeM3h);
  const minAirVolume = Amount.valueAs(unit ?? EhbUnits.CubicMeterPerHour, amountMinAirVolume);
  const validationFilter = `airvolume>${Math.round(minAirVolume * 100) / 100}:${
    airvolume?.unit.name ?? "CubicMeterPerHour"
  }&airvolume<${Math.round(maxAirVolume * 100) / 100}:${airvolume?.unit.name ?? "CubicMeterPerHour"}`;

  return {
    propertyName: "airvolume",
    validationFilter: validationFilter ?? "",
  };
}

export function generateBlankProperties(
  productProperties: ReadonlyArray<GQLOps.PropertyModuleFragment>
): PVS.PropertyValueSet {
  let properties = PVS.Empty;
  productProperties.forEach((p) => {
    if (p.name) {
      const blankPropertyValue = PV.fromString("0", EhbUnitsLookup);
      if (blankPropertyValue) {
        properties = PVS.set(p.name, blankPropertyValue, properties);
      }
    }
  });
  return properties;
}

export function setSelectedDefaultProperties(
  productProperties: ReadonlyArray<GQLOps.PropertyModuleFragment>,
  selectedProperties: PVS.PropertyValueSet
): PVS.PropertyValueSet {
  let properties = selectedProperties;
  productProperties.forEach((p) => {
    if (p.defaults.length === 1 && p.name) {
      const defaultValue = p.defaults[0]?.value;
      if (defaultValue) {
        const defaultPropertyValue = PV.fromString(defaultValue, EhbUnitsLookup);
        if (defaultPropertyValue) {
          properties = PVS.set(p.name, defaultPropertyValue, properties);
        }
      }
    }
    if (p.defaults.length > 1 && p.name) {
      p.defaults.forEach((v) => {
        const isValid = PF.isValid(
          selectedProperties,
          PF.fromString(v.property_filter ?? "", EhbUnitsLookup)! ?? undefined
        );
        log(isValid, p.name);
        if (isValid && p.name) {
          const defaultValue = v.value;
          if (defaultValue) {
            const defaultPropertyValue = PV.fromString(defaultValue, EhbUnitsLookup);
            if (defaultPropertyValue) {
              properties = PVS.set(p.name, defaultPropertyValue, properties);
            }
          }
        }
      });
    }
  });
  return properties;
}
