import { PropertyFilter, PropertyValue, PropertyValueSet } from "@promaster-sdk/property";
import { unitLookup } from "uom-units";
import * as Q from "./query";
import * as Types from "./types";
import { Mutable } from "../utils";
import { Texts } from "..";
import { texts } from "../lang-texts";

const allValue = "_all";

export function propertiesToSearchFilter(
  data: Types.SearchData,
  properties: PropertyValueSet.PropertyValueSet,
  ignoreHiddenProperties: boolean = false
): Types.Filter {
  const { searchInput, searchColumnProperties } = data;

  const filter: Mutable<Types.Filter> = {};

  for (const row of searchColumnProperties) {
    const columnName = row.column_name;
    const propertyName = row.property;
    if (ignoreHiddenProperties && !PropertyFilter.isValid(properties, row.property_filter)) {
      continue;
    }
    if (!Types.isSearchColumn(columnName) || !propertyName) {
      continue;
    }
    const value = PropertyValueSet.getText(propertyName, properties);
    if (!value || value === allValue) {
      continue;
    }
    filter[columnName] = value;
  }

  const inputRows = searchInput.filter(
    (r) => !r.property_filter || PropertyFilter.isValid(properties, r.property_filter)
  );
  for (const row of inputRows) {
    for (const col of Types.searchColumns) {
      const value = row[col];
      if (value) {
        filter[col] = value;
      }
    }
  }

  return filter;
}

export function selectionToProperties(
  searchColumnPropertiesTable: Q.SearchColumnPropertiesTable,
  selectionProperties: ReadonlyArray<Types.SelectionToProperties>,
  selection: Types.Selection
): PropertyValueSet.PropertyValueSet | undefined {
  let pvs: PropertyValueSet.PropertyValueSet | undefined = undefined;
  for (const sp of selectionProperties) {
    const cols = Object.keys(sp.selection) as ReadonlyArray<keyof Types.Selection>;
    let foundMatch = true;
    for (const col of cols) {
      if (selection[col] !== sp.selection[col]) {
        foundMatch = false;
        break;
      }
    }
    if (foundMatch) {
      pvs = sp.properties;
      break;
    }
  }
  if (!pvs) {
    return undefined;
  }

  for (const row of searchColumnPropertiesTable) {
    const col = row.column_name;
    const property = row.property;
    if (!Types.isSearchColumn(col) || !property) {
      continue;
    }
    pvs = PropertyValueSet.setText(property, selection[col], pvs);
  }

  return pvs;
}

export type PropertyValueDef = {
  readonly validationFilter: PropertyFilter.PropertyFilter;
  readonly value: PropertyValue.PropertyValue;
  readonly text: string;
};

export function getPropertyValues(
  translate: Texts.TranslateFn,
  data: Types.SearchData,
  result: Types.Result,
  propertyName: string
): ReadonlyArray<PropertyValueDef> | undefined {
  const { searchColumnProperties } = data;

  const searchColumn = searchColumnProperties.find((r) => r.property === propertyName)?.column_name;
  if (!searchColumn || !Types.isSearchColumn(searchColumn)) {
    return undefined;
  }

  const values: Array<PropertyValueDef> = [];

  values.push({
    validationFilter: PropertyFilter.Empty,
    value: {
      type: "text",
      value: allValue,
    },
    text: translate(texts.search_all),
  });

  const attributeValues: ReadonlyArray<PropertyValueDef> =
    result.attributes
      .find((a) => a.name === searchColumn)
      ?.values.map((v) => v.value)
      ?.map((v) => ({
        validationFilter: PropertyFilter.Empty,
        value: {
          type: "text",
          value: v,
        },
        text: v,
      })) || [];
  values.push(...attributeValues);

  const selectedValue = result.query.filter[searchColumn];
  if (selectedValue && !attributeValues.some((a) => a.value.value === selectedValue)) {
    values.push({
      validationFilter: PropertyFilter.fromStringOrEmpty("1=0", unitLookup),
      value: {
        type: "text",
        value: selectedValue,
      },
      text: selectedValue,
    });
  }

  return values;
}
