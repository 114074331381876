import * as R from "ramda";
import { AbstractDoc as AD } from "abstract-document";

export function numberingDefinitions(
  _styles: AD.Types.Indexer<AD.Style.Style>
): AD.Types.Indexer<AD.NumberingDefinition.NumberingDefinition> {
  //const boldStyle = styles[AD.StyleKey.create("TextStyle", "Default")] as AD.TextStyle.TextStyle;
  const numberDefs: Array<[string, AD.NumberingDefinition.NumberingDefinition]> = [
    [
      "Ordered",
      AD.NumberingDefinition.create({
        levels: [
          AD.NumberingLevelDefinition.create({
            level: 0,
            format: "Decimal",
            start: 1,
            levelText: "%1.",
            levelIndention: 15,
            numberingWidth: 15,
            //style: boldStyle,
          }),
          AD.NumberingLevelDefinition.create({
            level: 1,
            format: "Decimal",
            start: 1,
            levelText: "%1.",
            levelIndention: 30,
            numberingWidth: 15,
            //style: boldStyle,
          }),
          AD.NumberingLevelDefinition.create({
            level: 2,
            format: "Decimal",
            start: 1,
            levelText: "%1.",
            levelIndention: 45,
            numberingWidth: 15,
            //style: boldStyle,
          }),
        ],
      }),
    ],
    [
      "Unordered",
      AD.NumberingDefinition.create({
        levels: [
          AD.NumberingLevelDefinition.create({
            level: 0,
            format: "Decimal",
            start: 1,
            levelText: "•",
            levelIndention: 6,
            numberingWidth: 6,
            //style: boldStyle,
          }),
          AD.NumberingLevelDefinition.create({
            level: 1,
            format: "Decimal",
            start: 1,
            levelText: "•",
            levelIndention: 12,
            numberingWidth: 6,
            //style: boldStyle,
          }),
          AD.NumberingLevelDefinition.create({
            level: 2,
            format: "Decimal",
            start: 1,
            levelText: "•",
            levelIndention: 18,
            numberingWidth: 6,
            //style: boldStyle,
          }),
        ],
      }),
    ],
  ];
  return createNumberDefs(numberDefs);
}

function createNumberDefs(
  // eslint-disable-next-line functional/prefer-readonly-type
  tuples: Array<[string, AD.NumberingDefinition.NumberingDefinition]>
): AD.Types.Indexer<AD.NumberingDefinition.NumberingDefinition> {
  return R.fromPairs(tuples);
}
