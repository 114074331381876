import { PropertyValueSet } from "@promaster-sdk/property";
import { Calculate, CalculatorFrtCoil, Search } from "../..";
import { QueryGenerator, graphQLProductQuery } from "../../query";
import { ActiveUser, isInternalUser } from "../../user";
import { getPageProps, imageToBitmapImage } from "../common";
import { ReportQueryParams } from "../types";
import { AdapterType, Response } from "./types";
import * as GQLOps from "../../generated/generated-operations";
import { createSearchData } from "../../search";
import { imageQueryByProductId } from "../common/queries";
import { fetchImageData } from "../../images";

export function* query({
  clientConfig,
  productByKey,
  productKey,
  imageServiceUrl,
}: ReportQueryParams): QueryGenerator<Response> {
  const searchQueryData = yield* graphQLProductQuery<GQLOps.SearchSingleQuery, GQLOps.SearchSingleQueryVariables>(
    Search.searchProductQuerySingle,
    {
      searchProductId: clientConfig.promaster_search_product_id,
      productId: productByKey[productKey].id,
    }
  );

  const fritermCoilData = yield* graphQLProductQuery<GQLOps.FrtCoilQuery, GQLOps.FrtCoilQueryVariables>(
    CalculatorFrtCoil.query,
    { searchProductId: clientConfig.promaster_search_product_id }
  );

  const productImages = yield* imageQueryByProductId(productByKey[productKey].id);
  const productImage = productImages?.modules.images.image.find((i) => i.name === "product_image");

  const imageData = productImage
    ? yield* fetchImageData([productImage], imageServiceUrl, 300, 300, 1, "jpeg")
    : undefined;

  return { searchData: searchQueryData, fritermCoilData, productImage: imageData && imageData[0] };
}

export const execute: AdapterType = async (reportParams, common, response) => {
  const { searchData, fritermCoilData } = response;
  const { user, productKey, properties } = reportParams;
  const pageProps = await getPageProps(reportParams, common);
  const productImage = response.productImage && (await imageToBitmapImage(500, 500, response.productImage));
  const query = createSearchQuery(user, createSearchData(searchData), properties, productKey);
  const calcData = CalculatorFrtCoil.mapQuery(fritermCoilData);
  const searchResult = Search.search(
    createSearchData(searchData),
    createSearchQuery(user, createSearchData(searchData), properties, productKey)
  );
  const match = searchResult?.matches?.[0];

  const calculationResult = await Calculate.calculateProduct(
    calcData,
    properties,
    match.selection,
    match.productVariantRow,
    query,
    reportParams.waterAccessories
  );

  const internalUser = isInternalUser(user.claims);

  if (
    !calculationResult ||
    calculationResult.type === "Err" ||
    searchData.searchProduct === null ||
    searchData.product === null
  ) {
    throw new Error("Failed to calculate");
  }

  return {
    pageProps,
    calculationResult: calculationResult.value,
    variant: properties,
    model: match.model,
    productById: reportParams.productById,
    productImage,
    configurationLink: reportParams.configurationLink,
    searchProductKey: searchData.searchProduct.key,
    productKey: searchData.product.key,
    internalUser,
    accessories: reportParams.waterAccessories,
  };
};

function createSearchQuery(
  activeUser: ActiveUser,
  searchData: Search.SearchData,
  variant: PropertyValueSet.PropertyValueSet,
  productKey: string
): Search.Query {
  return {
    company: activeUser.companyName,
    filter: Search.propertiesToSearchFilter(searchData, variant),
    productKey: productKey,
    userCurrency: activeUser.claims.currency,
  };
}
