import { ParseUrl, createParseUrl, LocationFromParams } from "./parse-url";
import { BuildUrl, createBuildUrl, ParamsFromLocation } from "./build-url";

export interface Route<TLocation> {
  readonly parseUrl: ParseUrl<TLocation>;
  readonly buildUrl: BuildUrl<TLocation>;
}

export const NoParams = (): {} => ({});

export function createRoute<TLocation>(
  path: string,
  locationFromParams: LocationFromParams<TLocation>,
  paramsFromLocation: ParamsFromLocation<TLocation>,
  encode: (value: string) => string = encodeURIComponent,
  validate: boolean = true
): Route<TLocation> {
  return {
    parseUrl: createParseUrl<TLocation>(path, locationFromParams),
    buildUrl: createBuildUrl(path, paramsFromLocation, encode, validate),
  };
}

export function combineUrls(...urls: readonly string[]): string {
  if (urls.length === 0) {
    return "";
  }

  // If the first url is full url we need to handle that first.
  const isFullUrl = /^(https?:\/\/)/;
  const match = isFullUrl.exec(urls[0]);
  let fullUrl = match ? match[1] : "/";
  for (const [index, u1] of urls.entries()) {
    const u = index === 0 && match ? u1.replace(match[1], "") : u1;

    // If string has en leading or an trailing slash it will add empty strings
    const parts = u.split("/").filter((s) => s !== "");
    for (const part of parts) {
      if (part === "..") {
        const lastPart = fullUrl.lastIndexOf("/");
        fullUrl = fullUrl.substring(0, lastPart);
      } else {
        fullUrl = `${fullUrl.replace(/\/$/, "")}/${part}`;
      }
    }
  }
  return fullUrl;
}
