export interface AppInfo {
  readonly commitHash: string;
  readonly branch: string;
  readonly pipelineId: string;
}
// Gets stamped by CI
export const appInfo: AppInfo = {
  branch: "16.1.1",
  pipelineId: "123646",
  commitHash: "COMMIT_REF_SHORT",
};
