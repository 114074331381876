import { PropertyValueSet } from "@promaster-sdk/property";
import * as Q from "./query";
import { SearchSingleQuery } from "../generated/generated-operations";

export const searchColumns = [
  "pm_product_type",
  "pm_cooling",
  "pm_heating",
  "pm_medium",
  "pm_connection",
  "pm_model",
  "pm_size",
  "pm_capacity",
  "pm_speed",
  "frt_dll_number_of_rows",
  "fixed_airflow",
  "pm_controller_type",
] as const;

// export const ignoredColumns = ["price_sek", "price_eur"];

export type SearchColumn = keyof Pick<Q.ProductVariantsRow, (typeof searchColumns)[number]>;

export function isSearchColumn(columnName: string | undefined | null): columnName is SearchColumn {
  if (!columnName) {
    return false;
  }
  return (searchColumns as ReadonlyArray<string>).includes(columnName);
}

export type Query = {
  readonly company: string | undefined;
  readonly filter: Filter;
  readonly productKey: string | undefined;
  readonly userCurrency: string;
};

export type Filter = {
  readonly [column: string]: string;
};

export type FilterSelection = { readonly [key in SearchColumn]: string };

export type Selection = {
  readonly productKey: string;
  readonly productId: string;
} & FilterSelection;

export type Match = {
  readonly model: string;
  readonly selectionKey: string;
  readonly selection: Selection;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly productVariantRow: Q.ProductVariantsRow;
};

export type AttributeValue = {
  readonly value: string;
};

export type Attribute = {
  readonly name: string;
  readonly values: ReadonlyArray<AttributeValue>;
};

export type Result = {
  readonly query: Query;
  readonly matches: ReadonlyArray<Match>;
  readonly attributes: ReadonlyArray<Attribute>;
};

export type SelectionToProperties = {
  readonly selection: Partial<Selection>;
  readonly properties: PropertyValueSet.PropertyValueSet;
};

export type SearchData = {
  readonly searchProduct: SearchSingleQuery | undefined;
  readonly searchProducts: Q.SearchProductsTable;
  readonly searchColumnProperties: Q.SearchColumnPropertiesTable;
  readonly searchInput: Q.SearchInputTable;
  readonly searchCalculationLimits: Q.SearchCalculationLimitsTable;
  readonly searchCalculationMargin: Q.SearchCalculationMarginTable;
  readonly products: Q.Products;
  readonly propertyToSearchValue: ReadonlyMap<string, string>;
  readonly searchToPropertyValue: ReadonlyMap<string, number>;
  readonly selectionToProperties: ReadonlyArray<SelectionToProperties>;
  readonly searchResultColumns: Q.SearchResultColumnTable;
};
