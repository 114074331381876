import { Converter, extension } from "showdown";
import RemoveMarkdown from "remove-markdown";
import { supsub, excludeList } from "./extension";
import { TableCell, CellTextAlignment } from "./types";

const threeDots = new RegExp(/\.{3}/);
extension("subsup", supsub);
extension("excludeList", excludeList);

const extensionRegExs = supsub().map((ext) => ext.regex);

const mdImageRe = /!\[(.*)\]\((.*)\)/g;

export function makeHtml(markdown: string, replaceImageUrl?: (url: string) => string): string {
  const withoutThreeDots = markdown.replace(threeDots, `\\...`);
  const withoutExcessiveSpaces = withoutThreeDots.replace(/  +/g, " ");
  const converter = new Converter({
    extensions: ["subsup", "excludeList"],
    noHeaderId: true,
    parseImgDimensions: true,
  });
  if (replaceImageUrl) {
    converter.addExtension(
      {
        type: "lang",
        filter: (text: string) =>
          text.replace(mdImageRe, (_match: string, desc: string, url: string) => `![${desc}](${replaceImageUrl(url)})`),
      },
      "replaceUrl"
    );
  }
  return converter.makeHtml(withoutExcessiveSpaces);
}

export function makePlainText(markdown: string): string {
  const withoutExcessiveSpaces = markdown.replace(/  +/g, " ");
  return RemoveMarkdown(withoutExcessiveSpaces, { stripListLeaders: false });
}

export function makePlainTextExt(markdown: string): string {
  const withoutExcessiveSpaces = markdown.replace(/  +/g, " ");
  const text = RemoveMarkdown(withoutExcessiveSpaces, { stripListLeaders: false }) as string;
  return extensionRegExs.reduce((sofar: string, regex) => {
    if (typeof regex !== "string" && regex !== undefined) {
      return sofar.replace(regex, "$1");
    } else {
      return sofar;
    }
  }, text) as string;
}

/*
  Example:

  | :- | :-: | -: |
  | ## Text Alignment |||\
  | |||\
  | Left | Center | Right |\
  | **Text with markdown syntax** |||\
*/
export function parseTable(table: string): ReadonlyArray<ReadonlyArray<TableCell>> {
  const lines = tokenizeMarkdownTable(table);

  // Column alignment
  const colAlign: Array<CellTextAlignment> = lines[0].map((c) => {
    if (c.startsWith(":") && c.endsWith(":")) {
      return "Center";
    } else if (c.endsWith(":")) {
      return "End";
    } else {
      return "Start";
    }
  });

  const bodyLines = lines.slice(1);
  const body: Array<Array<TableCell>> = [];
  let rowNo = 0;
  for (const line of bodyLines) {
    if (line.length <= 2) {
      continue;
    }

    const row: Array<TableCell> = [];
    for (let partIdx = 0; partIdx < line.length; partIdx++) {
      const text = line[partIdx];
      if (text === "" || text === "\\") {
        continue;
      }
      let colSpan = 1;
      for (let i = partIdx + 1; i < line.length - 1; i++) {
        if (line[i] === "") {
          colSpan++;
        } else {
          break;
        }
      }
      row.push({ text, colSpan, align: colAlign[partIdx] || "Start", rowNo });
    }
    if (row.length === 0) {
      body.push([{ text: "", colSpan: colAlign.length - 2, align: "Start", rowNo }]);
    }
    body.push(row);

    if (line[line.length - 1] !== "\\") {
      rowNo++;
    }
  }
  return body;
}

export function getNumTableColumns(table: string): number {
  const lines = tokenizeMarkdownTable(table);
  if (lines.length === 0) {
    return 0;
  }
  return Math.max(0, lines[0].length - 2);
}

function tokenizeMarkdownTable(tableText: string): ReadonlyArray<ReadonlyArray<string>> {
  return tableText.split("\n").map((innerString) => {
    const trimmedInnerString = innerString.trim();
    const replacedString = trimmedInnerString.replace(/(\\\|)/g, "#break#");
    const splitedString = replacedString.split("|");
    return splitedString.map((c) => c.trim().replace(/\\n/g, "\n")).map((c) => c.replace(/#break#/g, "\\|"));
  });
}
