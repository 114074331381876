import { PropertyValueSet } from "@promaster-sdk/property";
import { ReportModule } from "../types";
import * as Common from "../common";
import * as GQLOps from "../../generated/generated-operations";
import { CoilResult } from "../../calculator-frt-coil";
import { ImageData } from "../../images";

export const Name = "technical-data-sheet";
export type Response = {
  readonly searchData: GQLOps.SearchSingleQuery;
  readonly fritermCoilData: GQLOps.FrtCoilQuery;
  readonly dimensionsimage: ImageData | undefined;
};

type Product = GQLOps.AllProductsQuery["products"][number];

export type CreatorProps = {
  readonly pageProps: Common.CataloguePageProps;
  readonly calculationResult: CoilResult;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly model: string;
  readonly productById: Record<string, Product>;
  readonly dimensionsImage: Common.BitmapImage | undefined;
};

export type ReportType = ReportModule<CreatorProps, Response>;
export type AdapterType = ReportType["execute"];
export type CreatorType = ReportType["create"];
