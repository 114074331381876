import { PropertyValueSet } from "@promaster-sdk/property";
import { Calculate, CalculatorFrtCoil, MagicloudApi, Search } from "../..";
import { QueryGenerator, graphQLProductQuery, httpBlobQuery, promiseQuery } from "../../query";
import { ActiveUser } from "../../user";
import { getPageProps, imageToBitmapImage } from "../common";
import { ReportQueryParams } from "../types";
import { AdapterType, Response } from "./types";
import * as GQLOps from "../../generated/generated-operations";
import { createSearchData } from "../../search";
import { createProxiedMagicloudUrl } from "../../magicloud-api";

export function* query({
  clientConfig,
  productByKey,
  productKey,
  user,
  properties,
}: ReportQueryParams): QueryGenerator<Response> {
  const searchQueryData = yield* graphQLProductQuery<GQLOps.SearchSingleQuery, GQLOps.SearchSingleQueryVariables>(
    Search.searchProductQuerySingle,
    {
      searchProductId: clientConfig.promaster_search_product_id,
      productId: productByKey[productKey].id,
    }
  );

  const fritermCoilData = yield* graphQLProductQuery<GQLOps.FrtCoilQuery, GQLOps.FrtCoilQueryVariables>(
    CalculatorFrtCoil.query,
    { searchProductId: clientConfig.promaster_search_product_id }
  );

  const searchData = createSearchData(searchQueryData);
  const searchResult = Search.search(searchData, createSearchQuery(user, searchData, properties, productKey));

  const rows = Search.filterRows(
    searchResult?.matches[0].selection,
    searchQueryData.product?.modules.custom_tables.product_variants || []
  );

  const magicloudId = rows[0].magicloud_id;
  const model =
    magicloudId === null ? undefined : yield* promiseQuery(MagicloudApi.getX3DModelByGuid(magicloudId, undefined));

  const proxiedDimensionsUrl = model?.dimensionsUrl && createProxiedMagicloudUrl(model.dimensionsUrl);

  const imageData = typeof proxiedDimensionsUrl !== "string" ? undefined : yield* httpBlobQuery(proxiedDimensionsUrl);

  const dimensionsimage = imageData && {
    image: { image: "dimension_image", file_name: null },
    imageData,
  };

  return { searchData: searchQueryData, fritermCoilData, dimensionsimage };
}

export const execute: AdapterType = async (reportParams, common, response) => {
  const { searchData, fritermCoilData } = response;
  const { user, productKey, properties } = reportParams;
  const pageProps = await getPageProps(reportParams, common);
  const dimensionsImage = response.dimensionsimage && (await imageToBitmapImage(500, 500, response.dimensionsimage));
  const calcData = CalculatorFrtCoil.mapQuery(fritermCoilData);
  const query = createSearchQuery(user, createSearchData(searchData), properties, productKey);
  const searchResult = Search.search(createSearchData(searchData), query);
  const match = searchResult?.matches?.[0];

  const calculationResult = await Calculate.calculateProduct(
    calcData,
    properties,
    match.selection,
    match.productVariantRow,
    query,
    reportParams.waterAccessories
  );

  if (!calculationResult || calculationResult.type === "Err") {
    throw new Error("Failed to calculate");
  }

  return {
    pageProps,
    calculationResult: calculationResult.value,
    variant: properties,
    model: match.model,
    productById: reportParams.productById,
    dimensionsImage,
  };
};

function createSearchQuery(
  activeUser: ActiveUser,
  searchData: Search.SearchData,
  variant: PropertyValueSet.PropertyValueSet,
  productKey: string
): Search.Query {
  return {
    company: activeUser.companyName,
    filter: Search.propertiesToSearchFilter(searchData, variant),
    productKey: productKey,
    userCurrency: activeUser.claims.currency,
  };
}
