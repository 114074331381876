import { exhaustiveCheck } from "ts-exhaustive-check";
import { Dispatch, EffectManager } from "@typescript-tea/core";
import { HttpFetchCmd, HttpResponseType, mapCmd } from "./http-fetch-cmd";
import { home } from "./home";
import { HttpResponse } from ".";

export type HttpState = "idle" | "waiting";

export function createEffectManager<ActionApp>(onHttpStateChanged: (httpState: HttpState) => ActionApp): EffectManager {
  return {
    home,
    mapCmd,
    mapSub: (_, _sub) => {
      throw new Error("This effect manager has no subs.");
    },
    setup: () => () => undefined,
    onEffects: (
      dispatchApp: Dispatch<ActionApp>,
      dispatchSelf: Dispatch<never>,
      cmds: readonly HttpFetchCmd<ActionApp>[],
      subs: readonly never[],
      state: State = init()
    ) => onEffects(dispatchApp, dispatchSelf, cmds, subs, state || init(), onHttpStateChanged),
    onSelfAction: () => ({}),
  };
}

// -- STATE

interface State {}

function init(): readonly [State] {
  return [{}];
}

// -- COMMANDS

// -- SUBSCRIPTIONS

// -- MANAGER

// eslint-disable-next-line functional/no-let
let pendingRequests = 0;

function onEffects<ActionApp>(
  dispatchApp: Dispatch<ActionApp>,
  _dispatchSelf: Dispatch<never>,
  cmds: readonly HttpFetchCmd<ActionApp>[],
  _subs: readonly never[],
  state: State,
  onHttpStateChanged: (httpState: HttpState) => ActionApp
): State {
  const decPendingRequets = (): void => {
    pendingRequests = Math.max(0, pendingRequests - 1);
    if (pendingRequests === 0) {
      dispatchApp(onHttpStateChanged("idle"));
    }
  };
  const incPendingRequets = (): void => {
    pendingRequests++;
    if (pendingRequests === 1) {
      dispatchApp(onHttpStateChanged("waiting"));
    }
  };

  for (const cmd of cmds) {
    switch (cmd.type) {
      case "FetchOne": {
        incPendingRequets();
        httpFetch(cmd.headers, cmd.url, cmd.responseType)
          .then((data) => {
            if (data !== undefined) {
              cmd.onSuccess && dispatchApp(cmd.onSuccess(data));
            } else if (cmd.onError) {
              dispatchApp(cmd.onError());
            } else {
              throw new Error("Request failed");
            }
          })
          .finally(decPendingRequets);
        break;
      }
      case "FetchMultiple": {
        incPendingRequets();
        Promise.all(cmd.urls.map((url) => httpFetch(cmd.headers, url, cmd.responseType)))
          .then((responsesData) => {
            if (responsesData.every((r) => !!r)) {
              cmd.onSuccess && dispatchApp(cmd.onSuccess(responsesData as ReadonlyArray<HttpResponse>));
            } else if (cmd.onError) {
              dispatchApp(cmd.onError());
            } else {
              throw new Error("Request failed");
            }
          })
          .finally(decPendingRequets);
        break;
      }
      case "Post": {
        incPendingRequets();
        fetch(cmd.url, {
          method: "POST",
          headers: { "Content-Type": cmd.contentType, ...cmd.headers },
          body: cmd.body,
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              return undefined;
            }
          })
          .then((result) => {
            if (result) {
              cmd.onSuccess && dispatchApp(cmd.onSuccess(result));
            } else if (cmd.onError) {
              dispatchApp(cmd.onError());
            } else {
              throw new Error("Request failed");
            }
          })
          .finally(decPendingRequets);
        break;
      }
      default: {
        exhaustiveCheck(cmd, true);
      }
    }
  }
  return [state];
}

async function httpFetch(
  headers: { readonly [header: string]: string },
  url: string,
  responseType: HttpResponseType
): Promise<HttpResponse | undefined> {
  const response = await fetch(url, {
    headers: headers,
  });
  if (!response.ok) {
    return undefined;
  }
  switch (responseType) {
    case "json": {
      return { response: await response.json(), headers: response.headers };
    }
    case "text": {
      return { response: await response.text(), headers: response.headers };
    }
    case "blob": {
      return { response: new Uint8Array(await response.arrayBuffer()), headers: response.headers };
    }
    default: {
      return exhaustiveCheck(responseType, true);
    }
  }
}
