import { Cmd, Result } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { productQuery as ProductQuery } from "@ehb/shared/src/graphql-schema/product";
import { PropertyValueSet as PVS, PropertyValue as PV } from "@promaster-sdk/property";
import { Calculator, FP, Reports, Query, SelectableFormat } from "@ehb/shared";
import {
  CircuitDiagramTableRows,
  DocumentTableRows,
  getFilteredCircuitDiagramTable,
  getFilteredDocumentsTable,
  getFilteredImagesTable,
  getFilteredPriceTables,
  ImageTableRows,
} from "@ehb/shared/src/calculator/get-tables";
import {
  CalculateRequest,
  CalculateResult,
  Currency,
  CustomItem,
  CustomItems,
  ErrorResult,
} from "@ehb/shared/src/calculator/types";
import { texts } from "@ehb/shared/src/lang-texts";
import {
  decodeCustomItems,
  decodePropertyValueSet,
  encodeVariantPropertyValueSet,
  reverseLookUpVariant,
} from "@ehb/shared/src/product-utils";
import {
  NavigationEffectManager as Navigation,
  SharedState,
  graphQLMutationWithAuth,
  HttpFetch,
  graphQLQueryWithAuth,
  Routes,
  PromiseEffectManager,
} from "@ehb/client-infra";
import { updateUserClaimProperties } from "@ehb/shared/src/user";
import { v4 as uuid } from "uuid";
import { createCustomItem, createErrorResult } from "@ehb/shared/src/calculator/util";
import { PriceAccessories, getPriceAccessories } from "@ehb/shared/src/calculator/calc-price";
import { SharedStateAction } from "@ehb/client-infra/src/shared-state";
import { MainLocation, createQueryString } from "@ehb/client-infra/src/routes";
import * as GQLOps from "../../generated/generated-operations";
import {
  generateDefaultProperties,
  generateDefaultValueForProperty,
  updateAmountFormat,
} from "../../elements/properties-selector/property-selector-def";
import { clientConfig } from "../../client-config";
import { createQuoteRequestMutation } from "./mutation";

export const Printing = ctorsUnion({
  Idle: () => ({}),
  Fetching: () => ({}),
  Loading: (response: Reports.ReportQueryResponse, data: CalculateResult) => ({ response, data }),
});

export type Printing = CtorsUnion<typeof Printing>;

export type State = {
  readonly electricalHeaterProduct: GQLOps.ProductQuery | undefined;
  readonly circuitDiagrams: ReadonlyArray<CircuitDiagramTableRows>;
  readonly documents: ReadonlyArray<DocumentTableRows>;
  readonly images: ReadonlyArray<ImageTableRows>;
  readonly selectedProperties: PVS.PropertyValueSet;
  readonly calculateResult: Result<ErrorResult, Calculator.CalculateResult> | undefined;
  readonly isValidselectedProperties: boolean;
  readonly printing: Printing;
  readonly existingCode: string;
  readonly errorExistingCode: string;
  readonly manualDepth: string;
  readonly requestQuoteStatus: "not_sent" | "sending" | "sent_ok" | "sent_error";
  readonly customItems: ReadonlyArray<CustomItem>;
  readonly reportQueryRunner: Reports.QueryRunner | undefined;
};

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState,
  locationParams: { readonly query: { readonly [query: string]: string } }
): readonly [State, Cmd<Action>?] {
  if (prevState) {
    return [prevState];
  }
  const url = new URL(window.location.href);
  const queryParams: { [key: string]: string } = {};
  url.searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  const initialState: State = {
    electricalHeaterProduct: undefined,
    selectedProperties: decodePropertyValueSet(locationParams.query, sharedState.activeUser),
    calculateResult: undefined,
    circuitDiagrams: [],
    documents: [],
    images: [],
    isValidselectedProperties: false,
    printing: Printing.Idle(),
    existingCode: "",
    errorExistingCode: "",
    manualDepth: "",
    requestQuoteStatus: "not_sent",
    customItems: decodeCustomItems(queryParams),
    reportQueryRunner: undefined,
  };

  const gqlCmdProduct = sharedState.graphQLProductQuery<GQLOps.ProductQuery, GQLOps.ProductQueryVariables, Action>(
    ProductQuery,
    { productId: clientConfig.promaster_eh_id, language: sharedState.selectedLanguage },
    (data) => {
      return Action.ProductRecieved(data);
    }
  );
  return [initialState, gqlCmdProduct];
}

export const Action = ctorsUnion({
  ProductRecieved: (data: GQLOps.ProductQuery) => ({ data }),
  SetSelectedPropertiesAndCalculate: (
    selectedProperties: PVS.PropertyValueSet,
    changedProperty: ReadonlyArray<string>
  ) => ({
    selectedProperties,
    changedProperty,
  }),
  SetSelectedFormat: (propertyName: string, selectedFormat: SelectableFormat) => ({ propertyName, selectedFormat }),
  SetFieldUnit: (fieldName: string, unit: string, decimalCount: number) => ({
    fieldName,
    unit,
    decimalCount,
  }),
  ClearFieldUnit: (fieldName: string) => ({ fieldName }),
  QuoteFinishedLoading: () => ({}),
  GenerateQuote: () => ({}),
  OnExsistingCodeValueChange: (value: string) => ({ value }),
  LookUpVariant: () => ({}),
  OnExistingDepthValueChange: (value: string) => ({ value }),
  SetManualDepth: () => ({}),
  ClearManualDepth: () => ({}),
  RequestQuote: () => ({}),
  RequestQuoteResponseReceived: (mutation: GQLOps.CreateQuoteRequestMutation) => ({ mutation }),
  RequestQuoteFailed: () => ({}),
  AddCustomItem: () => ({}),
  RemoveCustomItem: (id: string) => ({ id }),
  UpdateCustomItem: (item: CustomItem) => ({ item }),
  ReportDataReceived: (data: unknown) => ({ data }),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "ProductRecieved": {
      const productQuery = action.data;
      const productProperties = action.data.product?.modules.properties.property ?? [];

      const defaultProperties = generateDefaultProperties(productProperties);

      const selectedPropertiesFromUrl = PVS.setValues(state.selectedProperties, defaultProperties);

      const mapUserClaimsToProperties = updateUserClaimProperties(
        sharedState.activeUser.claims,
        selectedPropertiesFromUrl
      );

      const manualDepth = PVS.getInteger("manualdepth", mapUserClaimsToProperties);
      const newState =
        Navigation.getCurrentUrl().query === undefined
          ? {
              ...state,
              selectedProperties: mapUserClaimsToProperties,
              electricalHeaterProduct: productQuery,
              calculateResult: undefined,
            }
          : calculate(
              mapUserClaimsToProperties,
              productQuery,
              {
                ...state,
                manualDepth: manualDepth?.toString() ?? "",
              },
              sharedState
            );
      return [newState];
    }
    case "SetSelectedPropertiesAndCalculate": {
      const newProperties = generateDefaultValueForProperty(
        state.electricalHeaterProduct?.product?.modules.properties.property ?? [],
        action.selectedProperties,
        action.changedProperty.includes("model") ? "cableinlet" : ""
      );
      const allProps = PVS.merge(newProperties, action.selectedProperties);
      const selectedcCurrency: Currency = PV.getInteger(PVS.getValue("currency", action.selectedProperties)) || 1;
      const updatedCustomItems = state.customItems.map((item) => ({ ...item, currency: Currency[selectedcCurrency] }));

      const variantQuery = encodeVariantPropertyValueSet(allProps, updatedCustomItems, sharedState.activeUser);
      return [
        calculate(
          allProps,
          state.electricalHeaterProduct!,
          {
            ...state,
            customItems: updatedCustomItems,
            requestQuoteStatus: "not_sent",
          },
          sharedState
        ),
        Navigation.replaceUrl<Action>(Routes.buildMainUrl(MainLocation.ProductSelect(variantQuery))),
      ];
    }
    case "SetSelectedFormat": {
      const newProps = updateAmountFormat(
        state.electricalHeaterProduct?.product?.modules.properties.property ?? [],
        state.selectedProperties,
        action.propertyName,
        action.selectedFormat
      );
      const variantQuery = encodeVariantPropertyValueSet(newProps, state.customItems, sharedState.activeUser);
      return [
        {
          ...state,
          selectedProperties: newProps,
        },
        Navigation.replaceUrl<Action>(Routes.buildMainUrl(MainLocation.ProductSelect(variantQuery))),
        SharedStateAction.SetSelectedFormat(action.propertyName, action.selectedFormat),
      ];
    }
    case "ClearFieldUnit": {
      return [state, undefined, SharedStateAction.ClearFieldUnit(action.fieldName)];
    }
    case "SetFieldUnit": {
      return [state, undefined, SharedStateAction.SetFieldUnit(action.fieldName, action.unit, action.decimalCount)];
    }
    case "GenerateQuote": {
      if (!state.calculateResult || state.calculateResult?.type === "Err") {
        return [{ ...state, printing: Printing.Idle() }];
      }
      const calculateRequest = Calculator.fromVariantToCalculateRequest(state.selectedProperties);
      if (!calculateRequest || calculateRequest.type === "Err") {
        throw Error(calculateRequest?.error);
      }
      const priceTables = getFilteredPriceTables(state.electricalHeaterProduct, state.selectedProperties);
      if (!priceTables || priceTables.type === "Err") {
        throw Error(priceTables?.error);
      }
      const reportParams = createReportParams(
        sharedState,
        state,
        state.calculateResult.value,
        calculateRequest.value,
        getPriceAccessories(
          calculateRequest.value.currency,
          priceTables.value.priceAccessories,
          calculateRequest.value.discount
        ),
        state.customItems
      );
      const queryRunner = Reports.runReportQuries("https://public-api.promaster.systemair.com/image", reportParams);
      const newState = {
        ...state,
        reportQueryRunner: queryRunner,
      };
      return handleReportQueryAction(action, newState, sharedState);
    }
    case "QuoteFinishedLoading": {
      return [{ ...state, printing: Printing.Idle() }];
    }
    case "OnExsistingCodeValueChange": {
      return [{ ...state, existingCode: action.value, errorExistingCode: "" }];
    }
    case "LookUpVariant": {
      const variantFromCode = reverseLookUpVariant(state.existingCode);

      if (variantFromCode.type === "Err") {
        return [{ ...state, errorExistingCode: variantFromCode.error }];
      }

      const defaultProperties = generateDefaultProperties(
        state.electricalHeaterProduct?.product?.modules.properties.property ?? []
      );
      const mappedVariantFromCode = PVS.setValues(variantFromCode.value, defaultProperties);
      const mapUserClaimsToProperties = updateUserClaimProperties(sharedState.activeUser.claims, mappedVariantFromCode);
      const variantQuery = encodeVariantPropertyValueSet(
        mapUserClaimsToProperties,
        state.customItems,
        sharedState.activeUser
      );
      const selectedcCurrency: Currency = PV.getInteger(PVS.getValue("currency", mapUserClaimsToProperties)) || 1;
      const updatedCustomItems = state.customItems.map((item) => ({ ...item, currency: Currency[selectedcCurrency] }));
      return [
        calculate(
          mapUserClaimsToProperties,
          state.electricalHeaterProduct!,
          {
            ...state,
            customItems: updatedCustomItems,
            requestQuoteStatus: "not_sent",
          },
          sharedState
        ),
        Navigation.replaceUrl<Action>(Routes.buildMainUrl(MainLocation.ProductSelect(variantQuery))),
      ];
    }
    case "OnExistingDepthValueChange": {
      return [{ ...state, manualDepth: action.value, errorExistingCode: "" }];
    }

    case "SetManualDepth": {
      const selectedProperties = state.selectedProperties;
      const manualDepthMm = Number(state.manualDepth);
      if (Number.isNaN(manualDepthMm) || manualDepthMm < 0) {
        return [state];
      }
      // const depthMm = PV.create("integer", Amount.create(manualDepthMm, EhbUnits.Millimeter));

      const newProperties = PVS.setInteger("manualdepth", manualDepthMm, selectedProperties);

      const variantQuery = encodeVariantPropertyValueSet(newProperties, state.customItems, sharedState.activeUser);
      return [
        calculate(newProperties, state.electricalHeaterProduct, state, sharedState),
        Navigation.replaceUrl<Action>(Routes.buildMainUrl(MainLocation.ProductSelect(variantQuery))),
      ];
    }

    case "ClearManualDepth": {
      const selectedProperties = state.selectedProperties;

      const newProperties = PVS.setInteger("manualdepth", 0, selectedProperties);

      const variantQuery = encodeVariantPropertyValueSet(newProperties, state.customItems, sharedState.activeUser);
      return [
        calculate(newProperties, state.electricalHeaterProduct, { ...state, manualDepth: "" }, sharedState),
        Navigation.replaceUrl<Action>(Routes.buildMainUrl(MainLocation.ProductSelect(variantQuery))),
      ];
    }
    case "RequestQuote": {
      return RequestQuote(action, state, sharedState);
    }
    case "RequestQuoteResponseReceived": {
      const requestSent = action.mutation.createQuoteRequest.quoteRequestSent;
      return [{ ...state, requestQuoteStatus: requestSent ? "sent_ok" : "sent_error" }];
    }
    case "RequestQuoteFailed": {
      return [{ ...state, requestQuoteStatus: "sent_error" }];
    }
    case "AddCustomItem": {
      const currency: Currency = PV.getInteger(PVS.getValue("currency", state.selectedProperties)) || 1;
      const customItem: CustomItem = {
        id: uuid(),
        sortNo: state.customItems.length > 0 ? Math.max(...state.customItems.map((m) => m.sortNo)) + 1 : 1,
        name: "",
        quantity: 1,
        singlePrice: 0,
        totalPrice: 0,
        currency: (currency && Currency[currency]) ?? sharedState.activeUser.claims.currency,
      };

      return [{ ...state, customItems: [...state.customItems, customItem] }];
    }
    case "RemoveCustomItem": {
      const updatedItems = state.customItems.filter((item) => item.id !== action.id);
      const refreshedCustomItems = updatedItems.map((item, index) => ({
        ...item,
        sortNo: index + 1,
      }));

      const variantQuery = encodeVariantPropertyValueSet(
        state.selectedProperties,
        refreshedCustomItems,
        sharedState.activeUser
      );
      return [
        calculate(
          state.selectedProperties,
          state.electricalHeaterProduct,
          {
            ...state,
            customItems: refreshedCustomItems,
          },
          sharedState
        ),
        Navigation.replaceUrl<Action>(Routes.buildMainUrl(MainLocation.ProductSelect(variantQuery))),
      ];
    }
    case "UpdateCustomItem": {
      const updatedCustomItems = state.customItems.map((item) =>
        item.id === action.item.id ? createCustomItem(action.item) : item
      );
      const variantQuery = encodeVariantPropertyValueSet(
        state.selectedProperties,
        updatedCustomItems,
        sharedState.activeUser
      );
      return [
        calculate(
          state.selectedProperties,
          state.electricalHeaterProduct,
          {
            ...state,
            customItems: updatedCustomItems,
          },
          sharedState
        ),
        Navigation.replaceUrl<Action>(Routes.buildMainUrl(MainLocation.ProductSelect(variantQuery))),
      ];
    }
    case "ReportDataReceived": {
      if (!state.reportQueryRunner) {
        return [state];
      }
      return handleReportQueryAction(action, state, sharedState);
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function handleReportQueryAction(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?] {
  if (!state.reportQueryRunner || (action.type !== "GenerateQuote" && action.type !== "ReportDataReceived")) {
    return [state];
  }
  const result =
    action.type === "GenerateQuote" ? state.reportQueryRunner.next() : state.reportQueryRunner.next(action.data);
  if (!state.calculateResult || state.calculateResult?.type === "Err") {
    return [state];
  }
  if (result.done) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    return [
      {
        ...state,
        printing: Printing.Loading(result.value, state.calculateResult.value),
        reportQueryRunner: undefined,
      },
    ];
  } else {
    return [{ ...state, printing: Printing.Fetching() }, requestsToCommand(result.value, sharedState)];
  }
}

function calculate(
  selectedProperties: PVS.PropertyValueSet,
  product: GQLOps.ProductQuery | undefined,
  state: State,
  sharedState: SharedState.SharedState
): State {
  const { translate } = sharedState;
  let calculateRequest: FP.Result<string, CalculateRequest>;
  try {
    calculateRequest = Calculator.fromVariantToCalculateRequest(selectedProperties);
  } catch {
    return {
      ...state,
      selectedProperties: selectedProperties,
      electricalHeaterProduct: product,
      calculateResult: createErrorResult(texts.ERROR_SELECTION_NOT_POSSIBLE("").key, "ERROR"),
      errorExistingCode: "",
    };
  }

  if (calculateRequest.type === "Err") {
    return { ...state, electricalHeaterProduct: product, errorExistingCode: "" };
  }

  const calculateResult = Calculator.calculate(calculateRequest.value, product, translate, state.customItems);

  const diagrams =
    calculateResult.type === "Err" ? FP.Ok([]) : getFilteredCircuitDiagramTable(product, selectedProperties);
  const documents = calculateResult.type === "Err" ? FP.Ok([]) : getFilteredDocumentsTable(product, selectedProperties);
  const images = calculateResult.type === "Err" ? FP.Ok([]) : getFilteredImagesTable(product, selectedProperties);
  return {
    ...state,
    electricalHeaterProduct: product,
    selectedProperties: selectedProperties,
    calculateResult: calculateResult,
    circuitDiagrams: diagrams.type === "Err" ? [] : diagrams.value,
    documents: documents.type === "Err" ? [] : documents.value,
    images: images.type === "Err" ? [] : images.value,
    errorExistingCode: "",
  };
}

function createVariantURL(variantQuery: { readonly [s: string]: string }): string {
  return Navigation.getCurrentUrl().path + createQueryString(variantQuery);
}

function RequestQuote(
  _: ReturnType<typeof Action.RequestQuote>,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  const graphQLMutation = graphQLMutationWithAuth(sharedState.activeUser);
  if (!sharedState.activeUser.claims.responsible_email) {
    return [state];
  }
  const variantQuery = encodeVariantPropertyValueSet(
    state.selectedProperties,
    state.customItems,
    sharedState.activeUser
  );
  return [
    { ...state, requestQuoteStatus: "sending" },
    graphQLMutation<GQLOps.CreateQuoteRequestMutation, GQLOps.CreateQuoteRequestMutationVariables, Action>(
      createQuoteRequestMutation,
      {
        input: {
          configurationLink: createVariantURL(variantQuery),
          language: sharedState.selectedLanguage,
        },
      },
      (data) => Action.RequestQuoteResponseReceived(data),
      () => Action.RequestQuoteFailed()
    ),
  ];
}

function requestsToCommand(query: Query.Query, sharedState: SharedState.SharedState): Cmd<Action> {
  switch (query.type) {
    case "HttpBlobQuery": {
      return HttpFetch.fetchOne({}, query.url, "blob", (data) => {
        return Action.ReportDataReceived(data);
      });
    }
    case "HttpBlobQueryMultiple": {
      return HttpFetch.fetchMultiple({}, query.url, "blob", (data) => {
        return Action.ReportDataReceived(data);
      });
    }
    case "GraphQLProductQuery": {
      return sharedState.graphQLProductQuery<unknown, unknown, Action>(query.query, query.variables, (data) => {
        return Action.ReportDataReceived(data);
      });
    }
    case "GraphQLQuery": {
      return graphQLQueryWithAuth(sharedState.activeUser)<unknown, unknown, Action>(
        query.query,
        query.variables,
        (data) => {
          return Action.ReportDataReceived(data);
        }
      );
    }
    case "PromiseQuery": {
      return PromiseEffectManager.perform<Action, unknown>(
        (data: unknown) => Action.ReportDataReceived(data),
        (async (): Promise<FP.Result<never, unknown>> => ({
          type: "Ok",
          value: await query.promise,
        }))()
      );
    }
    default:
      exhaustiveCheck(query);
      throw new Error("Not implemented");
  }
}

export function createReportParams(
  sharedState: SharedState.SharedState,
  state: State,
  calculateResult: CalculateResult,
  calculateRequest: CalculateRequest,
  priceAccessories: PriceAccessories,
  customItems: CustomItems
): Reports.ReportParams {
  const sharedParams: Omit<Reports.ReportParams, "reportType"> = {
    imageServiceUrl: "https://public-api.promaster.systemair.com/image",
    metaProductId: clientConfig.promaster_meta_id,
    ehProductId: clientConfig.promaster_eh_id,
    translate: sharedState.translate,
    calculateResult: calculateResult,
    calculateRequest: calculateRequest,
    priceAccessories: priceAccessories,
    user: sharedState.activeUser,
    configurationLink: window.location.href,
    customItems: customItems,
    heaterImages: state.images,
    properties: state.selectedProperties,
    productKey: state.electricalHeaterProduct?.product?.key || "",
    clientConfig: clientConfig,
    productByKey: sharedState.productByKey,
    productById: sharedState.productById,
    selectedLanguage: sharedState.selectedLanguage,
    getFieldFormat: sharedState.getFieldFormat,
    waterAccessories: [],
  };

  const reportParams = { ...sharedParams, reportType: "quote-page" } as const;

  return reportParams;
}
