import gql from "graphql-tag";

export const schema = gql`
  extend type Mutation {
    createQuoteRequest(input: CreateQuoteRequestInput!): CreateQuoteRequestPayload!
  }

  input CreateQuoteRequestInput {
    configurationLink: String!
    language: String!
  }

  type CreateQuoteRequestPayload {
    quoteRequestSent: Boolean!
  }
`;
