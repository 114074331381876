import { AbstractDoc as AD } from "abstract-document";
import { TranslateFn } from "../../lang-texts";
import { CommonResponse, ReportParams } from "../types";
import { createHeader } from "./header";
import { createFooter } from "./footer";
import { BitmapImage, createImageResource, imageToBitmapImage } from "./images";
import { GetFieldFormatFn } from "../../units";
import { ClientConfig } from "../../client-config";

export const standardLeftRightMargin: number = 60;
export const catalogueWidth: number = 595;
export const catalogueContentWidth: number = catalogueWidth - standardLeftRightMargin * 2;

export interface CataloguePageProps {
  readonly translate: TranslateFn;
  readonly logo: BitmapImage | undefined;
  readonly reportId: string;
  readonly getFieldFormat: GetFieldFormatFn;
  readonly clientConfig: ClientConfig;
}

export function cataloguePage(props: CataloguePageProps): AD.MasterPage.MasterPage {
  const pageStyle = AD.PageStyle.create({
    headerMargins: AD.LayoutFoundation.create({
      bottom: 0,
      left: standardLeftRightMargin,
      right: standardLeftRightMargin,
    }),
    contentMargins: AD.LayoutFoundation.create({
      top: 0,
      left: standardLeftRightMargin,
      right: standardLeftRightMargin,
    }),
    footerMargins: AD.LayoutFoundation.create({
      bottom: 40,
      left: standardLeftRightMargin,
      right: standardLeftRightMargin,
    }),
  });

  const normalImg = props.logo ? createImageResource(props.logo) : undefined;

  const header = createHeader(normalImg, props.translate, undefined);
  const footer = createFooter(normalImg, props.translate);

  return AD.MasterPage.create({
    style: pageStyle,
    header: header,
    footer: footer,
  });
}

export async function getPageProps(reportParams: ReportParams, common: CommonResponse): Promise<CataloguePageProps> {
  const imageLogo = await imageToBitmapImage(200, 80, common.logoImage[0]);
  return {
    logo: imageLogo,
    reportId: reportParams.reportType,
    clientConfig: reportParams.clientConfig,
    translate: reportParams.translate,
    getFieldFormat: reportParams.getFieldFormat,
  };
}

export function pageId(pageProps: CataloguePageProps): string {
  return `${pageProps.reportId}`;
}
