import { exhaustiveCheck } from "ts-exhaustive-check";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { Cmd } from "@typescript-tea/core";
import { HttpEffectManager, Routes, SharedState } from "@ehb/client-infra";
import * as Header from "../header";
import * as StartPage from "../pages/start-page";
import * as ProductSelect from "../pages/product-select";
import * as ProductSearch from "../pages/product-search";
import * as ProductCalculate from "../pages/product-calculate";
import * as SpecialCalculation from "../pages/special-calculation";

export type State = {
  readonly location: Routes.MainLocation;
  readonly headerState: Header.State | undefined;
  readonly productSelectState: ProductSelect.State | undefined;
  readonly productSearchState: ProductSearch.State | undefined;
  readonly productCalculateState: ProductCalculate.State | undefined;
  readonly specialCalculationState: SpecialCalculation.State | undefined;
  readonly startPageState: StartPage.State | undefined;
  readonly waitingForResponse: boolean;
  readonly errorResponse: boolean;
};

export const Action = ctorsUnion({
  DispatchHeader: (action: Header.Action) => ({ action }),
  DispatchProductSelect: (action: ProductSelect.Action) => ({ action }),
  DispatchProductSearch: (action: ProductSearch.Action) => ({ action }),
  DispatchProductCalculate: (action: ProductCalculate.Action) => ({ action }),
  DispatchSpecialCalculation: (action: SpecialCalculation.Action) => ({ action }),
  DispatchStartPage: (action: StartPage.Action) => ({ action }),
  HttpStateChanged: (httpState: HttpEffectManager.HttpState) => ({ httpState }),
});
export type Action = CtorsUnion<typeof Action>;

export function init(
  location: Routes.MainLocation,
  prevState: State | undefined,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?] {
  const cmds: Array<Cmd<Action> | undefined> = [];
  let state: State = {
    location: location,
    headerState: prevState?.headerState,
    productSelectState: prevState?.productSelectState,
    productSearchState: prevState?.productSearchState,
    productCalculateState: prevState?.productCalculateState,
    specialCalculationState: prevState?.specialCalculationState,
    startPageState: prevState?.startPageState,
    waitingForResponse: false,
    errorResponse: false,
  };

  const [headerState, headerCmd] = Header.init(prevState?.headerState, sharedState);
  state = {
    ...state,
    headerState: headerState,
  };

  cmds.push(Cmd.map(Action.DispatchHeader, headerCmd));

  switch (location.type) {
    case "StartPage": {
      const [newState, initCmds] = StartPage.init(prevState?.startPageState, sharedState);
      state = {
        ...state,
        startPageState: newState,
      };
      cmds.push(Cmd.map(Action.DispatchStartPage, initCmds));
      break;
    }

    case "ProductSelect": {
      const [newState, initCmds] = ProductSelect.init(prevState?.productSelectState, sharedState, location);
      state = {
        ...state,
        productSelectState: newState,
      };
      cmds.push(Cmd.map(Action.DispatchProductSelect, initCmds));
      break;
    }

    case "ProductSearch": {
      const [newState, initCmds] = ProductSearch.init(prevState?.productSearchState, sharedState, location);
      state = {
        ...state,
        productSearchState: newState,
      };
      cmds.push(Cmd.map(Action.DispatchProductSearch, initCmds));
      break;
    }

    case "ProductCalculate": {
      const [newState, initCmds] = ProductCalculate.init(prevState?.productCalculateState, sharedState, location);
      state = {
        ...state,
        productCalculateState: newState,
      };
      cmds.push(Cmd.map(Action.DispatchProductCalculate, initCmds));
      break;
    }

    case "SpecialCalculation": {
      const [newState, initCmds] = SpecialCalculation.init(prevState?.specialCalculationState, sharedState, location);
      state = {
        ...state,
        specialCalculationState: newState,
      };
      cmds.push(Cmd.map(Action.DispatchSpecialCalculation, initCmds));
      break;
    }

    default:
      return exhaustiveCheck(location, true);
  }

  return [state, Cmd.batch(cmds)];
}

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "DispatchHeader": {
      if (!state.headerState) {
        return [state];
      }
      const [headerState, headerCmd, sharedStateUpdate] = Header.update(action.action, state.headerState, sharedState);

      const newState = { ...state, headerState: headerState };

      return [newState, Cmd.map(Action.DispatchHeader, headerCmd), sharedStateUpdate];
    }
    case "DispatchStartPage": {
      if (!state.startPageState) {
        return [state];
      }
      const [newState, newCmd, sharedStateUpdate] = StartPage.update(action.action, state.startPageState, sharedState);
      return [{ ...state, startPageState: newState }, Cmd.map(Action.DispatchStartPage, newCmd), sharedStateUpdate];
    }
    case "DispatchProductSelect": {
      if (!state.productSelectState) {
        return [state];
      }
      const [newState, newCmd, sharedStateUpdate] = ProductSelect.update(
        action.action,
        state.productSelectState,
        sharedState
      );
      return [
        { ...state, productSelectState: newState },
        Cmd.map(Action.DispatchProductSelect, newCmd),
        sharedStateUpdate,
      ];
    }
    case "DispatchProductSearch": {
      if (!state.productSearchState) {
        return [state];
      }
      const [newState, newCmd, sharedStateUpdate] = ProductSearch.update(
        action.action,
        state.productSearchState,
        sharedState
      );
      return [
        { ...state, productSearchState: newState },
        Cmd.map(Action.DispatchProductSearch, newCmd),
        sharedStateUpdate,
      ];
    }

    case "DispatchProductCalculate": {
      if (!state.productCalculateState) {
        return [state];
      }
      const [newState, newCmd, sharedStateUpdate] = ProductCalculate.update(
        action.action,
        state.productCalculateState,
        sharedState
      );
      return [
        { ...state, productCalculateState: newState },
        Cmd.map(Action.DispatchProductCalculate, newCmd),
        sharedStateUpdate,
      ];
    }

    case "DispatchSpecialCalculation": {
      if (!state.specialCalculationState) {
        return [state];
      }
      const [newState, newCmd, sharedStateUpdate] = SpecialCalculation.update(
        action.action,
        state.specialCalculationState,
        sharedState
      );
      return [
        { ...state, specialCalculationState: newState },
        Cmd.map(Action.DispatchSpecialCalculation, newCmd),
        sharedStateUpdate,
      ];
    }

    case "HttpStateChanged": {
      let newState = state;
      if (action.httpState === "waiting") {
        newState = {
          ...state,
          waitingForResponse: true,
        };
      }
      if (action.httpState === "idle") {
        newState = {
          ...state,
          waitingForResponse: false,
        };
      }
      return [newState];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}
