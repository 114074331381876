import { CtorsUnion, ctorsUnion } from "ctors-union";
import { AllProductsQuery } from "@ehb/shared/src/generated/generated-operations";
import { GetFieldFormatFn, GetFieldFormatsFn, SelectableFormat, Texts, User } from "@ehb/shared";
import { graphQLProductQueryWithAuth } from "../graphql";

type Product = AllProductsQuery["products"][number];

export interface SharedState {
  readonly activeUser: User.ActiveUser;
  readonly selectedLanguage: string;
  readonly translate: Texts.TranslateFn;
  readonly getFieldFormat: GetFieldFormatFn;
  readonly getFieldFormats: GetFieldFormatsFn;
  readonly graphQLProductQuery: ReturnType<typeof graphQLProductQueryWithAuth>;
  readonly productById: Record<string, Product>;
  readonly productByKey: Record<string, Product>;
}

export const SharedStateAction = ctorsUnion({
  Logout: () => ({}),
  SetLanguage: (newLang: Texts.LanguageCode) => ({ newLang }),
  // UpdateUserSettings: (userSettings: UserSettings.UserSettings) => ({ userSettings }),
  SetSelectedFormat: (fieldName: string, selectedFormat: SelectableFormat) => ({ fieldName, selectedFormat }),
  SetFieldUnit: (fieldName: string, unit: string, decimalCount: number) => ({
    fieldName,
    unit,
    decimalCount,
  }),
  ClearFieldUnit: (fieldName: string) => ({ fieldName }),
});

export type SharedStateAction = CtorsUnion<typeof SharedStateAction>;
