import gql from "graphql-tag";

export const schema = gql`
  # We need to tell the server which types represent the root query
  # and root mutation types. We call the fields query and mutation by convention (but the types can have any name).
  schema {
    query: Query
    mutation: Mutation
    #subscription: Subscription
  }

  """
  This scalar is numeric and has Unix Time as gotten by JS call Date.getTime()
  """
  scalar Date

  # type Subscription {
  #   _dummy: String
  # }

  """
  This is the root query
  """
  type Query {
    # This type is needed here as root, but it cannot be empty so add a dummy
    _dummy: String
  }

  """
  This is the root mutation
  """
  type Mutation {
    ## Consider these articles when designing mutations:
    # https://dev-blog.apollodata.com/designing-graphql-mutations-e09de826ed97
    # http://facebook.github.io/relay/graphql/mutations.htm
    # This type is needed here as root, but it cannot be empty so add a dummy
    _dummy: String
  }
`;
