import { exhaustiveCheck } from "ts-exhaustive-check";
import { LangText_ModulesLanguagesFragment } from "@ehb/shared/src/graphql-schema";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import { SharedState } from "@ehb/client-infra";
import { Cmd } from "@typescript-tea/core";
import gql from "graphql-tag";
import { Images, Texts } from "@ehb/shared";
import * as GQLOps from "../generated/generated-operations";
import { clientConfig } from "../client-config";

export interface State {
  readonly metaProduct: GQLOps.HeaderMetaProductQuery | undefined;
}

const query = gql`
  query headerMetaProduct($productId: ID!) {
    product(id: $productId) {
      key
      modules {
        ...LangText_ModulesLanguages
        ...imageModules
      }
    }
  }
  ${LangText_ModulesLanguagesFragment}
  ${Images.imageModulesFragment}
`;

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?] {
  if (prevState) {
    return [prevState];
  }

  const initialState = { metaProduct: undefined };

  const gqlCmdProduct = sharedState.graphQLProductQuery<
    GQLOps.HeaderMetaProductQuery,
    GQLOps.HeaderMetaProductQueryVariables,
    Action
  >(query, { productId: clientConfig.promaster_meta_id }, (data) => {
    return Action.MetaProductRecieved(data);
  });

  return [initialState, gqlCmdProduct];
}

export const Action = ctorsUnion({
  ChangeLanguage: (newLang: Texts.LanguageCode) => ({ newLang }),
  Logout: () => ({}),
  MetaProductRecieved: (response: GQLOps.HeaderMetaProductQuery) => ({
    response,
  }),
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  _sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "Logout": {
      return [state, undefined, SharedState.SharedStateAction.Logout()];
    }
    case "ChangeLanguage": {
      return [state, undefined, SharedState.SharedStateAction.SetLanguage(action.newLang)];
    }
    case "MetaProductRecieved": {
      return [{ ...state, metaProduct: action.response }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
