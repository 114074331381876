import * as R from "ramda";
import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { Amount } from "uom";
import { saveAs } from "file-saver";
import { ImageTableRows, getFilteredImagesTable } from "@ehb/shared/src/calculator";
import { roundTo } from "@ehb/shared/src/utils";
import { SharedState, createBlobUrlCreator } from "@ehb/client-infra";
import {
  A3D,
  CalculatorResult,
  GetFieldFormatFn,
  GetFieldFormatsFn,
  Search,
  SelectableFormat,
  Texts,
  CalculatorFrtCoil,
  MagicloudApi,
} from "@ehb/shared";
import { unitLookup } from "uom-units";
import { Accessory, getAvailableAccessories } from "@ehb/shared/src/accessories";
import { CoilResult } from "@ehb/shared/src/calculator-frt-coil";
import { ActiveUser, isInternalUser } from "@ehb/shared/src/user";
import React, { Dispatch } from "react";
import { TranslateFn, key, texts } from "@ehb/shared/src/lang-texts";
import { retryFetch } from "@ehb/shared/src/fetch";
import {
  CheckboxInput,
  Icon,
  PropertiesSelector,
  Spinner,
  SpinnerContainer,
  SvgQuestionMark,
  withTw,
} from "../../elements";
import { State, Action, ResultOrder, MagicloudData } from "./state";
import {
  PropertySelectorDef,
  PropertyValueDef,
  mapPropertiesToSelector,
} from "../../elements/properties-selector/property-selector-def";
import { propertyDefsFromSearchResult } from "../../elements/properties-selector/search-properties";
import { Button } from "../../elements/button";
import { FieldFormatSelector } from "../../elements/field-format-selector";
import { Viewer3d } from "../../elements/viewer-3d";
import { DropdownButton, DropdownButtonItem } from "../../elements/dropdown-button";
import { Group } from "../../elements/group";
import { ImageCombobox } from "../../elements/popup-description";
import { ProductQuery } from "../../generated/generated-operations";

const Container = withTw(
  "div",
  "flex relative gap-4 block gap-10 text-xs flex-col lg:flex-row max-w-[1200px] ml-auto mr-auto w-full pt-4 pb-4"
);
const InfoPart = withTw("div", " mt-10");

const Center = withTw("div", "flex");
const BorderContainer = withTw("div", "rounded border-slate-100 border p-4");
const DataContainer = withTw("div", "flex flex-col space-y-4");
const ResultViewContainer = withTw(
  "div",
  "items-center gap-[5px] mt-[12px] rounded border-slate-100 border p-4 shrink"
);
const ResultRowContainer = withTw("div", "flex justify-between items-center");
const ResultLabel = withTw("label", "font-bold h-[20px]");
const ResultText = withTw("div", "text-md flex");

const InfoPartInner = withTw(
  "div",
  "flex-row shrink relative lg:sticky top-4 lg:flex-col rounded border-slate-100 border mb-6 p-4 row-span-1 min-w-80"
);

export function View({
  state,
  sharedState,
  dispatch,
}: {
  readonly state: State;
  readonly sharedState: SharedState.SharedState;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const { activeUser, translate, selectedLanguage, getFieldFormat, getFieldFormats } = sharedState;
  const {
    variant,
    searchProduct,
    searchData,
    searchResult: searchResult2,
    match,
    calculationResults,
    calcData,
    resultOrder,
    productKey,
    magicloudData,
    calculating,
  } = state;

  const [closedGroups, setClosedGroups] = React.useState<ReadonlyArray<string>>([]);

  const searchProperties = React.useMemo(
    () => (searchResult2 ? propertyDefsFromSearchResult(translate, searchResult2) : undefined),
    [searchResult2]
  );

  const propertySelectorDefs = React.useMemo(() => {
    if (!searchData || !searchResult2 || !match) {
      return [];
    }
    const defs = mapPropertiesToSelector(activeUser, searchProduct, variant, translate);
    const defsWithSearchValues = defs
      .map((def): PropertySelectorDef => {
        const values = Search.getPropertyValues(translate, searchData, searchResult2, def.name);
        const visibilityFilter = createVisibilityFilter(productKey, def, searchData, match.selection);

        if (!values) {
          return { ...def, visibilityFilter };
        }

        const items = values
          .filter((v) => v.value.value !== "_all" && v.value.value !== "N/A")
          .map(
            (v, i): PropertyValueDef => ({
              sortNo: i,
              value: v.value,
              validationFilter: v.validationFilter,
              text: v.text,
            })
          );

        return {
          ...def,
          visibilityFilter: items.length > 0 ? visibilityFilter : PropertyFilter.fromStringOrEmpty("2=0", unitLookup),
          type: "Discrete",
          selectorType: "dropdown",
          items: items,
        };
      })
      .filter((d) => d.group !== "calculation_target");
    return defsWithSearchValues;
  }, [productKey, searchProduct, selectedLanguage, searchResult2, match, PropertyValueSet.toString(variant)]);

  const accessories = searchProduct && match && getAvailableAccessories(searchProduct, calculationResults, match);

  React.useEffect(() => {
    for (const selectedAccessory of state.selectedAccessories) {
      if (accessories?.find((a) => a.articleNumber === selectedAccessory.articleNumber) === undefined) {
        dispatch(Action.SetSelectedAccessory(selectedAccessory, false));
      }
    }
  }, [accessories]);

  if (
    !searchProduct?.product ||
    !searchData ||
    !searchResult2 ||
    !match ||
    !calcData ||
    searchProperties === undefined ||
    !accessories
  ) {
    return <Spinner />;
  }

  return (
    <Container>
      <InfoPart>
        <Center>
          <div className="m-auto my-2">
            <a href={"/product-search"}>
              <Button label={translate(texts.back_to_search)} iconLeft={"arrow-left"} type={"primary"} />
            </a>
          </div>
        </Center>
        <InfoPartInner>
          <dt className="text-base font-semibold text-gray-900 flex flex-row gap-4 items-center">
            <SvgQuestionMark width={"40px"} fill={"#b2122d"} className="text-red-600" />
            {translate(texts.start_page_manual_heading)}
          </dt>
          <dd className="mt-2 text-base text-gray-600 min-w-[12rem] max-w-[24rem] w-full ">
            {translate(texts.start_page_manual_coil)}
          </dd>
        </InfoPartInner>
      </InfoPart>
      <DataContainer className="min-w-sm">
        <h1 className="text-2xl text-center">{translate(texts.options)}</h1>
        <div className="min-w-sm w-full">
          <div className="flex flex-col space-y-4">
            <PropertiesSelector
              selectedProperties={variant}
              properties={propertySelectorDefs}
              onChange={(
                newSelectedProperties: PropertyValueSet.PropertyValueSet,
                _propertyNames: ReadonlyArray<string>
              ) => {
                dispatch(Action.SetVariant(newSelectedProperties));
              }}
              onPropertyFormatChanged={(fieldName, unit, decimalCount) => {
                dispatch(Action.SetSelectedFormat(fieldName, { unit, decimalCount }));
              }}
              onPropertyFormatCleared={(fieldName) => {
                dispatch(Action.ClearFieldUnit(fieldName));
              }}
              activeUser={activeUser}
              productImages={getFilteredImagesTable(searchProduct, variant, false)}
              translate={translate}
              productKey={searchProduct.product.key}
              getFieldFormat={getFieldFormat}
              getFieldFormats={getFieldFormats}
              disableSingleItemDropdowns={true}
            />

            {accessories.length > 0 && (
              <Group
                header={translate(texts.accessories)}
                closed={closedGroups.includes("accessories")}
                onToggleClosed={() =>
                  closedGroups.includes("accessories")
                    ? setClosedGroups(closedGroups.filter((g) => g !== "accessories"))
                    : setClosedGroups([...closedGroups, "accessories"])
                }
              >
                <div className="flex flex-col space-y-2">
                  {accessories.map((a) => (
                    <AccessorySelector
                      key={a.articleNumber}
                      accessory={a}
                      searchProduct={searchProduct}
                      selected={state.selectedAccessories.some((sel) => sel.articleNumber === a.articleNumber)}
                      onClick={(checked) => dispatch(Action.SetSelectedAccessory(a, checked))}
                      activeUser={sharedState.activeUser}
                      translate={translate}
                    />
                  ))}
                </div>
              </Group>
            )}
          </div>
        </div>
      </DataContainer>
      <SpinnerContainer loading={magicloudData?.loading || calculating}>
        <div className="w-full space-y-8">
          {match && calculationResults && (
            <>
              <ResultsView
                translate={translate}
                getFieldFormat={getFieldFormat}
                getFieldFormats={getFieldFormats}
                dispatch={dispatch}
                match={match}
                results={calculationResults}
                activeUser={sharedState.activeUser}
                searchResultColumnTable={searchData.searchResultColumns}
                resultOrder={resultOrder}
                variant={variant}
                state={state}
                sharedState={sharedState}
              />
              <MagicloudModel
                match={match}
                magicloudData={magicloudData}
                translate={translate}
                dispatch={dispatch}
                orbitCamera={state.orbitCamera}
              />
              <MagicloudDimensions magicloudData={magicloudData} translate={translate} />
            </>
          )}
        </div>
      </SpinnerContainer>
    </Container>
  );
}

function AccessorySelector({
  accessory,
  selected,
  onClick,
  activeUser,
  searchProduct,
  translate,
}: {
  readonly accessory: Accessory;
  readonly selected: boolean;
  readonly onClick: (checked: boolean) => void;
  readonly activeUser: ActiveUser;
  readonly searchProduct: ProductQuery;
  readonly translate: TranslateFn;
}): JSX.Element {
  const images = searchProduct.product ? (searchProduct.product.modules.images.image as ImageTableRows[]) : [];
  const createBlobUrl = createBlobUrlCreator(activeUser.accessToken);

  return (
    <div className="min-h-8 first:mt-[5px] flex flex-row justify-between items-center">
      <span className="flex flex-row justify-between w-1/2">
        {accessory.name}
        {accessory.text_key && (
          <ImageCombobox
            images={images}
            createBlobUrl={createBlobUrl}
            description={translate(key(accessory.text_key, {}, searchProduct.product?.key))}
          />
        )}
        {isInternalUser(activeUser.claims) && (
          <span>
            {activeUser.claims.currency === "SEK" ? `${accessory.price_sek} SEK` : `${accessory.price_eur} EUR`}
          </span>
        )}
      </span>

      <span className="w-1/2 flex flex-row justify-center">
        <CheckboxInput
          checked={selected}
          type="checkbox"
          onChange={() => {
            onClick(!selected);
          }}
        />
      </span>
    </div>
  );
}

function createVisibilityFilter(
  productKey: string,
  def: PropertySelectorDef,
  searchData: Search.SearchData,
  selection: Search.Selection
): PropertyFilter.PropertyFilter {
  if (
    productKey === undefined ||
    def.group !== "selection" ||
    searchData.searchColumnProperties.map((p) => p.property || "").includes(def.name) ||
    (selection.pm_heating === "1" && selection.pm_cooling === "1" && def.name === "function")
  ) {
    return def.visibilityFilter;
  }

  return PropertyFilter.fromStringOrEmpty("1=0", unitLookup);
}

function ResultsView(props: {
  readonly activeUser: ActiveUser;
  readonly translate: Texts.TranslateFn;
  readonly getFieldFormat: GetFieldFormatFn;
  readonly getFieldFormats: GetFieldFormatsFn;
  readonly match: Search.Match;
  readonly results: CalculatorFrtCoil.CalculationResult;
  readonly searchResultColumnTable: Search.SearchResultColumnTable;
  readonly resultOrder: ResultOrder;
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly sharedState: SharedState.SharedState;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const {
    translate,
    getFieldFormat,
    results,
    match,
    state,
    sharedState,
    searchResultColumnTable,
    variant,
    getFieldFormats,
    dispatch,
  } = props;

  const productWebsiteUrl = state.searchProduct?.product?.modules.custom_tables.website_product_url.find(
    (u) => u.language === sharedState.selectedLanguage && u.product === match.selection.productId
  );

  if (!results) {
    return (
      <>
        <ResultViewContainer>{translate(texts.fill_in_values)}</ResultViewContainer>
        {productWebsiteUrl?.url && (
          <div className="mt-2.5">
            <a href={productWebsiteUrl.url} target={"_blank"} rel={"noopener noreferrer"}>
              <Button label={translate(texts.view_product)} iconRight={"right-to-bracket"} />
            </a>
          </div>
        )}
      </>
    );
  }

  if (results.type === "Err" || results.value.messages.some((m) => m.type === "error")) {
    return (
      <>
        <ResultViewContainer>
          <h1 className="text-lg">{translate(texts.err_not_possible)} </h1>
          <h2 className="text-lg text-red-500">
            {results.type === "Err" &&
              results.error.messages.map((m) => <div key={m.text.key}>{translate(m.text, m.textFallback)}</div>)}
            {results.type === "Ok" &&
              results.value.messages.some((m) => m.type === "error") &&
              results.value.messages.map((m) => <div key={m.text.key}>{translate(m.text, m.textFallback)}</div>)}
          </h2>
        </ResultViewContainer>
        {productWebsiteUrl?.url && (
          <div className="mt-2.5">
            <a href={productWebsiteUrl.url} target={"_blank"} rel={"noopener noreferrer"}>
              <Button label={translate(texts.view_product)} iconRight={"right-to-bracket"} />
            </a>
          </div>
        )}
      </>
    );
  }

  const resultFields = [
    { label: texts.model, text: match.model, fieldName: undefined },
    ...searchResultColumnTable
      .filter(
        (r) =>
          PropertyFilter.isValid(variant, r.property_filter) &&
          r.type !== "meta" &&
          (isInternalUser(sharedState.activeUser.claims) || r.type !== "price")
      )
      .map((r) => {
        if (r.type === "variant_column") {
          return {
            label: Texts.key(r.text_key),
            text: match.productVariantRow.art_nr || "",
            fieldName: r.field_name,
          };
        } else if (r.type === "price") {
          const value = R.path(r.value.split("."), results.value.price) as number | undefined;
          return {
            label: Texts.key(r.text_key),
            text: (value && roundTo(value, 2)?.toString()) || "",
            fieldName: r.field_name,
          };
        } else {
          const resultValue = results.value[r.value as keyof Omit<CoilResult, "messages">];

          return {
            label: Texts.key(r.text_key),
            text: CalculatorResult.renderValue(getFieldFormat, r.field_name, resultValue as Amount.Amount<unknown>),
            fieldName: r.field_name,
          };
        }
      }),
  ];

  return (
    <DataContainer>
      <h1 className="text-2xl text-center">{translate(texts.data)}</h1>
      {results.value.messages.length > 0 && (
        <div>
          {results.value.messages
            .filter((m) => m.type === "warning")
            .map((m) => (
              <div key={m.text.key} className="flex gap-2 mb-2 border-orange-100 border bg-yellow-50 p-2 rounded">
                <Icon icon="triangle-exclamation" className="warning-icon pl-1 self-start" size="lg" />
                <span>{translate(m.text, m.textFallback)}</span>
              </div>
            ))}
        </div>
      )}
      <ResultViewContainer>
        {resultFields.map((field) => (
          <ResultRow
            key={field.label.key}
            label={translate(field.label)}
            text={field.text}
            fieldName={field.fieldName}
            getFieldFormats={getFieldFormats}
            getFieldFormat={getFieldFormat}
            dispatch={dispatch}
            activeUser={sharedState.activeUser}
          />
        ))}
      </ResultViewContainer>

      <div className="mx-2">
        <div className="flex flex-row mt-2.5 justify-around w-full">
          <Button
            label={translate(texts.pdf_datasheet)}
            onClick={() => {
              dispatch(Action.GetReport("technical-data-sheet"));
            }}
            iconLeft="file-pdf"
          />
          <Button
            label={translate(texts.pdf_quote)}
            onClick={() => {
              dispatch(Action.GetReport("quote-page-water"));
            }}
            iconLeft="file-pdf"
          />
          {productWebsiteUrl?.url && (
            <a href={productWebsiteUrl.url} target={"_blank"} rel={"noopener noreferrer"}>
              <Button label={translate(texts.view_product)} iconRight={"right-to-bracket"} />
            </a>
          )}
        </div>
      </div>
    </DataContainer>
  );
}

function MagicloudModel({
  match,
  magicloudData,
  translate,
  dispatch,
  orbitCamera,
}: {
  readonly match: Search.Match;
  readonly magicloudData: MagicloudData | undefined;
  readonly translate: Texts.TranslateFn;
  readonly dispatch: Dispatch<Action>;
  readonly orbitCamera: A3D.OrbitCamera | undefined;
}): JSX.Element | null {
  if (!magicloudData) {
    return null;
  }

  const fileItems: Array<DropdownButtonItem> = [];
  const model = magicloudData?.model;
  if (model?.dxfUrl) {
    fileItems.push({
      value: model.dxfUrl,
      label: "DXF",
      onClick: () => {
        downloadFile(match.model, "dxf", model.dxfUrl || "");
      },
    });
  }
  // Disabled for now, https://systemair.atlassian.net/browse/EHB-256?focusedCommentId=60825
  // if (model?.revitUrls) {
  //   model.revitUrls.forEach((revitUrl) =>
  //     fileItems.push({
  //       value: revitUrl.name,
  //       label: revitUrl.name.replace("_LevelBased", "").split("_").join(" "),
  //       onClick: () => {
  //         downloadFile(match.model, "rfa", revitUrl.url);
  //       },
  //     })
  //   );
  // }

  return (
    <DataContainer>
      <h1 className="text-2xl text-center">{translate(texts.dxf_and_rfa)}</h1>
      <div className="flex flex-row space-x-4 mb-1">
        <DropdownButton label="Download" iconLeft="download" items={fileItems} />
      </div>
      {magicloudData.model && (
        <BorderContainer>
          <Viewer3d
            x3dModel={magicloudData.model}
            orbitCamera={orbitCamera}
            onCameraUpdate={(c) => dispatch(Action.SetOrbitCamera(c))}
            translate={translate}
          />
        </BorderContainer>
      )}
    </DataContainer>
  );
}

function MagicloudDimensions({
  magicloudData,
  translate,
}: {
  readonly magicloudData: MagicloudData | undefined;
  readonly translate: Texts.TranslateFn;
}): JSX.Element | null {
  const dimensionsUrl = magicloudData?.model?.dimensionsUrl;
  if (!dimensionsUrl) {
    return null;
  }
  return (
    <DataContainer>
      <h1 className="text-2xl text-center">{translate(texts.dimensions)}</h1>
      <BorderContainer className="flex justify-center">
        <img src={dimensionsUrl} />
      </BorderContainer>
    </DataContainer>
  );
}

async function downloadFile(model: string, extension: "rfa" | "dxf", url: string): Promise<void> {
  const filename = `${model.replace(/\/|\\|\?|%|\*|:|\||"|<|>|\.|,|;|=/g, "_") || "model"}.${extension}`;
  const mcUrl = MagicloudApi.createFileDownloadMcUrl(url, {
    source: window.location.hostname,
    orderingCode: model,
    fileType: extension,
    fileName: filename,
  });
  const response = await retryFetch(mcUrl, undefined, { maxAttempts: 1 });
  const data = await response.arrayBuffer();
  const blob = new Blob([data]);
  saveAs(blob, filename);
}

function ResultRow(props: {
  readonly label: string;
  readonly text: string;
  readonly fieldName: string | undefined;
  readonly getFieldFormats: GetFieldFormatsFn;
  readonly getFieldFormat: GetFieldFormatFn;
  readonly dispatch: Dispatch<Action>;
  readonly activeUser: ActiveUser;
}): JSX.Element {
  const { label, text, fieldName, activeUser, getFieldFormats, getFieldFormat, dispatch } = props;
  return (
    <ResultRowContainer>
      <ResultLabel>{label}:</ResultLabel>
      <ResultText>
        <div className="p-2">{text}</div>
        <div className="w-20">
          {fieldName && (
            <FieldFormatSelector
              fieldName={fieldName}
              getFieldFormat={getFieldFormat}
              getFieldFormats={getFieldFormats}
              setFieldFormat={(format: SelectableFormat) => {
                dispatch(Action.SetSelectedFormat(fieldName, { unit: format.unit, decimalCount: format.decimalCount }));
              }}
              classNameOverride={"p-1 min-w-14 text-right bg-transparent"}
              containerClassNameOverride={"bg-transparent rounded hover:bg-slate-200"}
              alwaysShowDropdown={true}
              activeUser={activeUser}
            />
          )}
        </div>
      </ResultText>
    </ResultRowContainer>
  );
}
