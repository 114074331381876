import gql from "graphql-tag";
import { SearchQuery, SearchSingleQuery } from "../generated/generated-operations";
import { NonNull, TableWithPropertyFilter } from "../utils";
import { propertyModulesFragment } from "../graphql-schema/property-fragments";

export const productSearchSelectionFragment = gql`
  fragment productSearchSelection on search_input {
    pm_product_type
    pm_model
    pm_medium
    pm_cooling
    pm_heating
    pm_connection
    pm_size
    pm_capacity
    pm_speed
    frt_dll_number_of_rows
    fixed_airflow
    pm_controller_type
  }
`;

export const productSelectionFragment = gql`
  fragment productSelection on product_variants {
    pm_product_type
    pm_model
    pm_medium
    pm_cooling
    pm_heating
    pm_connection
    pm_size
    pm_capacity
    pm_speed
    frt_dll_number_of_rows
    fixed_airflow
    magicloud_id
    art_nr
    price_sek
    price_eur
    pm_controller_type
  }
`;

export const searchProductFragment = gql`
  fragment SearchProduct on Product {
    key
    modules {
      properties {
        property {
          ...propertyModule
        }
      }
      custom_tables {
        search_products {
          company
          product
        }
        search_column_properties {
          property_filter
          property
          column_name
        }
        search_calculation_limits {
          property_filter
          property
          result_field
        }
        search_calculation_margin {
          property_filter
          property
          target_property
          operation
        }
        search_input {
          property_filter
          ...productSearchSelection
        }
        search_result_columns {
          property_filter
          value
          field_name
          text_key
          type
          sort_order
        }
      }
    }
  }
  ${propertyModulesFragment}
  ${productSearchSelectionFragment}
`;

export const productFragment = gql`
  fragment ProductSearched on Product {
    key
    id
    modules {
      custom_tables {
        product_variants {
          frt_dll_number_of_rows
          model
          ...productSelection
        }
      }
    }
  }
  ${productSelectionFragment}
`;

export const searchProductQueryAll = gql`
  query Search($searchProductId: ID!) {
    searchProduct: product(id: $searchProductId) {
      ...SearchProduct
    }
    products {
      ...ProductSearched
    }
  }
  ${searchProductFragment}
  ${productFragment}
`;

export const searchProductQuerySingle = gql`
  query SearchSingle($searchProductId: ID!, $productId: ID!) {
    searchProduct: product(id: $searchProductId) {
      ...SearchProduct
    }
    product(id: $productId) {
      ...ProductSearched
    }
  }
  ${searchProductFragment}
  ${productFragment}
`;

export type Products = SearchQuery["products"];
export type Product = SearchSingleQuery["product"];
export type ProductVariantsRow = Omit<
  SearchQuery["products"][0]["modules"]["custom_tables"]["product_variants"][0],
  "__typename"
>;
export type ProductVariantsTable = ReadonlyArray<ProductVariantsRow>;
export type SearchProduct = Exclude<SearchQuery["searchProduct"], null>;
export type SearchProductTables = SearchProduct["modules"]["custom_tables"];

export type SearchProductsTable = SearchProductTables["search_products"];
export type SearchColumnPropertiesTable = TableWithPropertyFilter<SearchProductTables["search_column_properties"]>;
export type SearchInputTable = TableWithPropertyFilter<SearchProductTables["search_input"]>;
export type SearchCalculationLimitsTable = TableWithPropertyFilter<SearchProductTables["search_calculation_limits"]>;
export type SearchCalculationMarginTable = TableWithPropertyFilter<SearchProductTables["search_calculation_margin"]>;
export type SearchProperties = SearchProduct["modules"]["properties"]["property"];

export type SearchResultColumn = NonNull<TableWithPropertyFilter<SearchProductTables["search_result_columns"]>[0]>;
export type SearchResultColumnTable = ReadonlyArray<SearchResultColumn>;
