import { PropertyValue as PV, PropertyValueSet as PVS, PropertyValueSet } from "@promaster-sdk/property";
import pako from "pako";
import { Amount } from "uom";
import { FP, logError } from "..";
import {
  AirDirection,
  CableInlet,
  Connection,
  CustomItems,
  Insulation,
  Material,
  MaxOutTemp,
  Model,
  IP_Class,
  Type,
  Voltage,
} from "../calculator";
import { EhbUnits, EhbUnitsLookup } from "../units";
import {
  ActiveUser,
  UserClaims,
  canChangeCurrencyOrDiscount,
  getUserClaimPropertyNames,
  isInternalUser,
  updateUserClaimProperties,
} from "../user";

function uint8ArrayToBase64(uint8Array: Uint8Array): string {
  const binaryString = String.fromCharCode.apply(null, Array.from(uint8Array));
  return encodeURIComponent(btoa(binaryString));
}

function base64ToUint8Array(base64: string): Uint8Array {
  const binaryString = atob(decodeURIComponent(base64));
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }
  return byteArray;
}

export function encodeVariantPropertyValueSet(
  variant: PVS.PropertyValueSet,
  customItems: CustomItems,
  user: ActiveUser
): { readonly [s: string]: string } {
  const encodedVariant = { ...encodePropertyValueSet(variant, user.claims) };
  if (isInternalUser(user.claims) && customItems.length > 0) {
    const customItemsString = JSON.stringify(customItems);
    const compressedData = pako.deflate(customItemsString);
    const encodedCustomItemsString = uint8ArrayToBase64(compressedData);
    encodedVariant["customItems"] = `${encodedCustomItemsString}`;
  }
  return encodedVariant;
}

export function encodePropertyValueSet(
  variant: PVS.PropertyValueSet,
  userClaims: UserClaims
): { readonly [s: string]: string } {
  const cleanVariant = canChangeCurrencyOrDiscount(userClaims)
    ? variant
    : PropertyValueSet.removeProperties(getUserClaimPropertyNames(), variant);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const encoded: any = {};
  for (const property of PVS.getPropertyNames(cleanVariant)) {
    const value = PVS.get(property, cleanVariant);
    if (value === undefined || (value.type === "text" && value.value.trim() === "")) {
      continue;
    }
    encoded[property] = encodeURIComponent(PV.toString(value));
  }
  return encoded;
}

export function decodePropertyValueSet(
  variant: { readonly [s: string]: string },
  user: ActiveUser
): PVS.PropertyValueSet {
  let decoded: PVS.PropertyValueSet = PVS.Empty;
  for (const property of Object.keys(variant)) {
    if (property === "customItems") {
      continue;
    }
    const value = decodeURIComponent(variant[property]);
    if (value === undefined) {
      continue;
    }
    const parsed = PV.fromString(value, EhbUnitsLookup);
    if (parsed === undefined) {
      continue;
    }

    decoded = PVS.set(property, parsed, decoded);
  }

  decoded = updateUserClaimProperties(user.claims, decoded);

  return decoded;
}

export function decodeCustomItems(queryParams: { readonly [key: string]: string }): CustomItems {
  const encodedCustomItemsString = queryParams["customItems"];

  if (!encodedCustomItemsString) {
    return [];
  }

  // Remove spaces from the encoded string
  const cleanedEncodedString = encodedCustomItemsString.replace(/\s+/g, "");

  try {
    const compressedData = base64ToUint8Array(cleanedEncodedString);

    const decompressedData = pako.inflate(compressedData, { to: "string" });
    const customItems = JSON.parse(decompressedData) as CustomItems;
    return customItems;
  } catch (error) {
    logError("Error decoding custom items:", error);
    return [];
  }
}

export function reverseLookUpVariant(orderingCode: string): FP.Result<string, PVS.PropertyValueSet> {
  const regex =
    /[a-zA-Z][a-zA-Z0-9][a-zA-Z0-9]*(-[a-zA-Z0-9]+)*(-[0-9][0-9][0-9][a-z])*(-[a-zA-Z0-9]+.\d[a-z][A-Z])*(-[0-9][a-z][0-9][0-9][0-9][A-Z])*(-[A-Z])*(-[a-zA-Z0-9]+)*(-\d+°[a-zA-Z0-9]+)*(-[a-zA-Z0-9]+)*$/;

  if (orderingCode.length === 0 || orderingCode === undefined || !regex.test(orderingCode)) {
    return FP.Err("Not a valid code.");
  }
  let model: Model = Model.VRA;
  let type: Type = Type.M;
  let width: number = 0;
  let height: number = 0;
  let power: number = 0;
  let voltage: Voltage = Voltage.Phase3Volt400;
  let material: Material = Material.A;
  let protection: IP_Class = IP_Class.IP43;
  let insulation: Insulation = Insulation.NI;
  let maxoutputtemp: MaxOutTemp = MaxOutTemp._50C;
  let airdirection: AirDirection = AirDirection.L;
  let cableinlet: CableInlet = CableInlet.D1;
  let connection: Connection = Connection.Rectangular;

  for (const [index, codePart] of orderingCode.split("-").entries()) {
    switch (index) {
      case 0: {
        const mappedModel = Model[codePart as keyof typeof Model];
        if (!mappedModel) {
          return FP.Err("Not a valid code.");
        }
        model = mappedModel;
        break;
      }
      case 1: {
        const mappedType = Type[codePart as keyof typeof Type];
        if (!mappedType) {
          return FP.Err("Not a valid code.");
        }
        type = mappedType;
        break;
      }
      case 2: {
        const [w, h, d] = codePart.split("x");
        width = Number(w);
        height = Number(h);
        connection = d.toLowerCase() === "xxx" ? Connection.Circular : Connection.Rectangular;
        break;
      }
      case 3: {
        power = Number(codePart.replace("kW", ""));
        break;
      }
      case 4: {
        voltage = Number(codePart.replace("V", "").replace("x", ""));
        break;
      }
      case 5: {
        const mappedMaterial = Material[codePart as keyof typeof Material];
        if (!mappedMaterial) {
          return FP.Err("Not a valid code.");
        }
        material = mappedMaterial;
        break;
      }
      case 6: {
        const mappedProtection = IP_Class[codePart as keyof typeof IP_Class];
        if (!mappedProtection) {
          return FP.Err("Not a valid code.");
        }
        protection = mappedProtection;
        break;
      }
      case 7: {
        const mappedInsulation = Insulation[codePart as keyof typeof Insulation];
        if (!mappedInsulation) {
          return FP.Err("Not a valid code.");
        }
        insulation = mappedInsulation;
        break;
      }
      case 8: {
        const maxTempKey = `_${codePart.replace("°", "")}`;
        const mappedMaxOutputTemp = MaxOutTemp[maxTempKey as keyof typeof MaxOutTemp];

        if (!mappedMaxOutputTemp) {
          return FP.Err("Not a valid code.");
        }
        maxoutputtemp = mappedMaxOutputTemp;
        break;
      }
      case 9: {
        const mappedAirDirection = AirDirection[codePart as keyof typeof AirDirection];
        if (!mappedAirDirection) {
          return FP.Err("Not a valid code.");
        }
        airdirection = mappedAirDirection;
        break;
      }
      case 10: {
        const mappedCableinlet = CableInlet[codePart as keyof typeof CableInlet];
        if (!mappedCableinlet) {
          return FP.Err("Not a valid code.");
        }
        cableinlet = mappedCableinlet;
        break;
      }
      default:
        break;
    }
  }

  let pvs: PVS.PropertyValueSet = PVS.Empty;
  try {
    pvs = PVS.setInteger("model", model, pvs);
    pvs = PVS.setInteger("type", type, pvs);
    pvs = PVS.setAmount("width", Amount.create(width as number, EhbUnits.Millimeter), pvs);
    pvs = PVS.setAmount("height", Amount.create(height as number, EhbUnits.Millimeter), pvs);
    pvs = PVS.setAmount("power", Amount.create(power as number, EhbUnits.KiloWatt), pvs);
    pvs = PVS.setInteger("voltage", voltage, pvs);
    pvs = PVS.setInteger("material", material, pvs);
    pvs = PVS.setInteger("protection", protection, pvs);
    pvs = PVS.setInteger("insulation", insulation, pvs);
    pvs = PVS.setInteger("maxoutputtemp", maxoutputtemp, pvs);
    pvs = PVS.setInteger("airdirection", airdirection, pvs);
    pvs = PVS.setInteger("cableinlet", cableinlet, pvs);
    pvs = PVS.setInteger("connection", connection, pvs);
  } catch {
    return FP.Err("Not a valid code.");
  }

  return FP.Ok(pvs);
}
