export { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
export { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
export { faTrashAlt } from "@fortawesome/pro-light-svg-icons/faTrashAlt";
export { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown";
export { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
export { faEdit } from "@fortawesome/pro-light-svg-icons/faEdit";
export { faCopy } from "@fortawesome/pro-light-svg-icons/faCopy";
export { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
export { faSortAlphaUp } from "@fortawesome/pro-light-svg-icons/faSortAlphaUp";
export { faSortAlphaDownAlt } from "@fortawesome/pro-light-svg-icons/faSortAlphaDownAlt";
export { faSortNumericUp } from "@fortawesome/pro-light-svg-icons/faSortNumericUp";
export { faSortNumericDownAlt } from "@fortawesome/pro-light-svg-icons/faSortNumericDownAlt";
export { faSortAmountUpAlt } from "@fortawesome/pro-light-svg-icons/faSortAmountUpAlt";
export { faSortAmountDown } from "@fortawesome/pro-light-svg-icons/faSortAmountDown";
export { faTable } from "@fortawesome/pro-light-svg-icons/faTable";
export { faCircleInfo } from "@fortawesome/pro-light-svg-icons/faCircleInfo";
export { faFaceFrown } from "@fortawesome/pro-light-svg-icons/faFaceFrown";
export { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons/faTriangleExclamation";
export { faCircleCheck } from "@fortawesome/pro-light-svg-icons/faCircleCheck";
export { faCircleXmark } from "@fortawesome/pro-light-svg-icons/faCircleXmark";
export { faXmark } from "@fortawesome/pro-light-svg-icons/faXmark";
export { faRefresh } from "@fortawesome/pro-light-svg-icons/faRefresh";
export { faList } from "@fortawesome/pro-light-svg-icons/faList";
export { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown";
export { faAngleUp } from "@fortawesome/pro-light-svg-icons/faAngleUp";
export { faCube } from "@fortawesome/pro-light-svg-icons/faCube";
export { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
export { faArrowLeft } from "@fortawesome/pro-light-svg-icons/faArrowLeft";
export { faRightToBracket } from "@fortawesome/pro-light-svg-icons/faRightToBracket";
export { faSpinner } from "@fortawesome/pro-light-svg-icons/faSpinner";
export { faCircleNotch } from "@fortawesome/pro-light-svg-icons/faCircleNotch";

export type FalIconName =
  | "check"
  | "times"
  | "trash-alt"
  | "chevron-down"
  | "chevron-right"
  | "edit"
  | "copy"
  | "exclamation-circle"
  | "sort-alpha-up"
  | "sort-alpha-down-alt"
  | "sort-numeric-up"
  | "sort-numeric-down-alt"
  | "sort-amount-up-alt"
  | "sort-amount-down"
  | "table"
  | "circle-info"
  | "face-frown"
  | "triangle-exclamation"
  | "circle-check"
  | "xmark"
  | "refresh"
  | "list"
  | "angle-down"
  | "angle-up"
  | "cube"
  | "spinner"
  | "circle-notch"
  | "circle-xmark"
  | "arrow-line"
  | "plus"
  | "right-to-bracket"
  | "file-pdf";
