export { faArrowUpWideShort } from "@fortawesome/free-solid-svg-icons/faArrowUpWideShort";
export { faArrowDownShortWide } from "@fortawesome/free-solid-svg-icons/faArrowDownShortWide";
export { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
export { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
export { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
export { faRightToBracket } from "@fortawesome/free-solid-svg-icons/faRightToBracket";
export { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";

export type FasIconName =
  | "arrow-up-wide-short"
  | "farrow-down-short-wide"
  | "download"
  | "arrow-left"
  | "chevron-down"
  | "right-to-bracket"
  | "file-pdf";
