import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { ReportType } from "@ehb/shared/src/reports";
import { CtorsUnion, ctorsUnion } from "ctors-union";
import {
  Reports,
  Search,
  Calculate,
  FP,
  CalculatorFrtCoil,
  SelectableFormat,
  logWarn,
  A3D,
  Query,
  Accessories,
} from "@ehb/shared";
import { PropertyValueSet } from "@promaster-sdk/property";
import {
  SharedState,
  PromiseEffectManager,
  NavigationEffectManager as Navigation,
  Routes,
  HttpFetch,
  graphQLQueryWithAuth,
} from "@ehb/client-infra";
import { clientConfig } from "@ehb/client-infra/src/client-config";
import { productQuery } from "@ehb/shared/src/graphql-schema/product";
import { Accessory } from "@ehb/shared/src/accessories";
import { MainLocation } from "@ehb/client-infra/src/routes";
import { decodePropertyValueSet, encodePropertyValueSet } from "@ehb/shared/src/product-utils";
import { SharedStateAction } from "@ehb/client-infra/src/shared-state";
import { ActiveUser } from "@ehb/shared/src/user";
import { MagicloudApi } from "@ehb/shared/src";
import * as GQLOps from "../../generated/generated-operations";
import {
  generateDefaultProperties,
  resetCalcParamsToDefault,
} from "../../elements/properties-selector/property-selector-def";

type LocationParams = { readonly query: { readonly [query: string]: string }; readonly productKey: string };

export type ResultOrder = {
  readonly sortOrder: Search.SortOrder;
  readonly sortColumn: string;
};

export type MagicloudData = {
  readonly model: MagicloudApi.X3DModel | undefined;
  readonly loading: boolean;
};

export type State = {
  readonly variant: PropertyValueSet.PropertyValueSet;
  readonly autoCalculate: boolean;
  readonly searchProduct: GQLOps.ProductQuery | undefined;
  readonly calcData: CalculatorFrtCoil.CalculationData | undefined;
  readonly searchData: Search.SearchData | undefined;
  readonly searchResult: Search.Result | undefined;
  readonly match: Search.Match | undefined;
  readonly calculating: boolean;
  readonly calculationResults: CalculatorFrtCoil.CalculationResult | undefined;
  readonly resultOrder: ResultOrder;
  readonly productKey: string;
  readonly locationParams: LocationParams;
  readonly magicloudData: MagicloudData | undefined;
  readonly orbitCamera: A3D.OrbitCamera | undefined;
  readonly printoutStatus: "printing" | "idle";
  readonly reportQueryRunner: Reports.QueryRunner | undefined;
  readonly reportResponse: Reports.ReportQueryResponse | undefined;
  readonly selectedAccessories: ReadonlyArray<Accessory>;
};

export function init(
  prevState: State | undefined,
  sharedState: SharedState.SharedState,
  locationParams: LocationParams
): readonly [State, Cmd<Action>?] {
  if (prevState && locationParams.productKey === prevState.productKey && prevState.searchData) {
    const newVariant = decodePropertyValueSet(locationParams.query, sharedState.activeUser);
    const merged = PropertyValueSet.merge(newVariant, prevState.variant);
    const variant = PropertyValueSet.setInteger("mode", 2, merged); // mode=2 for product page

    return [
      { ...prevState, variant: variant },
      search(
        prevState.searchData,
        createSearchQuery(sharedState.activeUser, prevState.searchData, variant, prevState.productKey)
      ),
    ];
  }

  const gqlCmdProduct = sharedState.graphQLProductQuery<GQLOps.ProductQuery, GQLOps.ProductQueryVariables, Action>(
    productQuery,
    { productId: clientConfig.promaster_search_product_id, language: sharedState.selectedLanguage },
    (data) => {
      return Action.SearchProductRecieved(data);
    }
  );

  const variant = decodePropertyValueSet(locationParams.query, sharedState.activeUser);

  const initialState: State = {
    locationParams,
    searchProduct: undefined,
    variant: PropertyValueSet.setInteger("mode", 2, variant), // mode=2 for product page
    autoCalculate: true,
    calcData: undefined,
    searchData: undefined,
    searchResult: undefined,
    match: undefined,
    calculating: false,
    calculationResults: undefined,
    resultOrder: {
      sortOrder: "asc" as const,
      sortColumn: "model",
    },
    productKey: locationParams.productKey,
    magicloudData: undefined,
    orbitCamera: undefined,
    printoutStatus: "idle",
    reportQueryRunner: undefined,
    reportResponse: undefined,
    selectedAccessories: [],
  };

  return [initialState, Cmd.batch([gqlCmdProduct])];
}

export const Action = ctorsUnion({
  SearchProductRecieved: (data: GQLOps.ProductQuery) => ({ data }),
  SearchDataRecieved: (data: GQLOps.SearchSingleQuery) => ({ data }),
  CalcDataRecieved: (data: GQLOps.FrtCoilQuery) => ({ data }),
  SetVariant: (variant: PropertyValueSet.PropertyValueSet) => ({ variant }),
  UpdateSearchResult: (result: Search.Result) => ({ result }),
  UpdateCalculationResult: (result: CalculatorFrtCoil.CalculationResult) => ({ result }),
  SetSelectedFormat: (fieldName: string, selectedFormat: SelectableFormat) => ({ fieldName, selectedFormat }),
  SetFieldUnit: (fieldName: string, unit: string, decimalCount: number) => ({
    fieldName,
    unit,
    decimalCount,
  }),
  ClearFieldUnit: (fieldName: string) => ({ fieldName }),
  SetResultOrder: (resultOrder: ResultOrder) => ({ resultOrder }),
  UpdateMagicloudData: (data: MagicloudData) => ({ data }),
  SetOrbitCamera: (camera: A3D.OrbitCamera | undefined) => ({ camera }),
  SetSelectedAccessory: (accessory: Accessory, selected: boolean) => ({ accessory, selected }),
  SetPrintoutStatus: (status: "idle" | "printing") => ({ status }),
  ReportDataReceived: (data: unknown, report: ReportType) => ({ data, report: report as ReportType }),
  GetReport: (report: ReportType) => ({ report: report as ReportType }),
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.SharedState
): readonly [State, Cmd<Action>?, SharedState.SharedStateAction?] {
  switch (action.type) {
    case "SearchProductRecieved": {
      const variant = action.data.product
        ? generateDefaultProperties(action.data.product.modules.properties.property, state.variant)
        : PropertyValueSet.Empty;
      const newState = { ...state, searchProduct: action.data, variant };
      const gqlCmdSearch = sharedState.graphQLProductQuery<
        GQLOps.SearchSingleQuery,
        GQLOps.SearchSingleQueryVariables,
        Action
      >(
        Search.searchProductQuerySingle,
        {
          searchProductId: clientConfig.promaster_search_product_id,
          productId: sharedState.productByKey[state.productKey].id,
        },
        (data) => {
          return Action.SearchDataRecieved(data);
        }
      );

      return [newState, Cmd.batch([gqlCmdSearch])];
    }

    case "SearchDataRecieved": {
      const searchData = Search.createSearchData(action.data);

      const gqlCmdCalc = sharedState.graphQLProductQuery<GQLOps.FrtCoilQuery, GQLOps.FrtCoilQueryVariables, Action>(
        CalculatorFrtCoil.query,
        { searchProductId: clientConfig.promaster_search_product_id },
        (data) => {
          return Action.CalcDataRecieved(data);
        }
      );
      return [{ ...state, searchData: searchData }, Cmd.batch([gqlCmdCalc])];
    }

    case "CalcDataRecieved": {
      if (!state.searchData) {
        return [state];
      }
      const calcData = CalculatorFrtCoil.mapQuery(action.data);
      return [
        { ...state, calcData: calcData },
        search(
          state.searchData,
          createSearchQuery(sharedState.activeUser, state.searchData, state.variant, state.productKey)
        ),
      ];
    }

    case "SetVariant": {
      const productProperties = state.searchProduct?.product?.modules.properties.property;
      if (!state.searchData || !productProperties) {
        return [state];
      }
      const newVariant = resetCalcParamsToDefault(productProperties, state.variant, action.variant);
      const newState = { ...state, variant: newVariant };
      return [
        newState,
        Cmd.batch([
          search(
            state.searchData,
            createSearchQuery(sharedState.activeUser, state.searchData, newState.variant, state.productKey)
          ),
          Navigation.replaceUrl<Action>(
            Routes.buildMainUrl(
              MainLocation.ProductCalculate(
                encodePropertyValueSet(newState.variant, sharedState.activeUser.claims),
                state.productKey
              )
            ),
            undefined,
            true
          ),
        ]),
      ];
    }

    case "UpdateSearchResult": {
      if (!state.calcData || !state.searchData) {
        return [state];
      }

      const searchResult = action.result;
      const matches = searchResult.matches || [];

      if (matches.length !== 1) {
        logWarn("UpdateSearchResult: matches.length !== 1");
        return [state];
      }
      const match = matches[0];

      const [mcState, mcCmd] = loadMagicadData(state.magicloudData, state.searchData.searchProduct, match);
      const query = createSearchQuery(sharedState.activeUser, state.searchData, state.variant, state.productKey);
      return [
        {
          ...state,
          calculating: state.autoCalculate,
          searchResult: searchResult,
          match: match,
          ...mcState,
        },

        Cmd.batch([
          state.autoCalculate
            ? calculate(state.calcData, state.variant, match, query, state.selectedAccessories)
            : undefined,
          mcCmd,
        ]),
      ];
    }

    case "UpdateCalculationResult": {
      return [{ ...state, calculating: false, calculationResults: action.result }];
    }

    case "SetSelectedFormat": {
      return [state, undefined, SharedStateAction.SetSelectedFormat(action.fieldName, action.selectedFormat)];
    }
    case "ClearFieldUnit": {
      return [state, undefined, SharedStateAction.ClearFieldUnit(action.fieldName)];
    }
    case "SetFieldUnit": {
      return [state, undefined, SharedStateAction.SetFieldUnit(action.fieldName, action.unit, action.decimalCount)];
    }

    case "SetResultOrder": {
      return [{ ...state, resultOrder: action.resultOrder }];
    }

    case "UpdateMagicloudData": {
      return [{ ...state, magicloudData: action.data, orbitCamera: undefined }];
    }

    case "SetOrbitCamera": {
      return [{ ...state, orbitCamera: action.camera }];
    }

    case "GetReport": {
      const reportParams = createReportParams(sharedState, state, action.report);
      const queryRunner = Reports.runReportQuries("https://public-api.promaster.systemair.com/image", reportParams);
      const newState = {
        ...state,
        reportQueryRunner: queryRunner,
      };
      return handleReportQueryAction(undefined, newState, sharedState, action.report);
    }

    case "ReportDataReceived": {
      if (!state.reportQueryRunner) {
        return [state];
      }

      return handleReportQueryAction(action.data, state, sharedState, action.report);
    }

    case "SetPrintoutStatus": {
      return [{ ...state, printoutStatus: action.status }];
    }

    case "SetSelectedAccessory": {
      if (!state.calcData || !state.match || !state.searchData) {
        return [state];
      }
      const selectedAccessories = action.selected
        ? [...state.selectedAccessories, action.accessory]
        : state.selectedAccessories.filter((a) => a.articleNumber !== action.accessory.articleNumber);
      const query = createSearchQuery(sharedState.activeUser, state.searchData, state.variant, state.productKey);
      const calculateCmd = calculate(state.calcData, state.variant, state.match, query, selectedAccessories);
      return [{ ...state, selectedAccessories }, calculateCmd];
    }

    default: {
      return exhaustiveCheck(action, true);
    }
  }
}

function search(
  searchData: Search.SearchData,
  query: Search.Query
): PromiseEffectManager.PromiseEffect<Action, never, Search.Result> {
  return PromiseEffectManager.perform<Action, Search.Result>(
    (data) => Action.UpdateSearchResult(data),
    (async (): Promise<FP.Result<never, Search.Result>> => ({
      type: "Ok",
      value: Search.search(searchData, query),
    }))()
  );
}

function calculate(
  calcData: CalculatorFrtCoil.CalculationData,
  properties: PropertyValueSet.PropertyValueSet,
  match: Search.Match,
  query: Search.Query,
  accessories: ReadonlyArray<Accessories.Accessory>
): PromiseEffectManager.PromiseEffect<Action, never, CalculatorFrtCoil.CalculationResult> {
  return PromiseEffectManager.perform<Action, CalculatorFrtCoil.CalculationResult>(
    (data) => Action.UpdateCalculationResult(data),
    (async (): Promise<FP.Result<never, CalculatorFrtCoil.CalculationResult>> => {
      return {
        type: "Ok",
        value: await Calculate.calculateProduct(
          calcData,
          properties,
          match.selection,
          match.productVariantRow,
          query,
          accessories
        ),
      };
    })()
  );
}

function createSearchQuery(
  activeUser: ActiveUser,
  searchData: Search.SearchData,
  variant: PropertyValueSet.PropertyValueSet,
  productKey: string
): Search.Query {
  return {
    company: activeUser.companyName,
    filter: Search.propertiesToSearchFilter(searchData, variant),
    productKey: productKey,
    userCurrency: activeUser.claims.currency,
  };
}

function loadMagicadData(
  magicloudData: MagicloudData | undefined,
  viewData: GQLOps.SearchSingleQuery | undefined,
  match: Search.Match | undefined
): readonly [Partial<State>, PromiseEffectManager.PromiseEffect<Action, never, MagicloudData> | undefined] {
  const newState = { magicloudData: undefined };
  if (!viewData || !match) {
    return [newState, undefined];
  }
  const rows = Search.filterRows(match.selection, viewData.product?.modules.custom_tables.product_variants || []);
  if (rows.length !== 1) {
    logWarn("loadMagicadData: rows.length !== 1");
    return [newState, undefined];
  }
  const magicloudId = rows[0].magicloud_id;
  if (!magicloudId) {
    return [newState, undefined];
  }
  if (
    !magicloudData?.loading &&
    magicloudData?.model?.magicloudId &&
    magicloudData?.model?.magicloudId === magicloudId
  ) {
    return [{}, undefined];
  }
  return [
    { magicloudData: { model: magicloudData?.model, loading: true } },
    PromiseEffectManager.perform<Action, MagicloudData>(
      (data) => Action.UpdateMagicloudData(data),
      (async (): Promise<FP.Result<never, MagicloudData>> => {
        return {
          type: "Ok",
          value: await (async (): Promise<MagicloudData> => {
            const model = await MagicloudApi.getX3DModelByGuid(magicloudId, undefined);
            return {
              model,
              loading: false,
            };
          })(),
        };
      })()
    ),
  ];
}

// TODO: Make generic, see client-infra/src/effect-managers/report-handler
function handleReportQueryAction(
  data: unknown,
  state: State,
  sharedState: SharedState.SharedState,
  report: ReportType
): readonly [State, Cmd<Action>?] {
  if (!state.reportQueryRunner) {
    return [state];
  }
  const result = state.reportQueryRunner.next(data);

  if (result.done) {
    const reportParams = createReportParams(sharedState, state, report);
    return [
      {
        ...state,
        reportQueryRunner: undefined,
        reportResponse: result.value,
      },
      print(result.value, reportParams, report),
    ];
  } else {
    return [state, requestsToCommand(result.value, sharedState, report)];
  }
}

function requestsToCommand(query: Query.Query, sharedState: SharedState.SharedState, report: ReportType): Cmd<Action> {
  switch (query.type) {
    case "HttpBlobQuery": {
      return HttpFetch.fetchOne({}, query.url, "blob", (data) => {
        return Action.ReportDataReceived(data, report);
      });
    }
    case "HttpBlobQueryMultiple": {
      return HttpFetch.fetchMultiple({}, query.url, "blob", (data) => {
        return Action.ReportDataReceived(data, report);
      });
    }
    case "GraphQLProductQuery": {
      return sharedState.graphQLProductQuery<unknown, unknown, Action>(query.query, query.variables, (data) => {
        return Action.ReportDataReceived(data, report);
      });
    }
    case "GraphQLQuery": {
      return graphQLQueryWithAuth(sharedState.activeUser)<unknown, unknown, Action>(
        query.query,
        query.variables,
        (data) => {
          return Action.ReportDataReceived(data, report);
        }
      );
    }
    case "PromiseQuery": {
      return PromiseEffectManager.perform<Action, unknown>(
        (data: unknown) => Action.ReportDataReceived(data, report),
        (async (): Promise<FP.Result<never, unknown>> => ({
          type: "Ok",
          value: await query.promise,
        }))()
      );
    }
    default:
      exhaustiveCheck(query);
      throw new Error("Not implemented");
  }
}

function createReportParams(
  sharedState: SharedState.SharedState,
  state: State,
  report: ReportType
): Reports.ReportParams {
  const params: Reports.ReportParams = {
    reportType: report,
    imageServiceUrl: "https://public-api.promaster.systemair.com/image",
    metaProductId: clientConfig.promaster_meta_id,
    ehProductId: clientConfig.promaster_eh_id,
    translate: sharedState.translate,
    calculateResult: undefined,
    calculateRequest: undefined,
    priceAccessories: undefined,
    user: sharedState.activeUser,
    configurationLink: window.location.href,
    customItems: [],
    heaterImages: [],
    properties: state.variant,
    productKey: state.productKey,
    clientConfig: clientConfig,
    productByKey: sharedState.productByKey,
    productById: sharedState.productById,
    selectedLanguage: sharedState.selectedLanguage,
    getFieldFormat: sharedState.getFieldFormat,
    waterAccessories: state.selectedAccessories,
  };

  return params;
}

function print(
  reportResponse: Reports.ReportQueryResponse,
  reportParams: Reports.ReportParams,
  report: ReportType
): PromiseEffectManager.PromiseEffect<Action, never, undefined> {
  const date = new Date().toISOString().split("T")[0];
  const reportName = report === "quote-page-water" ? "Quote" : report === "technical-data-sheet" ? "Data sheet" : "";
  const fileName = `${reportName} ${date}.pdf`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pdfKit = (window as any).PDFDocument;
  return PromiseEffectManager.perform<Action, undefined>(
    () => Action.SetPrintoutStatus("idle"),
    (async (): Promise<FP.Result<never, undefined>> => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await Reports.createPrintoutClient(reportResponse, [reportParams], fileName, pdfKit, () => {});
      return {
        type: "Ok",
        value: undefined,
      };
    })()
  );
}
