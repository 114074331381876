import React from "react";

export function Spinner({
  small,
  debounce,
}: {
  readonly small?: boolean;
  readonly debounce?: boolean;
}): React.ReactElement<{}> {
  const useDebounce = debounce ?? true;
  const [showSpinner, setShowSpinner] = React.useState(useDebounce ? false : true);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, 750);
    return () => clearTimeout(timer);
  });
  return (
    <div className="fixed inset-0 flex justify-center h-screen items-center bg-white bg-opacity-50 z-50">
      {showSpinner ? <SpinnerIcon small={small} debounce={debounce} /> : undefined}
    </div>
  );
}

export function SpinnerIcon({
  small,
  debounce,
}: {
  readonly small?: boolean;
  readonly debounce?: boolean;
}): React.ReactElement<{}> | null {
  const useDebounce = debounce ?? true;
  const [showSpinner, setShowSpinner] = React.useState(useDebounce ? false : true);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, 750);
    return () => clearTimeout(timer);
  });
  return showSpinner ? (
    <svg
      className={`animate-spin text-primary-700  ${small ? "w-16 h-16" : "w-24 h-24"}`}
      fill="white"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  ) : null;
}

export function SpinnerContainer({
  loading,
  children,
}: React.PropsWithChildren<{ readonly loading: boolean }>): JSX.Element {
  const [showSpinner, setShowSpinner] = React.useState(false);
  React.useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setShowSpinner(true);
      }, 750);
      return () => clearTimeout(timer);
    } else {
      return undefined;
    }
  }, [loading]);
  React.useEffect(() => {
    if (!loading) {
      setShowSpinner(false);
    }
  }, [loading]);
  return (
    <div className="w-full relative">
      {showSpinner && (
        <div className="absolute w-full h-full bg-white bg-opacity-50 z-50 flex justify-center pt-[50%]">
          <SpinnerIcon debounce={false} />
        </div>
      )}
      {children}
    </div>
  );
}
