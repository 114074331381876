import { IconName } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { Button } from "./button";
import { useOnClickOutside } from "./hooks";
import { DropdownListItem, DropdownUnorderedList } from "./dropdown-input";

export interface DropdownButtonProps {
  readonly label: string;
  readonly title?: string;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly iconLeft?: IconName;
  readonly spinnerIcon?: boolean;
  readonly items: ReadonlyArray<DropdownButtonItem>;
}

export interface DropdownButtonItem {
  readonly value: string;
  readonly label: string;
  readonly icon?: IconName;
  readonly onClick?: () => void;
}

export function DropdownButton({
  label,
  title,
  disabled,
  className,
  iconLeft,
  spinnerIcon,
  items,
}: DropdownButtonProps): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownButtonRef = React.useRef<HTMLDivElement | null>(null);
  useOnClickOutside(dropdownButtonRef, () => setIsOpen(false));
  return (
    <div className="relative" ref={dropdownButtonRef}>
      <Button
        label={label}
        title={title}
        disabled={disabled}
        className={className}
        iconLeft={iconLeft}
        spinnerIcon={spinnerIcon}
        iconRight="chevron-down"
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <DropdownUnorderedList className="absolute top-10 min-w-full">
          {items.map((i) => (
            <DropdownListItem
              align="left"
              key={i.value}
              onClick={() => {
                setIsOpen(false);
                if (i.onClick) {
                  i.onClick();
                }
              }}
              valid={true}
            >
              {i.label}
            </DropdownListItem>
          ))}
        </DropdownUnorderedList>
      )}
    </div>
  );
}
