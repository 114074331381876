import { PropertyFilter, PropertyValueSet } from "@promaster-sdk/property";
import { Amount, Unit } from "uom";
import { Quantity, Units } from "uom-units";
import { Accessories, FP, Search, logWarn } from "..";
import { Friterm } from "../dll";
import * as Types from "./types";
import * as Q from "./query";
import { TextKey, texts } from "../lang-texts";
import { FrtCoilQuery } from "../generated/generated-operations";
import { mapPropertyFilter } from "../utils";
import { searchColumns } from "../search";
import { FritermInput, FritermOutput, getMaterialText } from "../dll/friterm";
import { calculatePrice } from "../calculate-water-dx";
import { propertyAmountAs } from "../utils/property-amount-as";

export function mapQuery(query: FrtCoilQuery): Types.CalculationData {
  if (!query.searchProduct) {
    throw new Error("mapQuery: search product is missing");
  }
  const calculationInput = mapPropertyFilter(query.searchProduct.modules.custom_tables.calculation_input);
  const products = query.products;
  return {
    calculationInput,
    products,
  };
}

export function mapSpecialCalculationInput(
  properties: PropertyValueSet.PropertyValueSet
): FP.Result<Types.CoilInputError, Partial<FritermInput>> {
  const getAmountValue = (propertyName: string, unit: Unit.Unit<unknown>): number | undefined => {
    const amount = PropertyValueSet.getAmount(propertyName, properties);
    if (!amount) {
      return 0;
    }
    return Amount.valueAs(unit, amount);
  };

  const propertiesToDivide: ReadonlyArray<keyof FritermInput> = [
    "ManifoldInletDiameter",
    "ManifoldOutletDiameter",
    "TubeThickness",
    "FinThickness",
    "FinPitch",
  ];

  const getInt = (propertyName: keyof Friterm.FritermInput): number => {
    if (propertiesToDivide.includes(propertyName)) {
      return (PropertyValueSet.getInteger(propertyName, properties) || 0) / 1000;
    }
    return PropertyValueSet.getInteger(propertyName, properties) || 0;
  };

  const discreteProperties: ReadonlyArray<keyof FritermInput> = [
    "CoilType",
    "GeometryID",
    "ModeManifold",
    "ManifoldMaterialID",
    "ManifoldInletDiameter",
    "ManifoldOutletDiameter",
    "ManifoldSetCount",
    "ManifoldConnectionPipeCount",
    "TubeMaterialID",
    "TubeThickness",
    "TubeThickness",
    "FinMaterialID",
    "FinThickness",
    "FinPitch",
    "ModePassOrCircuit",
    "ModeLength",
    "ModeHeight",
    "ModeWidth",
    "IsTurbulatorTwistedTape",
    "IsTurbulatorWireMatrix",
    "DxTr",
    "CoilPosition",
    "ModeAtmosphericPressure",
    "AirAtmosphericPressure",
    "ModeAirFlow",
    "ModeAirMoistureInlet",
    "ModeAirReferenceMassFlow",
    "ModeCalculateAirPressureDropDry",
    "FldID",
    "ModeFluidFlow",
    "ModeLoad",
  ];

  return {
    type: "Ok",
    value: {
      ...Object.assign({}, ...discreteProperties.map((p) => ({ [p]: getInt(p) }))),

      Length: getAmountValue("Length", Units.Millimeter),
      CircuitCount: getAmountValue("CircuitCount", Units.One),
      PassCount: getAmountValue("PassCount", Units.One),
      TubeCount: getAmountValue("TubeCount", Units.One),
      RowCount: getAmountValue("RowCount", Units.One),
      LDMax: getAmountValue("LDMax", Units.Millimeter),
      HR: getAmountValue("HR", Units.Millimeter),
      TR: getAmountValue("TR", Units.Millimeter),
      AirDensityInlet: getAmountValue("AirDensityInlet", Units.KilogramPerCubicMeter),
      AirVolumetricFlowInlet: getAmountValue("AirVolumetricFlowInlet", Units.CubicMeterPerHour),
      AirTemperatureInlet: getAmountValue("AirTemperatureInlet", Units.Celsius),
      AirHumidityInlet: getAmountValue("AirHumidityInlet", Units.Percent),
      FldMixtureRatio: getAmountValue("FldMixtureRatio", Units.Percent),
      FldMassFlow: getAmountValue("FldMassFlow", Units.KilogramPerHour),
      FldTemperatureOutlet: getAmountValue("FldTemperatureOutlet", Units.Celsius),
      FldTemperatureInlet: getAmountValue("FldTemperatureInlet", Units.Celsius),
      FldVolumetricFlowInlet: getAmountValue("FldVolumetricFlowInlet", Units.CubicMeterPerHour),
      FldTemperatureEvaporation: getAmountValue("FldTemperatureEvaporation", Units.Celsius),
      FldTemperatureCondensation: getAmountValue("FldTemperatureCondensation", Units.Celsius),
      FldTemperatureSubCooling: getAmountValue("FldTemperatureSubCooling", Units.Kelvin),
      FldTemperatureSuperHeating: getAmountValue("FldTemperatureSuperHeating", Units.Kelvin),
    },
  };
}

export function mapInput(
  data: Types.CalculationData,
  properties: PropertyValueSet.PropertyValueSet,
  selection: Search.Selection
): FP.Result<Types.CoilInputError, Types.CoilInput> {
  const calcInput = data.calculationInput.filter((r) => PropertyFilter.isValid(properties, r.property_filter));

  // General coil inputs
  const mode = getStrInput(calcInput, "function");
  const coilType = getStrInput(calcInput, "medium");
  const airInletTempAmount = PropertyValueSet.getAmount<Quantity.Temperature>("inlet_air_temp", properties);
  const relativeHumidityAmount = PropertyValueSet.getAmount<Quantity.RelativeHumidity>(
    "inlet_air_humidity",
    properties
  );
  const airflowInputParam = getStrInput(calcInput, "airflow_input");
  const airflowAmount = PropertyValueSet.getAmount<Quantity.VolumeFlow>("airflow", properties);
  const airVelocityAmount = PropertyValueSet.getAmount<Quantity.Velocity>("air_velocity", properties);
  const frtFluidId = getNumInput(calcInput, "frt_dll_fld_id");

  // DX coil inputs
  const evaporatingTempAmount = PropertyValueSet.getAmount<Quantity.Temperature>("evaporating_temp", properties);
  const condensingTempAmount = PropertyValueSet.getAmount<Quantity.Temperature>("condensing_temp", properties);
  const superheatingTempAmount = PropertyValueSet.getAmount<Quantity.Temperature>("superheating_temp", properties);
  const subcoolingTempAmount = PropertyValueSet.getAmount<Quantity.DeltaTemperature>("subcooling_temp", properties);

  // Water coil inputs
  const fluidMixturePercentAmount = PropertyValueSet.getAmount<Quantity.Dimensionless>(
    "fluid_mixture_rate",
    properties
  );
  const calculationMethod = getStrInput(calcInput, "calculation_method");
  const fluidInletTempAmount = PropertyValueSet.getAmount<Quantity.Temperature>("inlet_fluid_temp", properties);
  const fluidOutletTempAmount = PropertyValueSet.getAmount<Quantity.Temperature>("outlet_fluid_temp", properties);
  const fluidFlowAmount = PropertyValueSet.getAmount<Quantity.VolumeFlow>("fluid_flow", properties);

  const discount = propertyAmountAs(Units.One, "discount", properties);

  if (discount === undefined) {
    return inputError("missing discount", texts.calculation_input_error);
  }

  if (mode !== "cooling" && mode !== "heating") {
    return inputError("operating mode", texts.calculation_input_error);
  }

  // General input
  if (!airInletTempAmount || !relativeHumidityAmount) {
    return inputError("air params", texts.calculation_input_error);
  }
  const airInput: Types.AirInput = {
    airInletTempC: Amount.valueAs(Units.Celsius, airInletTempAmount),
    relativeHumidity: Amount.valueAs(Units.PercentHumidity, relativeHumidityAmount),
  };

  // Airflow input
  let airflowInput: Types.AirflowInput;
  const fixedAirflow = Number(selection.fixed_airflow);

  if (!Number.isNaN(fixedAirflow) && fixedAirflow > 0) {
    airflowInput = {
      airflowInput: "volumetric",
      airFlowCmph: fixedAirflow,
    };
  } else if (airflowInputParam === "volumetric" && airflowAmount) {
    airflowInput = {
      airflowInput: "volumetric",
      airFlowCmph: Amount.valueAs(Units.CubicMeterPerHour, airflowAmount),
    };
  } else if (airflowInputParam === "velocity" && airVelocityAmount) {
    airflowInput = {
      airflowInput: "velocity",
      airVelocityMps: Amount.valueAs(Units.MeterPerSecond, airVelocityAmount),
    };
  } else {
    return inputError("airflow input", texts.calculation_input_error);
  }

  // Dx coil input
  let dxCoilInput: Types.DxCoilInput | undefined = undefined;
  if (coilType === "dx") {
    if (frtFluidId === undefined || !superheatingTempAmount || !subcoolingTempAmount) {
      return inputError("dx fluid inputs", texts.calculation_input_error);
    }
    const dxInput: Types.DxInput = {
      coilType: "dx",
      frtFluidId: frtFluidId,
      superheatingTempC: Amount.valueAs(Units.Celsius, superheatingTempAmount),
      subcoolingTempK: Amount.valueAs(Units.DeltaCelsius, subcoolingTempAmount),
    };
    let dxModeInput: Types.DxModeInput;
    if (mode === "cooling" && evaporatingTempAmount) {
      dxModeInput = {
        mode: "cooling",
        evaporatingTempC: Amount.valueAs(Units.Celsius, evaporatingTempAmount),
      };
    } else if (mode === "heating" && condensingTempAmount) {
      dxModeInput = {
        mode: "heating",
        condensingTempC: Amount.valueAs(Units.Celsius, condensingTempAmount),
      };
    } else {
      return inputError("dx cond/evap temp", texts.calculation_input_error);
    }
    dxCoilInput = {
      ...dxInput,
      ...dxModeInput,
    };
  }

  // Water coil input
  let waterCoilInput: Types.WaterCoilInput | undefined = undefined;
  if (coilType === "water") {
    if (mode === undefined || frtFluidId === undefined || !fluidInletTempAmount) {
      return inputError("water inputs", texts.calculation_input_error);
    }
    const waterInput: Types.WaterInput = {
      coilType: "water",
      mode: mode,
      frtFluidId: frtFluidId,
      fluidMixturePercent: fluidMixturePercentAmount ? Amount.valueAs(Units.Percent, fluidMixturePercentAmount) : 0,
      fluidInletTempC: Amount.valueAs(Units.Celsius, fluidInletTempAmount),
    };
    let waterCalculationMethod: Types.WaterCalculationMethod;
    if (calculationMethod === "outlet_fluid_temp" && fluidOutletTempAmount) {
      waterCalculationMethod = {
        calculationMethod: "outlet_fluid_temp",
        fluidOutletTempC: Amount.valueAs(Units.Celsius, fluidOutletTempAmount),
      };
    } else if (calculationMethod === "fluid_flow" && fluidFlowAmount) {
      waterCalculationMethod = {
        calculationMethod: "fluid_flow",
        fluidFlowCmph: Amount.valueAs(Units.CubicMeterPerHour, fluidFlowAmount),
      };
    } else {
      return inputError("water method", texts.calculation_input_error);
    }
    waterCoilInput = {
      ...waterInput,
      ...waterCalculationMethod,
    };
  }

  if (dxCoilInput) {
    return {
      type: "Ok",
      value: {
        selection,
        discount,
        ...airInput,
        ...airflowInput,
        ...dxCoilInput,
      },
    };
  } else if (waterCoilInput) {
    return {
      type: "Ok",
      value: {
        selection,
        discount,
        ...airInput,
        ...airflowInput,
        ...waterCoilInput,
      },
    };
  } else {
    throw new Error("Coil input set");
  }
}

export async function calculate(
  data: Types.CalculationData,
  coilInput: Types.CoilInput,
  selectedProduct: Search.ProductVariantsRow,
  query: Search.Query,
  accessories: ReadonlyArray<Accessories.Accessory>
): Promise<Types.CalculationResult> {
  const product = data.products.find((p) => p.key === coilInput.selection.productKey);
  if (!product) {
    return calcError(`product ${coilInput.selection.productKey} not found`, texts.calculation_error_no_product_data);
  }
  const selection = coilInput.selection;
  const pv = product.modules.custom_tables.product_variants.find((r) =>
    searchColumns.every((column) => (r[column]?.toString() ?? "") === selection[column])
  );
  if (!pv) {
    return calcError(`variant data not found ${JSON.stringify(selection)}`, texts.calculation_error_variant_no_found);
  }

  let coilType: number;
  if (coilInput.coilType === "water" && coilInput.mode === "heating") {
    coilType = 1;
  } else if (coilInput.coilType === "water" && coilInput.mode === "cooling") {
    coilType = 2;
  } else if (coilInput.coilType === "dx" && coilInput.mode === "cooling") {
    coilType = 3;
  } else if (coilInput.coilType === "dx" && coilInput.mode === "heating") {
    coilType = 4;
  } else {
    throw new Error("calculate: invalid coil type");
  }

  let modeAirFlow = 0;
  let airVolumetricFlowInlet = 0;
  let airVelocity = 0;
  if (coilInput.airflowInput === "volumetric") {
    modeAirFlow = 0;
    airVolumetricFlowInlet = coilInput.airFlowCmph;
  } else if (coilInput.airflowInput === "velocity") {
    modeAirFlow = 1;
    airVelocity = coilInput.airVelocityMps;
  }

  // Water coil
  let fldTemperatureInlet = 0;
  let fldMixtureRatio = 0;
  if (coilInput.coilType === "water") {
    fldTemperatureInlet = coilInput.fluidInletTempC;
    fldMixtureRatio = coilInput.frtFluidId !== 0 ? coilInput.fluidMixturePercent : 0;
  }
  let modeFluidFlow = 0;
  let fldVolumetricFlow = 0;
  let fldTemperatureOutlet = 0;
  if (coilInput.coilType === "water" && coilInput.calculationMethod === "fluid_flow") {
    modeFluidFlow = 1;
    fldVolumetricFlow = coilInput.fluidFlowCmph;
  } else if (coilInput.coilType === "water" && coilInput.calculationMethod === "outlet_fluid_temp") {
    modeFluidFlow = 2;
    fldTemperatureOutlet = coilInput.fluidOutletTempC;
  }

  // Dx coil
  let fldTemperatureSuperHeating = 0;
  let fldTemperatureSubCooling = 0;
  if (coilInput.coilType === "dx") {
    fldTemperatureSuperHeating = coilInput.superheatingTempC;
    fldTemperatureSubCooling = coilInput.subcoolingTempK;
  }
  let fldTemperatureEvaporation = 0;
  let fldTemperatureCondensation = 0;
  if (coilInput.coilType === "dx" && coilInput.mode === "cooling") {
    fldTemperatureEvaporation = coilInput.evaporatingTempC;
    fldTemperatureCondensation = 40; // Same default value the old VEAB app is using
  } else if (coilInput.coilType === "dx" && coilInput.mode === "heating") {
    fldTemperatureEvaporation = 5; // Same default value the old VEAB app is using
    fldTemperatureCondensation = coilInput.condensingTempC;
  }

  // Manifold diameter
  const inletDia = num(pv.frt_dll_manifold_inlet_diameter);
  const outletDia = num(pv.frt_dll_manifold_outlet_diameter);
  let manifoldInletDiameter = 0;
  let manifoldOutletDiameter = 0;
  if (coilInput.coilType === "dx" && coilInput.mode === "cooling") {
    manifoldInletDiameter = Math.min(inletDia, outletDia);
    manifoldOutletDiameter = Math.max(inletDia, outletDia);
  } else if (coilInput.coilType === "dx" && coilInput.mode === "heating") {
    manifoldInletDiameter = Math.max(inletDia, outletDia);
    manifoldOutletDiameter = Math.min(inletDia, outletDia);
  } else {
    manifoldInletDiameter = inletDia;
    manifoldOutletDiameter = outletDia;
  }

  const dllInput: Partial<Friterm.FritermInput> = {
    CoilType: coilType,
    GeometryID: num(pv.frt_dll_geometry_id),
    ModeLoad: 0,
    ModeManifold: 1, // 1 = when ManifoldSetCount, ManifoldInletDiameter, ManifoldOutletDiameter is set
    Length: num(pv.frt_dll_finned_length),
    TubeMaterialID: num(pv.frt_dll_tube_id),
    FinMaterialID: num(pv.frt_dll_fin_id),
    TubeThickness: num(pv.frt_dll_tube_thickness),
    FinThickness: num(pv.frt_dll_fin_fin_thickness),
    FinPitch: num(pv.frt_dll_fin_pitch),
    TubeCount: num(pv.frt_dll_number_of_tubes),
    RowCount: num(pv.frt_dll_number_of_rows),
    ModePassOrCircuit: 0,
    CircuitCount: num(pv.frt_dll_number_of_circuits),
    ModeAtmosphericPressure: 0,
    AirAtmosphericPressure: 101325,
    ModeAirFlow: modeAirFlow,
    AirVolumetricFlowInlet: airVolumetricFlowInlet,
    AirVelocity: airVelocity,
    AirTemperatureInlet: coilInput.airInletTempC,
    ModeAirMoistureInlet: 0,
    AirHumidityInlet: coilInput.relativeHumidity,
    FldID: coilInput.frtFluidId,
    FldTemperatureInlet: fldTemperatureInlet,
    FldMixtureRatio: fldMixtureRatio,
    ModeFluidFlow: modeFluidFlow,
    FldTemperatureOutlet: fldTemperatureOutlet,
    FldVolumetricFlowInlet: fldVolumetricFlow,
    ManifoldMaterialID: num(pv.frt_dll_manifold_material_id),
    ManifoldSetCount: num(pv.frt_dll_manifold_set_count),
    ManifoldInletDiameter: manifoldInletDiameter,
    ManifoldOutletDiameter: manifoldOutletDiameter,
    FldTemperatureSuperHeating: fldTemperatureSuperHeating,
    FldTemperatureSubCooling: fldTemperatureSubCooling,
    FldTemperatureEvaporation: fldTemperatureEvaporation,
    FldTemperatureCondensation: fldTemperatureCondensation,
  };

  const fullDllInput = Friterm.createFullInput(dllInput);

  const output = await Friterm.compute(fullDllInput);
  if (!output) {
    return calcError(`dll request failed: ${JSON.stringify(fullDllInput)}`, texts.calculation_error_dll_request);
  }

  const dllMessages = messagesFromDllOutput(output);
  if (dllMessages.some((m) => m.type === "error")) {
    return {
      type: "Err",
      error: {
        messages: dllMessages,
      },
    };
  }

  const price = calculatePrice(selectedProduct, accessories, query, coilInput.discount);

  const result: Types.CoilResult = {
    atmosphericPressure: Amount.create(output.airAtmosphericPressure, Units.Pascal),
    altitude: Amount.create(output.airAltitude, Units.Meter),

    airDensity: Amount.create(output.airDensityInlet, Units.KilogramPerCubicMeter),
    airFlow: Amount.create(output.airVolumetricFlowInlet, Units.CubicMeterPerHour),
    airMassFlow: Amount.create(output.airMassFlowInlet, Units.KilogramPerHour),
    airVelocity: Amount.create(output.airVelocity, Units.MeterPerSecond),
    airPressureDrop: Amount.create(output.airPressureDrop, Units.Pascal),
    airInletTemperature: Amount.create(coilInput.airInletTempC, Units.Celsius),
    airInletHumidity: Amount.create(output.airHumidityInlet, Units.PercentHumidity),
    airOutletTemperature: Amount.create(output.airTemperatureOutlet, Units.Celsius),
    airOutletHumidity: Amount.create(output.airHumidityOutlet, Units.PercentHumidity),

    capacity: Amount.create(output.capacity, Units.Watt),
    capacitySensible: Amount.create(output.capacitySensible, Units.Watt),

    fluidDensity: Amount.create(output.fldDensityInlet, Units.KilogramPerCubicMeter),
    fluidFlow: Amount.create(output.fldVolumetricFlowInlet, Units.CubicMeterPerHour),
    fluidMassFlow: Amount.create(output.fldMassFlow, Units.KilogramPerHour),
    fluidPressureDrop:
      coilInput.coilType === "dx" && coilInput.mode === "cooling"
        ? Amount.create(output.fldPressureDropPrint ?? 0, Units.KiloPascal)
        : Amount.create(output.fldPressureDrop ?? 0, Units.Pascal),
    fluidVelocity: Amount.create(output.fldVelocityTubeLiquid, Units.MeterPerSecond),
    fluidMixtureRatio: Amount.create(fldMixtureRatio, Units.Percent),
    fluidInletTemperature: Amount.create(output.fldTemperatureInlet, Units.Celsius),
    fluidOutletTemperature: Amount.create(output.fldTemperatureOutlet, Units.Celsius),

    subcooling: Amount.create(fldTemperatureSubCooling, Units.Kelvin),
    superheating: Amount.create(fldTemperatureSuperHeating, Units.Kelvin),
    condensationTemperature: Amount.create(fldTemperatureCondensation, Units.Celsius),

    tubeVolume: Amount.create(output.tubeInnerVolume, Units.Liter),
    tubeMaterial: getMaterialText(num(pv.frt_dll_tube_id)),
    finMaterial: getMaterialText(num(pv.frt_dll_fin_id)),

    weight: Amount.create(output.weight, Units.Kilogram),
    messages: dllMessages,

    price,
  };

  return {
    type: "Ok",
    value: result,
  };
}

export async function calculateRaw(
  dllInput: Partial<Friterm.FritermInput>
): Promise<FP.Result<Types.CoilErrorResult, FritermOutput>> {
  const fullDllInput = Friterm.createFullInput(dllInput);
  const output = await Friterm.compute(fullDllInput);
  if (!output) {
    return calcError(`dll request failed: ${JSON.stringify(fullDllInput)}`, texts.calculation_error_dll_request);
  }

  const dllMessages = messagesFromDllOutput(output);
  if (dllMessages.some((m) => m.type === "error")) {
    return {
      type: "Err",
      error: {
        messages: dllMessages,
      },
    };
  }

  return {
    type: "Ok",
    value: output,
  };
}

function messagesFromDllOutput(output: Friterm.FritermOutput): ReadonlyArray<Types.Message> {
  const messages: Array<Types.Message> = [];

  const errorNumber = (output.errorNumber || 0).toString();
  if (errorNumber !== "0") {
    logWarn(`messagesFromDllOutput: error number: ${errorNumber}, ${output.errorDescription}`);
    messages.push({
      type: "error",
      text: texts.frt_dll_error(errorNumber),
      textFallback: `${output.errorDescription || "Unknown error"} (code: ${errorNumber})`,
    });
  }

  const warningNumbers = output.warningNumber ? output.warningNumber.toString().split("|") : [];
  const warningDescs = (output.warningDescription || "").toString().split("|");
  messages.push(
    ...warningNumbers.map((warningNumber, i): Types.Message => {
      logWarn(`messagesFromDllOutput: warning number: ${warningNumber}, ${warningDescs[i] || ""}`);
      return {
        type: "warning",
        text: texts.frt_dll_warning(warningNumber),
        textFallback:
          warningNumbers.length === warningDescs.length
            ? `${warningDescs[i]} (code: ${warningNumber})`
            : `Unknown warning (code: ${warningNumber})`,
      };
    })
  );

  return messages;
}

function getNumInput(calcInput: Q.CalculationInputTable, param: string): number | undefined {
  const value = calcInput.find((r) => r.param === param)?.value;
  if (value === undefined || value === null) {
    return undefined;
  }
  return num(value);
}

function getStrInput(calcInput: Q.CalculationInputTable, param: string): string | undefined {
  const value = calcInput.find((r) => r.param === param)?.value;
  if (value === undefined || value === null) {
    return undefined;
  }
  return str(value);
}

function num(value: number | string | null): number {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    return Number.parseFloat(value);
  } else {
    return 0;
  }
}

function str(value: number | string | null): string {
  if (typeof value === "number") {
    return value.toString();
  } else if (typeof value === "string") {
    return value;
  } else {
    return "";
  }
}

function calcError(log: string, text: TextKey): FP.Err<Types.CoilErrorResult> {
  logWarn(`Incorrect calculation input: ${log}`);
  return {
    type: "Err",
    error: {
      messages: [
        {
          type: "error",
          text,
        },
      ],
    },
  };
}

function inputError(log: string, text: TextKey): FP.Err<Types.CoilInputError> {
  logWarn(`Incorrect calculation input: ${log}`);
  return {
    type: "Err",
    error: {
      messages: [
        {
          type: "error",
          text,
        },
      ],
    },
  };
}
